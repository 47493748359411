import { Checkbox } from '@material-ui/core';
import './FilterItemSelector.scss';
import Loader from 'react-spinners/ClipLoader';

export default function FilterItemSelector({ el, handleFilterData, selected }) {

    const { title, data, key, otherAction } = el;

    return (
        <div className='sectionBox'>
            <p className='title'>{title}</p>

            {data === undefined ? <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 150,
                width: 150,
            }}>
                <Loader
                    color='grey'
                // size={20}

                />
            </div>
                :
                <div className='containerFilter'>
                    {data?.map((el, i) => (
                        <div
                            key={i}
                            className='selectSection'
                            style={{
                                background:
                                    el.value === selected
                                        ? 'var(--milestoneRowAddColor)'
                                        : null,
                            }}
                            onClick={() => handleFilterData(el.value === selected ? null : el.value, key, otherAction)}
                        >
                            <Checkbox
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                size='small'
                                className='showCheckbox'
                                checked={el.value === selected}
                            />
                            <p>{el?.name}</p>
                        </div>
                    ))}
                </div>
            }

        </div>
    );
}
