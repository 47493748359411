import React from 'react';
import './button.scss';
import { Tooltip } from '@mui/material';

const colors = {
    INVOICED: 'var(--progressBarColor)',
    PENDING: 'var(--red)',
    PARTIALLY_INVOICED: 'var(--yellow)',

    PAID: 'var(--green)',
    UNPAID: 'var(--red)',

    Invoiced: 'var(--progressBarColor)',
    Pending: 'var(--red)',
    'Partially Invoiced': 'var(--yellow)',
    'Partially...': 'var(--yellow)',
    Paid: 'var(--green)',
};

const MyButton = ({ children, onClick, className }) => {
    return (
        <div onClick={onClick} className={`button ${className}`} style={{ backgroundColor: colors[children] }}>
            <Tooltip title={children === 'Partially...' ? 'Partially Invoiced' : ''}>
                <span>{children}</span>
            </Tooltip>
        </div>
    );
};

export default MyButton;
