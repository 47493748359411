import CustomDatePickerModal from 'components/CustomModal/CustomDatePickerModal';
import { memo, useState } from 'react';
import './header.scss';
import Icon from 'components/icons/IosIcon';
import { UseProjectAmountDetail } from './useProjectAmount';
import Loading from 'components/loading/Loading';
import CustomButton from 'components/CustomButton';
import { CircularProgress } from '@mui/material';
import YearMonthPicker from '../../yearMonthPicker/YearMonthPicker';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const DetailsHeader = ({
    project,
    active,
    handleIsLinkClient,
    activeTab,
    filterData,
    handleFilterData,
    paymentScheduleData,
}) => {

    const userType = useSelector(
        (state) => state.userReducer?.userType?.userType
    );


    const { data, cardData, isLoading, refetch } = UseProjectAmountDetail(
        project?._id,
        project?.totalProject,
        filterData[activeTab - 1]
    );
    let projectNotLink = project?.title === 'All projects' ? false : data?.count === 0 ? true : false;

    return (
        <div className='detailHeader'>
            {isLoading && (
                <div className='dfaIc'>
                    <CircularProgress />
                </div>
            )}
            {!isLoading && (
                <>
                    {project?.title === 'All projects' ? (
                        <div className='monthYear'>
                            {activeTab - 1 === 0 && (
                                <YearMonthPicker
                                    filterData={filterData[activeTab - 1]}
                                    handleFilterData={handleFilterData}
                                />
                            )}
                            {activeTab - 1 === 1 && (
                                <YearMonthPicker
                                    filterData={filterData[activeTab - 1]}
                                    handleFilterData={handleFilterData}
                                />
                            )}
                        </div>
                    ) : (
                        <div className='headingTitleDiv'>
                            <p className='text'>{project?.title}</p>
                            {projectNotLink && (
                                <CustomButton onClick={() => handleIsLinkClient(true)}>Link Client</CustomButton>
                            )}
                        </div>
                    )}
                    {userType === 'Admin' && < div className='cartDiv'>
                        {cardData?.map((el) => {
                            return (
                                <div className={`cart ${el?.borderRed && 'cartRemaining'} `} key={el?.id}>
                                    <div className='textDiv'>
                                        <p className='text1'>
                                            {!el?.hideSign && '$'}
                                            {el?.amount ?? 0}
                                        </p>
                                        <p className='text2'>{el?.title ?? 'N/A'}</p>
                                    </div>
                                    <div className='icon'>
                                        <Icon name={el.icon} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>}
                </>
            )
            }
        </div >

        // <div
        //     className={
        //         active !== null
        //             ? 'payment-schedule-header-active'
        //             : 'payment-schedule-header'
        //     }
        // >
        //     <div className='nameRow'>
        //         <p>{project?.title}</p>
        //         <MyPopOver
        //             handleClick={handleClick}
        //             anchorEl={anchorEl}
        //             box={
        //                 <MenuBox
        //                     handleClick={handleClick}
        //                     list={paymentScheduleStatus}
        //                 />
        //             }
        //         >
        //             <MyButton>Pending</MyButton>
        //         </MyPopOver>
        //     </div>
        //     <div className='amount-row'>
        //         <div>
        //             <p>Total Amount</p>
        //             <span>{totalAmount}</span>
        //         </div>
        //         <div>
        //             <p>Total Paid Amount</p>
        //             <span>{totalPaidAmount}</span>
        //         </div>
        //         <div>
        //             <p>Total Remaining Amount</p>
        //             <span>{totalAmount - totalPaidAmount}</span>
        //         </div>
        //     </div>
        //     <CustomDatePickerModal
        //         handleChange={handleChange}
        //         showModal={showModal}
        //         handleClose={handleClose}
        //     />
        // </div>
    );
};

export default DetailsHeader;
