import {
  getAllEodList,
  getEodListForAdmin,
  getEodOfSIngleStaff,
} from "api/eod";
import { getOrgTeam } from "api/organisation";
import { getInvoiceProjects } from "api/project";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useProjects } from "react-query/projects/useProjects";

const initialOpenFilter = {
  open: false,
  type: null,
  Project: [],
  Member: [],
  Date: [
    {
      startDate: null,
      endDate: new Date(""),
      key: "selection",
    },
  ],
  filterSelect: {
    Project: false,
    Member: false,
    Date: false,
  },
};

export const useGetEODList = () => {
  const userType = useSelector((state) => state.userReducer?.userType.userType);
  const orgId = useSelector(
    (state) => state.userReducer?.selectedOrganisation?._id
  );

  const [filterObj, setFilterObj] = useState({});
  const [openFilter, setOpenFilter] = useState(initialOpenFilter);
  const [page, setPage] = React.useState(1);
  const resData = useQuery(
    ["allEodList", page, filterObj],
    () => getAllEodList(page, filterObj, orgId),
    {
      select: (res) => {
        const dataByDate = {};

        // Iterate through the data and group it by the "createdAt" date
        res?.data?.eodDetail?.forEach((item) => {
          const createdAt = moment(item.createdAt).format("DD-MMM-YYYY");
          if (!dataByDate[createdAt]) {
            dataByDate[createdAt] = [];
          }
          dataByDate[createdAt].push(item);
        });
        // Now, data

        if (res?.data?.eodDetail) {
          return {
            ...res,
            data: {
              ...res?.data,
              eodDetail: dataByDate,
            },
          };
        }
        return res;
      },
    }
  );
  //team member list
  const memberList = useQuery(
    ["organisationUsers", orgId],
    () => getOrgTeam(orgId),
    {
      enabled: openFilter?.type === "Member",
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  //projectList
  //   const projectList = useQuery(
  //     ["activeProjects", orgId, "Active"],
  //     () => getInvoiceProjects(orgId, "Active"),
  //     {
  //       enabled: openFilter?.type === "Project",
  //       retry: 0,
  //       refetchOnMount: false,
  //     }
  //   );

  const projectList = useProjects(
    "Active",
    undefined,
    openFilter?.type === "Project"
  );

  let onclickFilterType = (type, value) => {
    let key = openFilter?.type;
    if (!!type) {
      setOpenFilter((prev) => {
        return { ...prev, type };
      });
    }
    if (!!value && key !== "Date") {
      let temp = [...openFilter[key]];

      let index = temp.findIndex((el) => el?._id === value?._id);
      if (index === -1) {
        temp.push(value);
      } else {
        temp.splice(index, 1);
      }
      setOpenFilter(() => {
        let statusObj = { ...openFilter.filterSelect };
        if (temp.length > 0) {
          statusObj[key] = true;
        } else {
          statusObj[key] = false;
        }
        return { ...openFilter, [key]: temp, filterSelect: statusObj };
      });
    }
    if (!!value && key === "Date") {
      setOpenFilter((prev) => {
        let statusObj = { ...openFilter.filterSelect };
        if (!!value?.selection?.startDate) {
          statusObj.Date = true;
        } else {
          statusObj.false = true;
        }
        return { ...prev, Date: [value.selection], filterSelect: statusObj };
      });
    }
  };

  const onClickFilterBtn = (type) => {
    if (type === "clear") {
      setFilterObj({});
      setOpenFilter(initialOpenFilter);
    } else {
      let projectId = [...openFilter.Project].map((el) => el?._id);
      let membersId = [...openFilter.Member].map((el) => el?._id);
      let startDate = moment(openFilter.Date[0].startDate).format("YYYY-MM-DD");
      let endDate = moment(openFilter.Date[0].endDate).format("YYYY-MM-DD");
      // console.log('startDate', startDate)
      let temp = {
        ...(openFilter.Member.length > 0 && { member: membersId }),
        ...(openFilter.Project.length > 0 && { projectId: projectId }),
        ...(!!openFilter.Date[0].startDate && { startDate }),
        ...(!!openFilter.Date[0].startDate && { endDate }),
      };

      setFilterObj(temp);
      setOpenFilter((prev) => {
        return { ...prev, open: false };
      });
    }
  };

  //MP-->member Project
  const resetMPFilterList = (type) => {
    // type='Project' | 'Member' | 'Date
    if (type === "Date") {
      let Date = [
        {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      ];
      setOpenFilter((prev) => {
        return { ...prev, Date };
      });
    } else {
      setOpenFilter((prev) => {
        let filterSelect = { ...prev.filterSelect, [type]: false };
        return { ...prev, [type]: [], filterSelect };
      });
    }
  };
  // member project select all
  const MPSelectAll = ({ type, list }) => {
    if (type !== "Date") {
      setOpenFilter((prev) => {
        let filterSelect = { ...prev.filterSelect, [type]: true };
        return { ...prev, [type]: list, filterSelect };
      });
    }
  };

  const handleOpenCloseFilter = (status) => {
    setOpenFilter((prev) => {
      return { ...prev, open: status };
    });
  };

  const handlePagination = (status) => {
    if (status === "prev") {
      setPage((prev) => prev - 1);
    } else if (status === "next") {
      setPage((prev) => prev + 1);
    }
  };

  return {
    resData,
    memberList,
    projectList,
    openFilter,
    onclickFilterType,
    onClickFilterBtn,
    handleOpenCloseFilter,
    page,
    handlePagination,
    resetMPFilterList,
    MPSelectAll,
    filterObj,
  };
};
