import React from 'react'

function PartitionWall() {
    return (
        <div style={{
            width: 27,
            height: 40,
            background: 'linear-gradient(#0E0841, rgba(98, 93, 245, 0.2) 50%, #0E0841)',
        }}>{` `}</div>
    )
}

export default PartitionWall