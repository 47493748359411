export const paymentScheduleStatus = [
    { key: 'PAID', text: 'PAID' },
    { key: 'PENDING', text: 'PENDING' },
    { key: 'PARTIALLY_INVOICED', text: 'PARTIALLY INVOICED' },
    { key: 'INVOICED', text: 'INVOICED' },
];

export const invoiceStatus = [
    { key: 'PAID', text: 'PAID' },
    { key: 'UNPAID', text: 'UNPAID' },
]
export const validations = [
    'projectId',
    'subsiduary',
    'invoiceNumber',
    'paymentSchedule',
    'amount',
];

export const validationsInvoiceUpdate = [
    'projectId',
    'paymentSchedule',
    'amount',
    'invoiceNumber',
    'subsiduary',
];

export const dataKey = {
    projectId: '_id',
    paymentSchedule: '_id',
    subsiduary: '_id',
};

export const invoiceTabs = [
    {
        id: 1,
        name: 'Payment Phase',
    },
    {
        id: 2,
        name: 'Invoices',
    },
];

export const errors = {
    PS: {
        projectId: 'Project is required',
        name: 'Name is required',
        amount: 'Amount is required',
    },
    INVOICE: {
        projectId: 'Project is required',
        paymentSchedule: 'Select a payment phase',
        name: 'Name is required',
        amount: 'Amount is required',
        invoiceNumber: 'Invoice Number is required',
        subsiduary: 'Subsiduary is required',
    },
};

export const fields = {
    PS: [
        { id: 1, name: 'Project', key: 'projectId', type: 'select' },
        { id: 2, name: 'Name', key: 'name', type: 'text' },
        { id: 3, name: 'Amount', key: 'amount', type: 'number' },
        {
            id: 4,
            name: 'Due date',
            key: 'dueDate',
            type: 'date',
        },
        {
            id: 6,
            name: 'Paid At',
            key: 'paidAt',
            type: 'date',
            label: 'If already paid',
        },
        { id: 5, name: 'Restricted', key: 'isRestricted', type: 'checkbox' },
        // { id: 6, name: 'Notes', key: 'notes', type: 'textarea' },
    ],
    INVOICE: [
        {
            id: 1,
            name: 'Project',
            key: 'projectId',
            type: 'select',
            otherKey: 'project',
        },
        {
            id: 2,
            name: 'Subsiduary',
            key: 'subsiduary',
            type: 'select',
            otherKey: 'subsiduary',
        },
        { id: 3, name: 'invoiceNumber', key: 'invoiceNumber', type: 'text' },
        {
            id: 4,
            name: 'Payment Schedule',
            key: 'paymentSchedule',
            type: 'multiSelect',
            otherKey: 'amountFill',
        },
        {
            id: 5,
            name: 'Invoice at',
            key: 'invoicedAt',
            type: 'date',
        },
        {
            id: 6,
            name: 'Due date',
            key: 'dueDate',
            type: 'date',
        },
        { id: 7, name: 'Amount', key: 'amount', type: 'number' },
        { id: 9, name: 'Other amount', key: 'otherAmount', type: 'dynamicInput' },
        { id: 10, name: 'Notes', key: 'notes', type: 'textarea' },

    ],
};

export const PSFieldsUpdate = [
    { id: 1, name: 'Project', key: 'projectId', type: 'select' },
    { id: 2, name: 'Name', key: 'name', type: 'text' },
    { id: 3, name: 'Amount', key: 'amount', type: 'number' },
    {
        id: 4,
        name: 'Due date',
        key: 'dueDate',
        type: 'date',
    },
    {
        id: 5,
        name: 'Paid At',
        key: 'paidAt',
        type: 'date',
        label: 'If already paid',
    },
    { id: 6, name: 'Restricted', key: 'isRestricted', type: 'checkbox' },
];

export const InvoiceFieldUpdate = [
    {
        id: 1,
        name: 'Project',
        key: 'projectId',
        type: 'select',
        otherKey: 'project',
    },
    // {
    //     id: 2,
    //     name: 'Payment Schedule',
    //     key: 'paymentSchedule',
    //     type: 'multiSelect',
    // },
    { id: 3, name: 'Subsiduary', key: 'subsiduary', type: 'select' },
    { id: 4, name: 'invoiceNumber', key: 'invoiceNumber', type: 'text' },
    { id: 5, name: 'Amount', key: 'amount', type: 'number' },
    // { id: 5, name: 'Status', key: 'status', type: 'text' },
    { id: 6, name: 'Invoiced At', key: 'invoicedAt', type: 'date' },
    { id: 7, name: 'Due Date', key: 'dueDate', type: 'date' },
    { id: 8, name: 'Notes', key: 'description', type: 'textarea' },
    //mongoes type not in ui only send data static
];

export const filterLists = [
    [

        // {
        //     title: 'status',
        //     data: [
        //         { name: 'Pending', value: 'PENDING' },
        //         { name: 'Invoiced', value: 'INVOICED' },
        //         { name: 'Partially Invoiced', value: 'PARTIALLY_INVOICED' },
        //     ],
        //     key: 'status',
        // },
        {
            title: 'Sort By',
            data: [
                { name: 'Ascending', value: 1 },
                { name: 'Descending', value: -1 },
            ],
            key: 'createdAt',
        },
    ],

    [
        // {
        //     title: 'status',
        //     data: [
        //         { name: 'Paid', value: 'PAID' },
        //         { name: 'Unpaid', value: 'UNPAID' },
        //     ],
        //     key: 'status',
        // },
        {
            title: 'Sort By',
            data: [
                { name: 'Ascending', value: 1 },
                { name: 'Descending', value: -1 },
            ],
            key: 'createdAt',
        },
    ],
];
