import { addScheduledRequest } from 'api/scheduledApi'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export default function useAddRequest() {
    const [dates, setDates] = useState([])
    const [formValues, setFormValues] = useState({})

    const queryClient = useQueryClient()
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );

    const userType = useSelector(
        (state) => state.userReducer?.userType?.userType
    );
    let handleAddScheduled = () => {

        let tempDate = dates.map((el) => el.format('YYYY-MM-DD'))

        let obj = {
            ...formValues, date: [...tempDate]
        }
        console.log('obj', obj)
        addScheduledRequest(obj)
            .then((res) => {
                userType === 'Admin' ? queryClient.invalidateQueries(['allScheduled', orgId])
                    : queryClient.invalidateQueries(['allScheduled'])
                setFormValues({})
            })
            .catch((err) => {
                console.log('er', err)
            })
            .finally(() => {

            })
    }

    return { dates, setDates, setFormValues, formValues, handleAddScheduled }
}
