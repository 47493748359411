import { addEodByStaff, updateEodByStaffAdmin } from "api/eod";
import { object } from "prop-types";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { jsonParser } from "utils/jsonParser";

export const useEodForm = () => {
  let initial = {
    duration: { hour: "", min: "" },
    project: "",
    screenName: "",
    description: "",
  };
  const orgId = useSelector(
    (state) => state.userReducer?.selectedOrganisation?._id
  );
  const queryClient = useQueryClient();
  const [inputFields, setInputFields] = useState([{ ...initial, id: 1 }]);
  const [lengthOfLastObj, setLengthOfLastObj] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);

  const handleOpenCloseForm = ({ eodItem }) => {
    if (!!eodItem) {
      setInputFields(() => {
        let temp = {
          duration: eodItem?.duration,
          project: eodItem?.project_detail?._id,
          screenName: eodItem?.screenName,
          description: eodItem?.description,
          eodId: eodItem?._id,
        };
        return [temp];
      });
    } else setInputFields([initial]);
    setOpenForm(!openForm);
    setLengthOfLastObj(false);
  };

  const handleFormChange = (i, value, key) => {
    let temp = [...inputFields];
    temp[i][key] = value;
    setInputFields(temp);
    checkForButtonDisabled(temp);
    // ///////////////////////////////////
    // let obj = {
    //   string: [],
    //   ob: [],
    // };
    // Object.values(temp[i]).forEach((key) => {
    //   if (typeof key === "object") {
    //     Object.values(key).forEach((value) => {
    //       if (key?.hour === 0 && key?.min === 0) {
    //         obj.ob.push(false);
    //       } else {
    //         let aa = value.toString().length > 0;
    //         obj.ob.push(aa);
    //       }
    //     });
    //   } else {
    //     let cc = key.toString().length > 0;
    //     obj.string.push(cc);
    //   }
    // });
    // let isFill = [...obj.string, ...obj.ob].every((el) => el === true);
    // setLengthOfLastObj(isFill);
  };

  const checkForButtonDisabled = (values) => {
    let obj = {
      string: [],
      ob: [],
    };
    for (let i = 0; i < values.length; i++) {
      Object.values(values[i]).forEach((key) => {
        if (typeof key === "object") {
          Object.values(key).forEach((value) => {
            if (key?.hour === 0 && key?.min === 0) {
              obj.ob.push(false);
            } else {
              let aa = value.toString().length > 0;
              obj.ob.push(aa);
            }
          });
        } else {
          let cc = key.toString().length > 0;
          obj.string.push(cc);
        }
      });
    }

    let isFill = [...obj.string, ...obj.ob].every((el) => el === true);
    setLengthOfLastObj(isFill);
  };
  const addFields = (index) => {
    if (index !== undefined) {
      let temp = inputFields;
      temp.splice(index, 1);
      setInputFields([...temp]);
      checkForButtonDisabled(temp);
      return;
    }
    setInputFields((prev) => [...prev, initial]);
    setLengthOfLastObj(false);
  };

  const addEod = async () => {
    try {
      // forAdd
      let dataSend = { data: [...inputFields] };
      //forUpdate
      let dataUpdate = { ...inputFields[0] };
      setIsAddLoading(true);
      if (!inputFields[0]?.eodId) {
        await addEodByStaff(dataSend, orgId);
        // addEodByStaff(dataSend).then(() => {
        //   queryClient.invalidateQueries(["allEodList"]);
        //   setInputFields([{}]);
        // });
      } else {
        await updateEodByStaffAdmin(dataUpdate, orgId);
        // updateEodByStaffAdmin(dataUpdate).then(() => {
        //   queryClient.invalidateQueries(["allEodList"]);
        //   setInputFields([{}]);
        // });
      }
      await queryClient.invalidateQueries(["allEodList"]);
      setInputFields([{}]);
      handleOpenCloseForm({ eodItem: null });
    } catch {
    } finally {
      setIsAddLoading(false);
    }
  };

  return {
    eodForm: {
      inputFields,
      handleFormChange,
      addFields,
      addEod,
      lengthOfLastObj,
      handleOpenCloseForm,
      openForm,
      isAddLoading,
    },
  };
};
