import { getClientDetails } from "api/invoice";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";


export const useGetAdditionalInfo = (projectId) => {
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );
    return useQuery(['AdditionalInfo', orgId, projectId], () => getClientDetails({
        orgId,
        projectId
    }), {
        // enabled: !!projectId
    });
};