import React from 'react'
import { useProjects } from 'react-query/projects/useProjects';
import { filterLists } from 'pages/invoice/data';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSubsidiaryList } from 'react-query/invoice/subsidiary/useSubsidiaryList';
import moment from 'moment';
import { financialYearArray } from 'pages/invoice/FilterInInvoice';
import useInvoice from 'pages/invoice/useInvoice';

export default function useInvoicePaymentFilter(activeTab) {

    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );

    const { data: subsidiaryList, isLoading: subsidiaryLoading } =
        useSubsidiaryList({ orgId });


    const { isLoading: projectLoading, data: projectsData } = useProjects('Active');



    let a = projectsData?.projects?.map((e) => {
        return { name: e.title, value: e.title }
    })

    let b = subsidiaryList?.map((e) => {

        return {
            name: e.title, value: e._id
        }
    })

    let c = moment.monthsShort().map((e, i) => {
        return { name: e, value: i + 1 }
    })
    let temp = [...financialYearArray];
    let d = temp?.map((e, i) => {
        // return { name: `${e?.startYear}-${e?.endYear}`, value: `${e?.startYear}-${e?.endYear}` }
        return { name: `${e?.endYear}`, value: `${e?.endYear}`, id: i }
    })


    let listFilter = [
        (activeTab == 2 && { title: 'Sub Company', key: 'subsiduary', data: b }),
        ...filterLists[activeTab - 1],
        // { title: 'Project By', key: 'ProjectBy', data: a },
        // { title: 'Month By', key: 'MonthBy', data: c, otherAction: 'addYear' },
        // { title: 'Financial Year', key: 'FinancialYear', data: d },
    ].filter((elm) => elm)

    const Clear = () => {
        const { handleClearFilterData } = useInvoice()
        handleClearFilterData()
    }

    return { projectLoading, listFilter, Clear }
}
