import { updateProject } from 'api/project';
import CustomSelect from 'components/CustomSelect';
import CreateCustomer from 'pages/invoice/customer/CreateCustomer';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useUpdateProject } from 'react-query/projects/useUpdateProject';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from 'utils/textTruncate';

const ShowOtherDetails = ({ errorCode, project, details, setError, error }) => {
    const [currency, setCurrency] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );

    // const [error, setError] = useState(null);
    const updateCurrency = async (dataToSend) => {
        try {
            setIsLoading(true);
            await updateProject(dataToSend);
            setError(null);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setError(errorCode);
    }, [errorCode]);

    const onChange = (e) => {
        setCurrency(e.target.value);
        const dataToSend = {
            formData: { currency: e.target.value },
            orgId: orgId,
            projectId: project?._id,
        };
        console.log('dataToSend', dataToSend);
        updateCurrency(dataToSend);
    };

    const handleClose = () => {
        setError(null);
    };

    const renderItem = () => {
        switch (error) {
            case 412:
                return (
                    <div>
                        <p style={{ color: 'var(--defaultWhite)' }}>
                            Add currency
                        </p>
                        <CustomSelect
                            isLoading={isLoading}
                            variant={'outlined'}
                            placeholder='Select currency'
                            menuItems={['USD', 'SAR', 'INR']}
                            value={currency ?? 'Select currency'}
                            handleChange={onChange}
                            menuRenderComponent={<SelectRender />}
                            selectRenderComponent={<SelectRender />}
                            labelClassName={'normalFont'}
                            containerClassName={'selectOutlined mb-2'}
                        />
                    </div>
                );

            case 413:
                return (
                    <div style={{ marginBottom: 30 }}>
                        <p style={{ color: 'var(--defaultWhite)' }}>
                            Add client
                        </p>
                        <CreateCustomer
                            handleClose={handleClose}
                            buttonText='Add client'
                            details={details}
                        />
                    </div>
                );

            default:
                return <></>;
        }
    };

    return <div>{renderItem()}</div>;
};

function SelectRender({ item, selected }) {
    return selected ? (
        <div className={`normalFont d_flex alignCenter textEllipse`}>
            <p className='textEllipse'> {capitalizeFirstLetter(item)}</p>
        </div>
    ) : (
        <div className={`normalFont d_flex alignCenter textEllipse`}>
            <p className='pl-1 textEllipse'> {capitalizeFirstLetter(item)}</p>
        </div>
    );
}

export default ShowOtherDetails;
