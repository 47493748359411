import React from 'react'
import './userLIstInDrawer.scss'
import CheckBoxInScheduleDrawer from '../checkBoxInScheduleDrawer/CheckBoxInScheduleDrawer'
import { useState } from 'react'

export default function UserLIstInDrawer({
    heading = 'Name',
    radio,
    arrOfObject,
    selected,
    handleSelectOption = () => { },
}) {
    const [isSelect, setIsSelect] = useState({
        value: '', isChecked: false
    })

    return (
        <div
            className='userListBox'
        >
            <p className='listHeader'>{heading}</p>
            {/* {type?.mode === 'userList' && */}
            <div className='userList'>
                {arrOfObject?.map((e, i) => {
                    return <div className='listRow' key={i}
                        onClick={() => setIsSelect((prev) => {
                            handleSelectOption(prev.value === e._id ? null : e, i);
                            return {
                                ...prev,
                                isChecked: !prev.isChecked, value: prev.value === e._id ? null : e._id
                            }
                        })}
                    >
                        <CheckBoxInScheduleDrawer
                            mode={radio && 'radio'}
                            isSelect={e._id === isSelect.value}
                        />
                        <div className='imgNameDiv'>
                            {!radio && <img src={e?.profilePicture}
                                className='img'
                            />}
                            <p>{e?.name}</p>
                        </div>

                    </div>
                })}
            </div>
            {/* } */}
            {/* {type?.mode === 'addRequest' &&
                <div className='userList '>
                    {arrOfObject?.map((e, i) =>
                        <div className='flexStart mb' key={i}
                            onClick={() => handleSelectOption(e.value)}
                        >
                            <CheckBoxInScheduleDrawer
                                mode='radio'
                                isSelect={e.value === selected}
                                handleCheckBox={() => handleSelectOption(e.value)}

                            />
                            <p className='textInRadio'>
                                {e.name}
                            </p>
                        </div>
                    )}
                </div>
            } */}
        </div>
    )
}
