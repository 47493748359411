export const validate = (values) => {
    const errors = {};
    const regexUserName = /^[a-zA-Z]*$/i;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,}$/i;

    if (!values.fullName) {
        errors.fullName = 'Client name is required!';
    }
    if (!values.address) {
        errors.address = 'Address is required!';
    }
    if (!values.email) {
        errors.email = 'Email is required!';
    } else if (!regexEmail.test(values.email)) {
        errors.email = 'This is not a valid email format!';
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone number is required!';
    }
    if (!values.country) {
        errors.country = 'Country is required!';
    }

    // if (!values.password) {
    //   errors.password = 'Password is required';
    // } else if (values.password.length < 4) {
    //   errors.password = 'Password must be more than 4 characters';
    // } else if (values.password.length > 10) {
    //   errors.password = 'Password cannot exceed more than 10 characters';
    // }
    return errors;
};