import React from 'react'
import CheckBoxInScheduleDrawer from '../CheckBoxInScheduleDrawer'
import './inputOrSelectBox.scss'

export default function InputOrSelectBox({
    isInputBox,
    text,
    placeholder,
    styleBox,
    onChange,
    isSelect,
    inputType = 'text',
    handleSelect = () => { },
    mode = 'checkbox'
}) {
    return (
        <div
            style={{ ...styleBox }}
            className='inputSelectBox'
            onClick={() => handleSelect(text)}
        >

            {mode === 'checkbox' &&
                <>
                    <p className='text'>{text}</p>
                    <CheckBoxInScheduleDrawer
                        isSelect={text === isSelect}
                    />
                </>
            }
            {mode === 'input' && <input
                className='inputBox'
                placeholder={placeholder}
                onChange={onChange}
                type={inputType}
            />}

            {mode === 'textarea' && <textarea
                rows={4}
                cols={2}
                className='inputBox'
                placeholder={placeholder}
                onChange={onChange}
                type={inputType}
            />}
            {/* {
                !isInputBox ?
                    <>
                        <p className='text'>{text}</p>
                        <CheckBoxInScheduleDrawer
                            isSelect={text === isSelect}
                        />
                    </> :
                    <input
                        className='inputBox'
                        placeholder={placeholder}
                        onChange={onChange}
                        type={inputType}
                    />

            } */}



        </div>
    )
}
