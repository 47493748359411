import { useQuery } from 'react-query';

import { getInvoiceProjects } from 'api/project';
import { useSelector } from 'react-redux';

export const useInvoiceProjects = (dataType = 'all') => {
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );

    const { isLoading, data, isError } = useQuery(
        ['activeProjects', orgId, 'Active', dataType],
        () => getInvoiceProjects(orgId, 'Active', dataType),
        {
            enabled: !!orgId,
            // retry: 0,
            // refetchOnMount: false,
        }
    );

    return { isLoading, data, isError };
};
