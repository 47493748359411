import React from "react";
import "./customTimePicker.scss";

import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon";

import DatePicker from "react-multi-date-picker";
import IosIcon from "components/icons/IosIcon";
function CustomTimePicker({ format = "hh:mm A", placeholder }) {
  return (
    <DatePicker
      // render={<InputIcon />}
      render={(value, openCalendar) => {
        return (
          <div onClick={openCalendar} className="iconContainer">
            {value ? (
              <p className="time_value">{value}</p>
            ) : (
              <p className="time_placeholder">{placeholder ?? "HH:MM A"}</p>
            )}{" "}
            <IosIcon name={"clock"} style={{ width: 15, height: 15 }} />
          </div>
        );
      }}
      disableDayPicker
      format={format}
      plugins={[
        <TimePicker
          hideSeconds
          style={
            {
              // background: "#FFF",
            }
          }
          className="time_picker_container"
        />,
      ]}
      className="xyz_time_picker"
      inputClass="input_time_picker"
      arrowClassName="arrow_time_picker"
      containerClassName="input_time_picker_container "
      placeholder={placeholder ?? "HH:MM A"}
      editable={false}
    />
  );
}

export default CustomTimePicker;
