import { axiosInstance } from './axiosInstance';

export const getAllSchedule = (orgId) =>
    axiosInstance.get(`/schedule/schedules-details/${orgId}`)
        .then((res) => res?.data?.data?.schedules)

export const getAuthSchedule = () =>
    axiosInstance.get(`/schedule/get-schedule`)
        .then((res) => res?.data?.data)

export const addScheduledRequest = (data) =>
    axiosInstance.post(`/schedule/add-schedule`, data)
        .then((res) => res?.data)

export const acceptOrRejectSchedule = (orgId, data, id) =>
    axiosInstance.post(`/schedule/permit/${orgId}`, data, { params: { id } }).then((res) => res?.data)

export const getFilteredSchedule = ({ type, orgId, data }) =>
    //orgId backend se jb sahi kr dnge to orgId bhejna h
    axiosInstance.get(`/schedule/${type}/`,
        { params: data })
        .then((res) => res?.data?.scheduleDetail)




        // /schedule/user-schedule-filter
        // /schedule/schedule-filter/



