import React, { useState } from "react";
import ChopLines from "chop-lines";

function EodTableData({
  isLastItem,
  onClickItem,
  eodItem,
  isUser,
  styleDescription,
}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className="tableSection"
      style={{
        borderBottom: isLastItem ? "1px solid var(--newBlueLight)" : null,
        cursor: isUser && "pointer",
      }}
      onClick={onClickItem}
    >
      <div className="eodText">
        <p> {eodItem?.project_detail?.title}</p>
      </div>
      <div className="eodText">
        <p>{eodItem?.screenName ?? "--"}</p>
      </div>
      <div className="eodDescription" style={styleDescription}>
        {expanded ? (
          <>
            <div
              // className="eodDescription"
              // style={styleDescription}
              dangerouslySetInnerHTML={{
                __html: eodItem?.description,
              }}
            />
            <div
              onClick={(event) => {
                event?.preventDefault();
                event?.stopPropagation();
                setExpanded((prev) => !prev);
              }}
              className="eodDescription_read_more"
            >
              Read Less
            </div>
          </>
        ) : (
          <ChopLines
            maxHeight={100}
            ellipsis={
              <p
                onClick={(event) => {
                  event?.preventDefault();
                  event?.stopPropagation();
                  setExpanded((prev) => !prev);
                }}
                className="eodDescription_read_more"
              >
                <span>Read More</span>
              </p>
            }
          >
            <Sanitized html={eodItem?.description} />
          </ChopLines>
        )}
      </div>
      {/* <div
        className="eodDescription"
        style={styleDescription}
        dangerouslySetInnerHTML={{
          __html: eodItem?.description,
        }}
      /> */}
      {/* <p> {eodItem?.description}</p> */}
      {/* </div> */}
      <div className="eodText">
        <p>{eodItem?.user_detail?.name}</p>
      </div>
      <div className="eodText">
        <p>
          {" "}
          {eodItem?.duration?.hour} hrs {"  "}
          {eodItem?.duration?.min} min
        </p>
      </div>
    </div>
  );
}

export default EodTableData;
const Sanitized = ({ html }) => (
  <div
    // className="eodDescription"
    // style={styleDescription}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);
