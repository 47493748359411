import CustomButton from 'components/CustomButton';
import FieldRendered from './FieldRendered';
import './modal.scss';
import useModal from './useModal';

const type = 'PS';

const PSModal = ({ project, activeTab, modalHandler, filterData }) => {
    const {
        details,
        fields,
        onSubmit,
        isLoading,
        onValueChange,
        isAdmin,
        paymentScheduleLoading,
        paymentScheduleData,
        projectsData,
        projectLoading,
    } = useModal({
        type,
        project,
        activeTab,
        modalHandler,
        filterData,
    });

    return (
        <div className='modal'>
            <p className='heading'>Add a Payment - Schedule</p>
            {
                <FieldRendered
                    details={details}
                    fields={fields}
                    isAdmin={isAdmin}
                    onValueChange={onValueChange}
                    menuItems={{
                        projectId: {
                            data: projectsData?.projects,
                            isLoading: projectLoading,
                        },
                        paymentSchedule: {
                            data: paymentScheduleData,
                            isLoading: paymentScheduleLoading,
                        },
                    }}
                />
            }
            <div className='button'>
                <CustomButton onClick={onSubmit} loading={isLoading}>
                    <p>Add</p>
                </CustomButton>
            </div>
        </div>
    );
};

export default PSModal;
