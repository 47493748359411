import CustomButton from 'components/CustomButton';
import CustomInput from 'components/customInput/CustomInput';
import Icon from 'components/icons/IosIcon';
import { countries } from "utils/countries";
import './editInfo.scss';
import { useAddUpdateClient } from './functionality/useUpdateClient';
import { CountryPicker } from 'pages/invoice/linkClients/compo/countryPiker/CountryPicker';

export const BasicDetail = ({ projects, customer, handleCloseModal, selectedProject }) => {
    const { handleChange,
        onSubmit,
        error,
        formValue: {
            address,
            country,
            email,
            fullName,
            phoneNumber
        } } = useAddUpdateClient(projects, customer, handleCloseModal, '', selectedProject)

    let a = {}
    countries.forEach((el) => {
        if (el.label === country) {
            a = { ...el }
        }
    })

    return (
        <div className='editInfo'>
            <div className='heading'>
                <p className='text1' >Edit Info</p>
                <span onClick={handleCloseModal} style={{ cursor: 'pointer' }}> <Icon name={'cross'} fontSize={30} /></span>
            </div>
            <div className='border'></div>
            <div className='newClientDiv'>
                <div className='row1'>
                    {InputLabel('Client Name',
                        (e) => handleChange(e.target.value, 'fullName'),
                        fullName,
                        error.fullName,)}
                    <div className='inputWithLevel'>
                        <p className='text'>Country</p>
                        <CountryPicker
                            onChange={(e, value) => handleChange(value, 'country')}
                            defaultValue={a}
                        />
                        {error.country && <p style={{ color: 'red' }}>{error.country}</p>}
                    </div>
                </div>
                <div className='row1'>
                    {InputLabel('Email Address', (e) => handleChange(e.target.value, 'email'), email, error.email)}
                    {InputLabel('Contact Number', (e) => handleChange(e.target.value, 'phoneNumber'), phoneNumber, error.phoneNumber)}
                </div>

                <p className='text'>Address</p>
                <textarea
                    style={{
                        width: "99%",
                        outline: 0,
                        backgroundColor: 'rgba(45, 52, 99, 1)',
                        padding: 15,
                        color: "#fff",
                        fontSize: 14,
                        border: "1px solid #363d72",
                        borderRadius: 6,
                        marginRight: 20,
                    }}
                    rows={4}
                    cols={50}
                    placeholder="Address"
                    // value={leadDetails?.description}
                    onChange={(e) => handleChange(e.target.value, 'address')}
                    value={address}
                    name="address"
                    className="addLeadTextArea"
                />
                <p style={{ color: 'red' }}>{error.address}</p>

                <div className='btnDiv'>
                    <CustomButton
                        onClick={onSubmit}
                    >Save</CustomButton>
                </div>

            </div>
        </div>
    )
}

let InputLabel = (label, onChange, value, error) => {
    return <div className='inputWithLevel'>
        <p className='text'>{label}</p>
        <CustomInput
            onChange={onChange}
            value={value}
            // placeholder={label}
            style={{
                backgroundColor: 'rgba(45, 52, 99, 1)',
            }}

        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
}


export let inputWithLevel = (label, onChange, value, error) => {
    return <div className='inputWithLevel'>
        <p className='text'>{label}</p>
        <CustomInput
            onChange={onChange}
            style={{ backgroundColor: '#413B70' }}
            value={value}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>

}

