import { updateClient } from 'api/invoice';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

export const useLinkClient = ({ handleIsLinkClient, project, isChangeClient, customerRemove, handleCloseModal }) => {
    const orgId = useSelector((state) => state.userReducer?.selectedOrganisation?._id);
    const queryClient = useQueryClient();
    const [selectedData, setSelectedData] = useState(customerRemove)
    const [isBtnDisable, setIsBtnDisable] = useState(false)

    const handleAddOldClientToProject = (item) => {
        setSelectedData(item)

    };

    const handleAddNewClient = (item) => {
        let customerId = item?._id;
        let data = {
            projects: [...item?.projects, project?._id],
        };
        updateClient(orgId, customerId, data).then((res) => {
            handleIsLinkClient && handleIsLinkClient(false)
        })

        // let customerId = item?._id;
        // let data = {
        //     projects: [...item?.projects, project?._id],
        // };
        // let data2 = {
        //     projects: customerRemove?.projects?.filter((el) => el !== project?._id),
        //     isDelete: true,
        // };
        // console.log('customerRemove', customerRemove)
        // console.log('item', item)
        // updateClient(orgId, customerId, data).then((res) => {
        //     handleIsLinkClient && handleIsLinkClient(false);
        //     if (isChangeClient) {
        //         updateClient(orgId, customerRemove?._id, data2);
        //         queryClient.invalidateQueries(['AdditionalInfo', orgId, project?._id]);
        //     }
        // });
    }

    const addClientBtn = () => {
        setIsBtnDisable(true);
        let customerId = selectedData?._id;
        let data = {
            projects: [...selectedData?.projects, project?._id],
        };

        let data2 = {
            projects: customerRemove?.projects?.filter((el) => el !== project?._id),
            isDelete: true,
        };

        let res = queryClient.getQueriesData(['AdditionalInfo', orgId, project?._id])
        console.log('res', res)
        updateClient(orgId, customerId, data)
            .then((res) => {
                handleCloseModal();
                handleIsLinkClient && handleIsLinkClient(false);
                if (isChangeClient) {
                    updateClient(orgId, customerRemove?._id, data2).then((res) => {
                        queryClient.invalidateQueries(['AdditionalInfo', orgId, project?._id]);
                    })
                }
            })
            .finally(() => {
                handleCloseModal();
                setIsBtnDisable(false);
            })

    }
    return { handleAddOldClientToProject, selectedData, addClientBtn, isBtnDisable, handleAddNewClient };
};
