import React, { useState } from "react";
import "./eodDropDown.scss";
import { ClickAwayListener } from "@mui/base";
import { Popover } from "@material-ui/core";

export const EodDropDown = ({
  handleDuration,
  value,
  // isOpen,
  handleOpenDurationList,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //handleDuration
  const [isOpen, setIsOpen] = useState(false);
  const [hourMint, setHourMint] = useState({
    hour: value?.hour ?? 0,
    min: value?.min ?? 0,
  });
  const hours = Array.from(Array(13), (_, i) => i.toString());
  const minutes = Array.from(Array(12), (_, i) => (i * 5).toString());

  const handleClickAway = () => {
    handleCloseList();
  };
  const handleCloseList = () => {
    setIsOpen(false);
  };
  const handleOpenList = () => {
    setIsOpen(true);
  };
  const onCLickList = (key, value) => {
    let temp = { ...hourMint };
    console.log({ temp });
    if (temp?.min === "" && key === "hour") {
      temp = {
        ...temp,
        min: 0,
      };
    }

    if (temp?.hour === "" && key === "min") {
      temp = {
        ...temp,
        hour: 0,
      };
    }
    temp = { ...temp, [key]: Number(value) };
    setHourMint(temp);
    handleDuration(temp);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="eodDropDown" onClick={handleClick}>
        <div className="df">
          <p className="text">
            <span style={{ color: "white" }}>{hourMint?.hour}</span>
            {"  Hours"}{" "}
          </p>
          <p className="text">
            <span style={{ color: "white" }}>{hourMint?.min}</span>
            {"   Minutes"}
          </p>
        </div>
      </div>
      {/* {isOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="durationDiv">
            <div className="hourDiv">
              <p className="hourText">Hours</p>
              {hours?.map((el) => {
                return (
                  <p
                    className="durationList"
                    onClick={() => onCLickList("hour", el)}
                    style={{ background: hourMint.hour === el && "#534783" }}
                  >
                    {el}
                  </p>
                );
              })}
            </div>
            <div className="hourDiv">
              <p className="hourText">Minutes</p>
              {minutes?.map((el) => {
                return (
                  <p
                    onClick={() => onCLickList("min", el)}
                    className="durationList"
                    style={{ background: hourMint.min === el && "#534783" }}
                  >
                    {el}
                  </p>
                );
              })}
            </div>
          </div>
        </ClickAwayListener>
      )} */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: actionButton ? "bottom" : "top",
        //   horizontal: actionButton ? "center" : "right",
        // }}
        // transformOrigin={{
        //   vertical: actionButton ? "top" : "bottom",
        //   horizontal: actionButton ? "center" : "left",
        // }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            // background: "var(--popUpColor)",
            background: "var(--newBlueLight)",
            color: "#FFF",
            // width: 300,
            // boxShadow : actionButton && "0px 0px 10px 5px #ffffff45"
          },
        }}
      >
        <div className="durationDiv">
          <div className="hourDiv">
            <p className="hourText">Hours</p>
            {hours?.map((el) => {
              return (
                <p
                  className="durationList"
                  onClick={() => onCLickList("hour", el)}
                  style={{ background: hourMint.hour === el && "#534783" }}
                >
                  {el}
                </p>
              );
            })}
          </div>
          <div className="hourDiv">
            <p className="hourText">Minutes</p>
            {minutes?.map((el) => {
              return (
                <p
                  onClick={() => onCLickList("min", el)}
                  className="durationList"
                  style={{ background: hourMint.min === el && "#534783" }}
                >
                  {el}
                </p>
              );
            })}
          </div>
        </div>
      </Popover>
    </div>
  );
};
