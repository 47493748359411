import React, { useState } from "react";
import "./eodForm.scss";
import Icon from "components/icons/IosIcon";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import CustomInput from "components/customInput/CustomInput";
import { element } from "prop-types";
import CustomButton from "components/CustomButton";
import CustomTextEditor from "components/customTextEditor/CustomTextEditor";
import { CustomSelectInputEod } from "../component/customSelectInputEod/CustomSelectInputEod";
import { EodDropDown } from "../component/eodDropDown/EodDropDown";
import { useEodForm } from "./useEodForm";
import { InputEod } from "../component/customSelectInputEod/InputEod";
import { useInvoiceProjects } from "react-query/projects/useInvoiceProjects";
import { useProjects } from "react-query/projects/useProjects";

export const EodForm = ({ handleOpenCloseForm, eodForm }) => {
  const {
    inputFields,
    handleFormChange,
    addFields,
    addEod,
    lengthOfLastObj,
    isAddLoading,
  } = eodForm;
  // const { data, isLoading: projectListIsLoading } = useInvoiceProjects()
  const { isLoading, data, isError } = useProjects("Active");
  //text Editor
  const editorRef = React.useRef("");
  //
  const handleDuration = (i, v) => {
    handleFormChange(i, v, "duration");
  };
  return isLoading ? (
    <div
      className="Loading"
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="eodFormDiv">
      <div className="section1">
        {/* <p>EodForm</p> */}
        <p></p>
        <span onClick={handleOpenCloseForm}>
          <Icon name="cross" style={{ cursor: "pointer" }} />
        </span>
      </div>
      <div className="form">
        {inputFields?.map((el, i) => {
          return (
            <div
              className={inputFields?.length - 1 !== i && "mainChildDiv"}
              key={el?.id}
            >
              {i > 0 && (
                <div className="remove_child_container">
                  <p
                    className="remove_child"
                    style={{
                      background: "var(--red)",
                    }}
                    onClick={() => addFields(i)}
                  >
                    X
                  </p>
                </div>
              )}
              <div className="formTopRow">
                <div className="topRow">
                  <p className="mb">Select Project</p>
                  <CustomSelectInputEod
                    // onChange={(event, value) => handleFormChange(i, value, 'project')}
                    onChange={(value) =>
                      handleFormChange(i, value?._id, "project")
                    }
                    list={data?.projects}
                    value={el?.project}
                    onPressEnter={(v) => handleFormChange(i, v?._id, "project")}
                  />
                </div>
                <div className="topRow">
                  <p className="mb">Section/Screen Name</p>
                  {/* <CustomInput
                                    style={{ marginBottom: 20 }}
                                    onChange={(e) => handleFormChange(i, e.target.value, 'screen')}
                                    value={el.value}
                                    placeholder='Screen/ Section'
                                /> */}
                  <InputEod
                    placeholder={"Screen /Section name"}
                    value={el?.screenName}
                    onChange={(e) =>
                      handleFormChange(i, e.target.value, "screenName")
                    }
                  />
                  {/* {InputEod({
                                    placeholder: 'Screen /Section name',
                                    value: el?.screenName,
                                    onChange: (e) => handleFormChange(i, e.target.value, 'screenName'),
                                })} */}
                </div>
                <div className="topRow">
                  <p className="mb">Duration</p>
                  <EodDropDown
                    handleDuration={(v) => handleDuration(i, v)}
                    value={el?.duration}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                {/* <p className='mb'>Add Description</p> */}
                <CustomTextEditor
                  className="eod"
                  isEOD="yes"
                  value={el?.description}
                  ref={editorRef}
                  updateData={(value) => {
                    handleFormChange(i, value, "description");
                  }}
                  disable={false}
                />
                {/* <CustomTextEditor
                                // isEOD='yes'
                                //   value={info?.description}
                                ref={editorRef}
                                // disable={disabled}
                                updateData={(data) => {
                                    
                                }}
                                onChange={(e) => handleFormChange(i, e, 'description')}
                            /> */}
              </div>
            </div>
          );
        })}
        <div className="saveBtn">
          {!inputFields[0].eodId && (
            <div style={{ marginRight: 20 }}>
              <CustomButton
                onClick={addFields}
                // style={{ backgroundColor: 'red' }}
                // backgroundColor={!lengthOfLastObj ? 'red' : 'blue'}
                disabled={!lengthOfLastObj || isAddLoading}
              >
                Add more
              </CustomButton>
            </div>
          )}
          <CustomButton
            onClick={() => {
              // handleOpenCloseForm()
              addEod();
            }}
            disabled={isAddLoading || !lengthOfLastObj}
            loading={isAddLoading}
          >
            {inputFields[0].eodId ? "Update" : " Save"}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
];

const hours = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 3, value: 3 },
];

//    <select
//     onChange={(e) => onChangeForm(e.target.value, 'hour')}
//     className='eodSelect'
// >
//     <option>Duration in hours</option>
//     {hours.map((el, index) => {
//         return <option
//             key={index}

//         >
//             {el}
//         </option>
//     })}
// </select>
// <select
//     onChange={(e) => onChangeForm(e.target.value, 'min')}
//     className='eodSelect'
// >
//     <option>Duration in minutes</option>
//     {minutes.map((el, index) => {
//         return <option
//             key={index}

//         >
//             {el}
//         </option>
//     })}
// </select>
