import CustomButton from "components/CustomButton";
import Icon from "components/icons/IosIcon";
import { Calendar } from "react-multi-date-picker";
import InputOrSelectBox from "../component/checkBoxInScheduleDrawer/inputOrSelectBox/InputOrSelectBox";
import UserLIstInDrawer from "../component/useLIstInDrawer/UserLIstInDrawer";
import "./addRequest.scss";
import useAddRequest from "./useAddRequest";
import { useEffect, useMemo } from "react";
import moment from "moment";
import CustomTimePicker from "components/customTimePicker/CustomTimePicker";
export let typeData = [
  { _id: 1, name: "Leave-Full Day ", value: "LEAVE_FULL_DAY" },
  { _id: 2, name: "Leave-1st Half ", value: "LEAVE_FIRST_HALF" },
  { _id: 3, name: "Leave-2nd Half ", value: "LEAVE_SECOND_HALF" },
  { _id: 4, name: " WFH-Full Day", value: "WFH_FULL_DAY" },
  { _id: 5, name: "WFH-1st Half ", value: "WFH_FIRST_HALF" },
  { _id: 6, name: "WFH-2nd Half ", value: "WFH_SECOND_HALF" },
  { _id: 7, name: "Early Going ", value: "EARLY_GOING" },
  { _id: 8, name: "Late Coming ", value: "LATE_COMING" },
  { _id: 9, name: "Break ", value: "BREAK" },
];

function groupDatesIntoLogs(dates) {
  let tempDates = dates?.map((item) => new Date(item.format("YYYY-MM-DD")));
  const sortedDates = tempDates
    .map((date) => moment(date))
    .sort((a, b) => a - b);
  const logs = [];
  let currentLog = [sortedDates[0]];

  for (let i = 1; i < sortedDates.length; i++) {
    const prevDate = currentLog[currentLog.length - 1];
    const currentDate = sortedDates[i];

    if (currentDate.diff(prevDate, "days") > 1) {
      if (currentLog.length > 1) {
        const startDate = currentLog[0].format("MMM D, YYYY");
        const endDate = prevDate.format("MMM D, YYYY");
        logs.push(`${startDate} to ${endDate}`);
      } else {
        logs.push(prevDate.format("MMM D, YYYY"));
      }
      currentLog = [currentDate];
    } else {
      currentLog.push(currentDate);
    }
  }

  if (currentLog.length > 0) {
    if (currentLog.length > 1) {
      const startDate = currentLog[0].format("MMM D, YYYY");
      const endDate = currentLog[currentLog.length - 1].format("MMM D, YYYY");
      logs.push(`${startDate} to ${endDate}`);
    } else {
      logs.push(currentLog[0].format("MMM D, YYYY"));
    }
  }
  return logs;
}

export default function AddRequest({ closeDrawer }) {
  const { dates, setDates, formValues, setFormValues, handleAddScheduled } =
    useAddRequest();
  const printedDates = useMemo(
    () => (!!dates?.length ? groupDatesIntoLogs(dates) : null),
    [dates]
  );

  let isType =
    formValues.type === "EARLY_GOING" ||
    formValues.type === "LATE_COMING" ||
    formValues.type === "BREAK";
  return (
    <div className="addRequestBox">
      <div className="addRequestBox_header">
        <p className="headerText">Add Request</p>
        <Icon name="cross" className="icon" onClick={closeDrawer} />
      </div>
      <div className="line"></div>
      {/*  */}
      <div className="addRequestSection">
        <p className="addRequestBox_headingText">Select Date</p>
        <div className="calenderInSchedule">
          <Calendar
            value={dates}
            onChange={setDates}
            multiple
            minDate={new Date()}
            disableYearPicker
            className="addRequestBox_calender"
            // shadow={false}
            // disableMonthPicker
            // range
            // rangeHover
          />
        </div>
        {/* dates show */}
        {printedDates && (
          <div className="dateDiv">
            {printedDates?.map((el) => {
              return <p className="datesList">{el}</p>;
            })}
          </div>
        )}

        {/* TYPE */}
        <div>
          <p className="mh addRequestBox_headingText">Type</p>
          <UserLIstInDrawer
            type={{
              headingName: "Select Leave Type",
              mode: "addRequest",
            }}
            // heading='Leave'
            radio={true}
            handleSelectOption={(v) =>
              setFormValues({ ...formValues, type: v?.value })
            }
            arrOfObject={typeData}
            selected={formValues.type}
          />
        </div>
        {/* time */}
        {isType && (
          <div className="df">
            <div>
              <p className="mh addRequestBox_headingText">
                {" "}
                {formValues.type === "EARLY_GOING" ? "End Time" : "Start Time"}
              </p>
              {/* <div className="inputBox">
                <input
                  type="time"
                  className="dateInput"
                  onChange={(e) =>
                    setFormValues({ ...formValues, time: e.target.value })
                  }
                />
              </div> */}

              <CustomTimePicker />
            </div>
            {formValues.type === "BREAK" && (
              <div>
                <p className="mh addRequestBox_headingText">Duration</p>
                {/* <div className="inputHourDiv ">
                  <input
                    className="inputHour "
                    type="number"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        duration: {
                          ...formValues.duration,
                          hour: e.target.value,
                        },
                      })
                    }
                    max={0}
                    min={24}
                  />
                  <p>hrs</p>
                  <input
                    className="inputHour "
                    type="number"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        duration: {
                          ...formValues.duration,
                          min: e.target.value,
                        },
                      })
                    }
                  />
                  <p>min</p>
                </div> */}

                <CustomTimePicker format="HH:mm" placeholder={"HH:MM"} />
              </div>
            )}
          </div>
        )}
        {/* reason */}
        <p className="mh addRequestBox_headingText">Reason</p>
        <InputOrSelectBox
          mode="textarea"
          isInputBox={true}
          styleBox={{ width: "100%", height: "100px" }}
          placeholder={"Reason"}
          onChange={(e) =>
            setFormValues({ ...formValues, reason: e.target.value })
          }
        />

        <div className="flexEnd">
          <CustomButton
            backgroundColor={"var(--primary)"}
            onClick={() => {
              handleAddScheduled();
              closeDrawer();
            }}
          >
            Save
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
