import React from 'react'
import './rowItem.scss'
import PartitionWall from '../partitionWall/PartitionWall'
import Icon from 'components/icons/IosIcon';
import moment from 'moment'
import { useSelector } from 'react-redux';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IconButton, Tooltip } from '@mui/material';


// userName={el?.user_detail && el?.user_detail[0]?.name}
// userPic={el?.user_detail && el?.user_detail[0]?.profilePicture}
// date={el?.date}
// type={el?.type}
// time={el?.time}
// reason={el?.reason}
// status={el?.status}
// authorized_by={el?.authorized_by && el?.authorized_by[0]}
// duration={el?.duration && el?.duration}


let typeDataObj = {
    LEAVE_FIRST_HALF: 'Leave - 1st Half',
    LEAVE_FULL_DAY: 'Leave - Full Day',
    LEAVE_SECOND_HALF: 'Leave - 2nd Half ',
    WFH_FULL_DAY: 'WFH - Full Day ',
    WFH_FIRST_HALF: 'WFH - 1st Half ',
    WFH_SECOND_HALF: 'WFH - 2nd Half ',
    EARLY_GOING: 'Early Going ',
    LATE_COMING: 'Late Coming ',
    BREAK: 'Break ',
}


function RowItem({
    el,
    userName,
    userPic,
    date,
    type,
    time,
    reason,
    status,
    authorized_by,
    duration,
    handleAcceptReject

}) {
    const userType = useSelector(
        (state) => state.userReducer?.userType?.userType
    );

    let statusBG = {
        Pending: 'var(--primary)',
        Approved: 'var(--green)',
        Rejected: 'var(--red)'
    }
    return (
        <div className='rowItemSection'>
            {userType === 'Admin' && <>
                <div className='child1'>
                    <img src={el?.user_detail && el?.user_detail[0]?.profilePicture}

                        className='img'
                    />
                    <p>{el?.user_detail && el?.user_detail[0]?.name}</p>
                </div>
                <PartitionWall />
            </>}
            <div className='child2'>{el?.date && moment(el?.date).format('DD-MMM-YYYY')}</div>
            <PartitionWall />
            <div className='child2'>{typeDataObj[el?.type]}</div>
            <PartitionWall />
            <div className='child2' style={{ flexDirection: 'column' }}>

                {el?.time ?
                    <>{el?.time}
                        {el?.type === 'Break' &&
                            <p>{`( ${el?.duration?.hour}hrs ${el?.duration?.min}min )`}
                            </p>}
                    </>
                    : 'N/A'
                }
            </div>
            <PartitionWall />
            <div className='child3'>

                <p className='text'>{el?.reason.slice(0, 25)}</p>

                <Tooltip title="Delete">
                    <Icon name='hoverIcon'
                        className='icon'
                        onClick={() => console.log('as!!sd')}
                    />
                </Tooltip>
            </div>
            <PartitionWall />
            <div className='child4'>
                <p
                    className='text'
                    style={{ backgroundColor: statusBG[el?.status] }}
                >{el?.status}</p>
            </div>
            <PartitionWall />
            <div className='child5'>
                {(el?.status === 'PENDING' && userType === 'Admin') ? <>
                    <div className='crossTickSection'>
                        <span
                            className='icon'
                            onClick={() => handleAcceptReject('REJECTED')}
                            style={{ marginRight: 20 }}>
                            <HighlightOffIcon />
                        </span>
                        <span
                            className='icon'
                            onClick={() => handleAcceptReject('APPROVED')}
                        >
                            <CheckCircleOutlineIcon />
                        </span>
                        {/* <Icon name='cross'
                            className='icon'
                            onClick={() => handleAcceptReject('Rejected')}
                        />
                        <Icon name='circularTick'
                            className='icon'
                            style={{ marginLeft: 20 }}
                            onClick={() => handleAcceptReject('Approved')} /> */}
                    </div>
                </>
                    :
                    <>
                        <div className='profileDSection'>
                            {(userType !== 'Admin' && el?.status === 'Pending') ?
                                <p>N/A</p> :
                                <img src={el?.authorized_by && el?.authorized_by[0]?.profilePicture}
                                    className='imgProfile'
                                />

                            }

                        </div>

                        {userType === 'Admin' && <Icon name='crossRed'
                            className='icon'
                            onClick={() => handleAcceptReject('PENDING')}
                        />}

                    </>

                }
            </div>
        </div>
    )
}
export default RowItem