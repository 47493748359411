import React, { useState } from "react";
import "./TableRow.scss";
import Icon from "components/icons/IosIcon";
import EodTableData from "./EodTableData";

const initialState = {
  projectName: "",
  description: "",
  durationInHours: "",
  durationInMinutes: "",
};

export default function TableRow({
  isLastItem = true,
  styleDescription,
  isForm,
  isHeader,
  isTable,
  eodItem,
  onClickOpenEod,
  onClickItem,
  isUser,
}) {
  const [formValues, setFormValues] = useState(initialState);
  const [isOpen, setIsOpen] = useState({
    isOpenProjectList: false,
    isOpenDescriptionBox: false,
    isOpenDurationBox: false,
  });

  return (
    <>
      {isHeader && (
        <div
          className="tableSection textGray"
          style={{
            borderBottom: isLastItem ? "1px solid var(--newBlueLight)" : null,
          }}
        >
          <div className="eodText">
            <p>Project</p>
          </div>
          <div className="eodText">
            <p>Section / Screen Name</p>
          </div>
          <div className="eodDescription" style={styleDescription}>
            <p> Description</p>
          </div>
          <div className="eodText">
            <p>Submitted By</p>
          </div>
          <div className="eodText">
            <p>Duration</p>
          </div>
        </div>
      )}
      {isTable && (
        <EodTableData
          eodItem={eodItem}
          isLastItem={isLastItem}
          onClickItem={onClickItem}
          isUser={isUser}
          styleDescription={styleDescription}
        />
      )}

      {isForm && (
        <div
          className="tableSection"
          style={{
            borderBottom: isLastItem ? "1px solid var(--newBlueLight)" : null,
          }}
          onClick={onClickOpenEod}
        >
          <div className="eodText">
            <p className="formText">Select Projects</p>
          </div>
          <div className="eodText">
            <p className="formText">+Add Section / Screen Name</p>
          </div>
          <div className="eodDescription" style={styleDescription}>
            <p className="formText">+ Add Description</p>
          </div>
          <div className="eodTextIcon">
            <p className="formText">Duration</p>
            <div style={{ display: "flex" }}>
              <Icon name={"check"} style={{ marginLeft: 10 }} />
              <Icon name={"cross"} style={{ marginLeft: 10 }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
