import React from 'react'
import './tableLinkClient.scss'
import Icon from 'components/icons/IosIcon';
import { Checkbox } from '@material-ui/core';
import avatar from '../../../../../assets/images/avatar.png'

export const TableLinkClient = ({
    heading = false,
    isLast,
    item,
    onClick,
    isChecked
}) => {
    return (<>
        {heading &&
            <div className='tableHeaderBox'>
                <div className='tableBox2'>Client Name</div>
                <div className='tableBox'>Phone Number</div>
                <div className='tableBox'>Email Address</div>
                <div className='tableBox'>Address</div>
            </div>
        }
        {!heading &&
            <div className='tableRowBox'
                style={{
                    borderBottom: isLast ? '1px solid gray' : null,
                    cursor: 'pointer',
                }}
                onClick={onClick}
            >
                <div className='clientNameBox'>
                    <div className='clientNameBoxChild'>
                        <Checkbox
                            checked={isChecked}
                            style={{
                                color: !isChecked ? 'rgba(45, 52, 99, 1)' : 'white',
                                marginRight: 20
                            }
                            }
                        />
                        <img
                            src={item?.profilePicture ?? avatar}
                            className='img'
                        />
                        <p>
                            {item?.fullName}
                        </p>
                    </div>
                    <span
                        style={{ cursor: 'pointer' }}>
                        <Icon
                            name='info'
                            style={{
                                width: 20,
                                height: 20
                            }}
                        />
                    </span>

                </div>
                <div className='tableBox'>{item?.phoneNumber ?? '--'}</div>
                <div className='tableBox'>{item?.email} </div>
                <div className='tableBox'>{item?.address} </div>
            </div>
        }
    </>

    )
}
