import CustomDatePickerModal from 'components/CustomModal/CustomDatePickerModal';
import Icon from 'components/icons/IosIcon';
import { useState } from 'react';
import './yearMonthPicker.scss';
import { Switch } from '@material-ui/core';

export const YearMonthPicker = ({
    handleFilterData,
    filterData
}) => {
    const [showDateFilter, setShowDateFilter] = useState(false)

    let emptyDateComeFromBE = filterData?.MonthBy === '' && filterData?.FinancialYear === '';
    let date = new Date();
    let current = {
        month: date.getMonth() + 1,
        year: date.getFullYear()
    }

    const handleOpen = (value) => {
        setShowDateFilter(value)
    }

    const handleChange = (e) => {
        let str = e.toString().split(' ')
        handleOpen(false)
        handleFilterData([monthObj[str[1]], str[3]], 'FinancialYear', 'monthYear')
    }

    const onNextPrevMonth = (type) => {
        if (type === 'next') {
            if (filterData?.MonthBy < 12) {
                handleFilterData([filterData?.MonthBy + 1, filterData?.FinancialYear], 'FinancialYear', 'monthYear')
            }
        } else if (type === 'prev') {
            if (filterData?.MonthBy > 1) {
                handleFilterData([filterData?.MonthBy - 1, filterData?.FinancialYear], 'FinancialYear', 'monthYear')
            }
        } else if (type === 'all') {
            if (emptyDateComeFromBE) {
                handleFilterData([current.month, current.year], 'FinancialYear', 'monthYear')
            } else {
                handleFilterData(['', ''], 'FinancialYear', 'monthYear')
            }

        }
        // handleFilterData([monthObj[str[1]], filterData?.FinancialYear], 'FinancialYear', 'monthYear')
    }


    return (
        <div className='yearMonthPicker'>
            <div
                className='switchAll'
                onClick={() => onNextPrevMonth('all')}
            >
                <p >
                    All
                </p>
                <Switch
                    checked={emptyDateComeFromBE}
                />
            </div>
            <div
                className={`btn ${emptyDateComeFromBE && 'btnDisable'}`}
                onClick={() => !emptyDateComeFromBE ? onNextPrevMonth('prev') : undefined}>
                <Icon name={'prev'} />
            </div>
            <div
                className={`dateYear ${emptyDateComeFromBE && 'btnDisable'}`}
                onClick={handleOpen.bind(this, true)}
            >
                <p className='text mr' >{numberMonthObj[filterData?.MonthBy] ?? 'All'}</p>
                <p className='text'>{filterData?.FinancialYear}</p>
            </div>
            <div
                className={`btn ${emptyDateComeFromBE && 'btnDisable'}`}
                onClick={() => !emptyDateComeFromBE ? onNextPrevMonth('next') : undefined}
            >
                <Icon name='next' />
            </div>
            <CustomDatePickerModal
                handleChange={handleChange}
                showModal={showDateFilter}
                handleClose={handleOpen}
            />
        </div>
    )
}


export default YearMonthPicker

let monthObj = {
    Jan: '1',
    Feb: '2',
    Mar: '3',
    Apr: '4',
    May: '5',
    Jun: '6',
    Jul: '7',
    Aug: '8',
    Sep: '9',
    Oct: '10',
    Nov: '11',
    Dec: '12',
}

let numberMonthObj = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
}