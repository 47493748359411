import { Button } from '@material-ui/core';
import { useState } from 'react';
import './additionalInfo.scss';
// import CustomModal from 'components/CustomModal/CustomModal'
import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import NoData from 'components/NoData';
import moment from 'moment';
import { useEffect } from 'react';
import { ClientListCompo } from './clientListCompo/ClientListCompo';
import { BasicDetail } from './component/editInfo/BasicDetail';
import { OtherInfo } from './component/editInfo/OtherInfo';
import { useGetAdditionalInfo } from './useGetAddInfo';

const AdditionalInfo = ({ modalHandler, showModal, project, selectedProject }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [whichCompo, setWhichCompo] = useState(0);
    const { data, refetch, isLoading, isFetching } = useGetAdditionalInfo(project?._id);
    //for Change Client
    useEffect(() => {
        refetch();
    }, [project?._id]);

    const handleOpenModal = (index) => {
        setIsOpen(true);
        setWhichCompo(index);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };
    let ArrOfCompo = [
        <BasicDetail
            projects={data?.customers[0]?.projects}
            customer={data?.customers[0]}
            setIsOpen={setIsOpen}
            handleCloseModal={handleCloseModal}
            selectedProject={selectedProject}
        />,
        <OtherInfo />,
        <ClientListCompo
            project={project}
            customer={data?.customers[0]}
            handleCloseModal={handleCloseModal}
        />,
    ];

    return (
        <div className='additionalInfo'>
            {isLoading || isFetching ? (
                <div className='loading'>
                    <CircularProgress />
                </div>
            ) : data?.customers?.length === 0 ? (
                <NoData />
            ) : (
                <>
                    <div className='box2'>
                        <div className='heading'>
                            <p className='textColor'>Basic Detail</p>
                            <div className='btnDiv'>
                                <Button className='btn' onClick={() => handleOpenModal(2)} style={{ marginRight: 10 }}>
                                    Change Client
                                </Button>
                                <Button className='btn' onClick={() => handleOpenModal(0)}>
                                    Edit
                                </Button>
                            </div>
                        </div>
                        <p className='clientName'>{data?.customers?.[0]?.fullName}</p>
                        <div className='dfm'>
                            {textPair('Phone Number', data?.customers?.[0]?.phoneNumber)}
                            {textPair('Email Address', data?.customers?.[0]?.email)}
                        </div>
                        <div className='dfm'>
                            {textPair('Country', data?.customers?.[0]?.country)}
                            {textPair('Project Assigned Date', data?.customers?.[0]?.assignedDate?.[project?._id])}
                        </div>
                        {textPair('Address', data?.customers?.[0]?.address)}
                    </div>
                    {/* <div className='box2'>
                            <div className='heading'>
                                <p className='textColor'>Other Information</p>
                                <Button
                                    className='btn'
                                    onClick={() => handleOpenModal(1)}
                                >Edit</Button>
                            </div>
                            <p className='clientName'>Martin Carder</p>
                            <div className='dfm'>
                                {textPair('Project Currency ', '+1 2453456476')}
                                {textPair('Project Currency ', '+1 2453456476')}
                            </div>
                            {textPair('Number of Amount Released This Month', '+1 2453456476')}
                        </div> */}
                </>
            )}

            {/* <CustomModal
                open={showModal}
                handleClose={modalHandler}>
                {ArrOfCompo[whichCompo]}
            </CustomModal> */}
            <Dialog
                maxWidth={false}
                open={isOpen}
                PaperProps={{
                    style: {
                        borderRadius: 20,
                        border: 'none',
                        backgroundColor: '#0E0841',
                        color: 'white',
                        maxWidth: '750px',
                        // overflow: "auto",
                        maxHeight: '500px',
                    },
                }}
                onBackdropClick={() => setIsOpen(false)}
            >
                {ArrOfCompo[whichCompo]}
            </Dialog>
        </div>
    );
};

export default AdditionalInfo;

let textPair = (title, tittleValue) => {
    return (
        <div style={{ width: '70%' }}>
            <p className='lightText'>{title}</p>
            {title === 'Project Assigned Date' ? (
                <p>{!!tittleValue ? moment(tittleValue).format('MM/DD/YYYY') : '---'}</p>
            ) : (
                <p>{tittleValue ?? '---'}</p>
            )}
        </div>
    );
};
