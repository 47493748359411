import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';

const MyList = ({ data, renderItem, length }) => {

    return (
        data?.map((el, index) => renderItem({
            item: el,
            index,
            style: { marginBottom: 10 },
            isLast: index === length - 1,
        }))
        // <AutoSizer>
        //     {({ width, height }) => (
        //         <List
        //             height={height}
        //             width={width - 0}
        //             itemCount={data?.length}
        //             itemSize={60}
        //             style={{ marginBottom: 150 }}
        //         >
        //             {({ index, style }) =>
        //                 renderItem({
        //                     item: data[index],
        //                     index,
        //                     style: { ...style, marginBottom: 10 },
        //                     isLast: index === length - 1,
        //                 })
        //             }

        //         </List>
        //     )}
        // </AutoSizer>
    );
};

export default MyList;
