import React, { useState, useRef, useEffect, useCallback } from "react";
import "./customSelectInputEod.scss";
import { ClickAwayListener } from "@mui/base";
export const CustomSelectInputEod = ({
  onChange,
  list,
  value,
  onPressEnter,
}) => {
  // let listObj = {}
  // list?.forEach((el) => {
  //     listObj[el?._id] = el?.title
  // })
  useEffect(() => {
    if (value) {
      let projectName = list?.find((el) => el?._id === value);
      setInputValue(projectName?.title);
    }
  }, [list, value]);

  // console.log('listObj', listObj)

  //for input auto focus
  const autoFocusFn = useCallback(
    (element) => (element ? element.focus() : null),
    []
  );
  //
  const [isOpenInput, setIsOpenInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [lists, setLists] = useState(list);

  const handleVisibilityOfInput = (v) => {
    setIsOpenInput(v);
  };
  const onTextChange = (e) => {
    setInputValue(e.target.value);
  };

  const onClickList = (itm) => {
    setInputValue(itm?.title);
    onChange(itm);
    handleVisibilityOfInput(false);
  };

  const handleKeyDown = (event) => {
    let index = 0;
    if (event.key === "Enter" && inputValue.length > 0) {
      setInputValue(lists[index]?.title);
      onPressEnter(lists[index]);
      handleVisibilityOfInput(false);
    }
  };

  useEffect(() => {
    let temp = list?.filter((el) =>
      el?.title?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setLists(temp);
  }, [inputValue]);
  const onOpenSelect = () => {
    setLists(list);
    handleVisibilityOfInput(true);
    setInputValue("");
  };
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (!value) {
            setInputValue("");
          }
          handleVisibilityOfInput(false);
        }}
      >
        <div
          className="selectDiv"
          onClick={() => {
            if (value) {
              let projectName = list?.find((el) => el?._id === value);
              console.log({ projectName });
              setInputValue(projectName?.title);
            }
          }}
        >
          {isOpenInput ? (
            <>
              <input
                className="selectDivInput"
                ref={autoFocusFn}
                value={inputValue}
                onChange={onTextChange}
                onKeyDown={handleKeyDown}
              />
              <div className="inputSelectList">
                <div className="ulOfSelectList">
                  {!!!lists?.length ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 20,
                      }}
                    >
                      {" "}
                      No project found
                    </div>
                  ) : (
                    lists?.map((el) => {
                      return (
                        <li
                          onClick={() => onClickList(el)}
                          style={{
                            background:
                              value === el._id
                                ? "#30346a"
                                : inputValue.length > 0 &&
                                  lists[0]?._id === el._id &&
                                  "#666483",
                          }}
                        >
                          {el?.title}
                        </li>
                      );
                    })
                  )}
                </div>
              </div>
            </>
          ) : (
            <p
              onClick={onOpenSelect}
              className={"placeholder"}
              style={{ color: inputValue && "white" }}
            >
              {inputValue?.length > 0 ? inputValue : " Select Project"}
            </p>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};
