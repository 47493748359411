import { CircularProgress } from '@mui/material';
import classes from './projectList.module.css';
import { useEffect } from 'react';

const ProjectList = ({
    onProjectChange,
    projectListData,
    projectListLoading,
    active,
    handleIsLinkClient
}) => {

    useEffect(() => {
        onProjectChange({ title: 'All projects', totalProject: projectListData?.length })
    }, [!!projectListData])

    return (
        <div className={classes.projectList} style={{ height: '97vh' }}>
            {projectListLoading && !projectListData?.length ? (
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            ) : (
                !!projectListData && (
                    [{
                        title: 'All projects',
                        totalProject: projectListData?.length
                    }, ...projectListData]?.map((el, i) => (
                        <ProjectListItem
                            key={el?._id}
                            data={el}
                            active={active?._id === el?._id}
                            onPress={() => {
                                onProjectChange(el)
                                handleIsLinkClient(false)
                            }
                            }
                        />
                    ))
                )
            )}
        </div>
    );
};

const ProjectListItem = ({ onPress, active, data }) => {
    return (
        <div
            onClick={onPress}
            className={`${classes.projectListItem} ${active ? classes.active : ''
                }`}
        >
            {data?.title}
        </div>
    );
};

export default ProjectList;
