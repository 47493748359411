import React from 'react';
import CustomModal from './CustomModal';
import Calendar from 'react-calendar';
import './datePicker.css';
import 'react-calendar/dist/Calendar.css';

function CustomDatePickerModal({ showModal, handleClose, handleChange }) {
    return (
        <CustomModal minWidth='24vw' open={showModal} handleClose={handleClose}>
            <div style={{ position: 'relative' }} className='customDatePicker'>
                <Calendar onChange={handleChange} value={new Date()} />
            </div>
        </CustomModal>
    );
}

export default CustomDatePickerModal;
