import React, { useMemo, useState } from "react";
import "./EODUpdate.scss";
import Icon from "components/icons/IosIcon";
import EODTable from "./component/eodTable/EODTable";
import EodFilter from "./eodFilter/EodFilter";
import { ClickAwayListener, Drawer } from "@material-ui/core";
import { EodForm } from "./eodForm/EodForm";
import { useEODUpdate } from "./useEODUpdate";
import { useGetEODList } from "./useGetEODList";
import NoData from "components/NoData";
import { CircularProgress } from "@mui/material";
import { useEodForm } from "./eodForm/useEodForm";
import CustomButton from "components/CustomButton";
import { object } from "prop-types";

export default function EODUpdate({ onClickOpenEod }) {
  // const { onChangeForm } = useEODUpdate() delete this
  const {
    resData: { data, isFetching, isLoading, isRefetching },
    memberList,
    projectList,
    //filter
    onclickFilterType,
    openFilter,
    onClickFilterBtn,
    handleOpenCloseFilter,
    handlePagination,
    page,
    resetMPFilterList,
    MPSelectAll,
    filterObj,
  } = useGetEODList();
  const { eodForm } = useEodForm();
  const filterCount = useMemo(() => {
    let count = 0;
    count += filterObj?.endDate && filterObj?.startDate ? 1 : 0;
    count += filterObj?.member?.length ? 1 : 0;
    count += filterObj?.projectId?.length ? 1 : 0;

    return count;
  }, [filterObj]);
  let { handleOpenCloseForm, openForm } = eodForm;
  return (
    <div className="containerEod">
      <div className="flexRowSpaceBetween">
        <p style={{ fontSize: 20 }}>EOD Updates</p>
        <div style={{ position: "relative" }}>
          <div>
            <span
              style={{ marginRight: 10, cursor: "pointer" }}
              onClick={handleOpenCloseForm}
            >
              <Icon name="addRound" />
            </span>
            {/* <label htmlFor='openFilter'> */}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenCloseFilter(!openFilter.open)}
            >
              <Icon name="filter" />

              {!!filterCount && (
                <div className="filter_badge">{filterCount}</div>
              )}
            </span>
          </div>
          {/* </label> */}

          {/* <input id="openFilter" type="checkbox" /> */}
          {/* {openFilter.open && <ClickAwayListener
                        onClickAway={() => handleOpenCloseFilter(false)}

                    > */}
          {openFilter.open ? (
            <div
              style={{
                position: "absolute",
                height: "100vh",
                width: "100vw",
                // background: "rgba(0, 0, 0, 0.18)",
                zIndex: 1,
                transform: "translate(-90%, -100px)",
              }}
              onClick={() => handleOpenCloseFilter(!openFilter.open)}
            />
          ) : null}
          <div
            className={
              openFilter.open ? "clickToOpenFilter" : "clickToCloseFilter"
            }
          >
            <EodFilter
              onclickFilterType={onclickFilterType}
              openFilter={openFilter}
              memberList={memberList}
              projectList={projectList}
              onClickFilterBtn={onClickFilterBtn}
              resetMPFilterList={resetMPFilterList}
              MPSelectAll={MPSelectAll}
            />
          </div>
          {/* </ClickAwayListener>} */}
        </div>
      </div>

      {isLoading ? (
        <div
          className="Loading"
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : Object.keys(data?.data?.eodDetail ?? {})?.length === 0 ? (
        <NoData />
      ) : (
        Object.keys(data?.data?.eodDetail ?? {})?.map((el, i) => {
          return (
            <EODTable
              key={i}
              item={data?.data?.eodDetail?.[el]}
              index={i}
              onClickItem={(eodItem) => handleOpenCloseForm({ eodItem })}
              date={el}
            />
          );
        })
      )}

      {/* {data?.data?.eodDetail?.map((el, i) => {
                return <EODTable
                    key={i}
                    item={el}
                    index={i}
                    onClickItem={(eodItem) => handleOpenCloseForm({ eodItem })}
                />
            })} */}
      {Object.keys(data?.data?.eodDetail ?? {})?.length !== 0 &&
        !isLoading &&
        !isRefetching && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {data?.data?.hasPreviousPage && (
              <CustomButton
                onClick={() => handlePagination("prev")}
                style={{ marginRight: 20 }}
                // disabled={page === 1}
                disabled={!data?.data?.hasPreviousPage}
              >
                Prev
              </CustomButton>
            )}
            {data?.data?.hasNextPage && (
              <CustomButton
                onClick={() => handlePagination("next")}
                // disabled={data?.data?.eodDetail?.length === 0}
                disabled={!data?.data?.hasNextPage}
              >
                Next
              </CustomButton>
            )}
          </div>
        )}
      <Drawer
        anchor={"right"}
        open={openForm}
        PaperProps={{
          style: {
            transform: "none",
            transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            backgroundColor: "rgb(10 6 49)",
            width: 750,
            borderRadius: 35,
            maxHeight: "60vh",
            top: 80,
            right: 20,
            overflow: "auto",
            height: "auto",
            minHeight: 300,
          },
        }}
      >
        <EodForm handleOpenCloseForm={handleOpenCloseForm} eodForm={eodForm} />
      </Drawer>
    </div>
  );
}

let dummy = [
  {
    _id: {
      month: 5,
      day: 8,
      year: 2023,
    },
    eodDetail: [
      {
        _id: "6458b5d09c540b65c730d519",
        createdAt: "2023-05-08T08:41:16.161Z",
        description: "yerstrs",
        duration: {
          hour: 6,
          min: 15,
        },
        project_detail: {
          _id: "61d68fc3fd1d040016d826ef",
          title: "wincy testing",
        },
      },
      {
        _id: "6458a4e9fc6dc557dcb205ee",
        createdAt: "2023-05-08T07:29:03.248Z",
        description: "mayy8",
        duration: {
          hour: 6,
          min: 15,
        },
        project_detail: {
          _id: "61d68fc3fd1d040016d826ef",
          title: "wincy testing",
        },
      },
    ],
  },
  {
    _id: {
      month: 4,
      day: 25,
      year: 2023,
    },
    eodDetail: [
      {
        _id: "6447896bb24fb582aeecb3ce",
        createdAt: "2023-04-25T08:03:21.701Z",
        description: "tuesday",
        duration: {
          hour: 6,
          min: 15,
        },
        project_detail: {
          _id: "61d68fc3fd1d040016d826ef",
          title: "wincy testing",
        },
      },
    ],
  },
  {
    _id: {
      month: 4,
      day: 22,
      year: 2023,
    },
    eodDetail: [
      {
        _id: "6443bd142c4cab3e9f1bc407",
        createdAt: "2023-04-22T10:20:21.716Z",
        description: "mmppppp",
        duration: {
          hour: 6,
          min: 15,
        },
        project_detail: {
          _id: "61d68fc3fd1d040016d826ef",
          title: "wincy testing",
        },
      },
      {
        _id: "64435ec818299315c022edac",
        createdAt: "2023-04-22T04:05:48.595Z",
        description: "working on implemting api",
        duration: {
          hour: 2,
          min: 35,
        },
        project_detail: {
          _id: "638f12977849922d430f0b6d",
          title: "Marketing Project 001 2",
        },
      },
    ],
  },
  {
    _id: {
      month: 4,
      day: 21,
      year: 2023,
    },
    eodDetail: [
      {
        _id: "644279bf36fcde97d9ca25d1",
        createdAt: "2023-04-21T11:53:51.266Z",
        description: "demo project",
        duration: {
          hour: 1,
          min: 11,
        },
        project_detail: {
          _id: "615438a8d8aa0b00169fc016",
          title: "Yanabea",
        },
      },
      {
        _id: "644279be36fcde97d9ca25cc",
        createdAt: "2023-04-21T11:53:51.266Z",
        description: "demo project",
        duration: {
          hour: 1,
          min: 11,
        },
        project_detail: {
          _id: "615438a8d8aa0b00169fc016",
          title: "Yanabea",
        },
      },
    ],
  },
];
