import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import CustomButton from 'components/CustomButton';
import BtnWrapper from 'components/btnWrapper/BtnWrapper';
import { LightTooltip } from 'components/tooltip/LightTooltip';
import { invoiceTabs } from 'pages/invoice/data';
import useInvoice from 'pages/invoice/useInvoice';
import InvoicePaymentFilter from '../invoicePaymentFilter/InvoicePaymentFilter';
import './detailsButton.scss';
import PSItem from '../ListItem/PSItem';
import InvoiceItem from '../ListItem/InvoiceItem';

const DetailsButton = ({
    onTabChange,
    activeTab,
    modalHandler,
    filterData,
    handleFilterData,
    handleClearFilterData,
    handleResetFilterData,
    setIsAddInfo,
    isAddInfo,
    isShowBtn,
}) => {
    const { openFilter, setOpenFilter } = useInvoice();

    let filterBoxCount = Object.values(filterData[activeTab - 1]).filter((el) => el).length;

    return (
        <>
            <div className='buttonArea'>
                <div style={{ display: 'inline-block' }}>
                    <BtnWrapper>
                        {invoiceTabs.map((el, i) => (
                            <CustomButton
                                onClick={onTabChange.bind(this, el.id, i)}
                                key={i}
                                type={el?.id === isAddInfo ? 'contained' : 'text'}
                            >
                                {el.name}
                            </CustomButton>
                        ))}
                        <p></p>
                        {isShowBtn && (
                            <CustomButton
                                onClick={() => setIsAddInfo(3)}
                                // key={i}
                                type={isAddInfo === 3 ? 'contained' : 'text'}
                            >
                                {'Additional Info'}
                            </CustomButton>
                        )}
                    </BtnWrapper>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <LightTooltip title='Filter' arrow> */}
                    <div className='displayFlex' onClick={() => setOpenFilter(!openFilter)}>
                        {filterBoxCount > 0 && (
                            <div className='filterCount'>
                                <p className='text'>{filterBoxCount}</p>
                            </div>
                        )}
                        <FilterListIcon
                            style={{
                                color: 'var(--defaultWhite)',
                                fontSize: 35,
                            }}
                        />
                    </div>
                    {/* </LightTooltip> */}
                    <div className='iconWrapper' style={{ visibility: isAddInfo === 3 ? 'hidden' : null }}>
                        <IconButton onClick={modalHandler.bind(this, true)}>
                            <AddIcon
                                style={{
                                    color: 'var(--defaultWhite)',
                                    fontSize: 26,
                                }}
                                // type={'contained'}
                            />
                        </IconButton>
                    </div>
                </div>
            </div>

            <InvoicePaymentFilter
                activeTab={activeTab}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                filterData={filterData[activeTab - 1]}
                handleFilterData={handleFilterData}
                //
                handleClearFilterData={handleClearFilterData}
                handleClose={() => setOpenFilter(!openFilter)}
                handleResetFilterData={handleResetFilterData}
            />
        </>
    );
};

export default DetailsButton;
