import CustomButton from 'components/CustomButton';
import { InvoiceFieldUpdate } from 'pages/invoice/data';
import { useState } from 'react';
import DynamicFieldAdd from '../../dynamicFieldAdd/DynamicFieldAdd';
import FieldRendered from './FieldRendered';
import './modal.scss';
import { useModalUpdate } from './useModalUpdate';
//
import Dialog from '@mui/material/Dialog';
//

const type = 'INVOICE';

const InvoiceModalUpdate = ({
    modalData,
    active,
    onSubmit,
    filterData,
    activeTab,
    project,
    selectedProject
}) => {
    const {
        details,
        isAdmin,
        onValueChange,
        projectsData,
        projectLoading,
        paymentScheduleData,
        paymentScheduleLoading,
        subsidiaryList,
        subsidiaryLoading,
        onUpdate,
        isLoading,
        handleDeleteItem,
    } = useModalUpdate({
        type,
        modalData,
        active,
        onSubmit,
        filterData,
        keyForApi: 'invoice-new',
        activeTab,
        project,
        selectedProject
    });
    const [inputFields, setInputFields] = useState(modalData?.otherAmount);

    const [askDelete, setAskDelete] = useState(false);

    const handleInputChange = (index, e) => {
        let temp = [...inputFields];
        temp[index][e.target.name] = e.target.value;
        setInputFields(temp);
    };

    const addFields = () => {
        let a = { name: '', value: '' };
        setInputFields([...inputFields, a]);
    };
    const removeFields = (i) => {
        let temp = [...inputFields];
        temp.splice(i, 1);
        setInputFields(temp);
    };

    const handleCloseDeleteModal = () => {
        setAskDelete(!askDelete);
    };

    return (
        <div className='modal'>
            <p className='heading'>Update InVoice</p>
            {
                <FieldRendered
                    details={details}
                    fields={InvoiceFieldUpdate}
                    isAdmin={isAdmin}
                    onValueChange={onValueChange}
                    menuItems={{
                        projectId: {
                            data: projectsData?.projects,
                            isLoading: projectLoading,
                        },
                        paymentSchedule: {
                            data: paymentScheduleData,
                            isLoading: paymentScheduleLoading,
                        },
                        subsiduary: {
                            data: subsidiaryList,
                            isLoading: subsidiaryLoading,
                        },
                    }}
                />
            }

            <DynamicFieldAdd
                inputFields={inputFields}
                handleInputChange={handleInputChange}
                addFields={addFields}
                removeFields={removeFields}
            />
            <div className='button'>
                <CustomButton
                    style={{ marginRight: 10 }}
                    backgroundColor={'var(--red)'}
                    onClick={handleCloseDeleteModal}
                >
                    <p>Delete</p>
                </CustomButton>

                <CustomButton
                    onClick={() => onUpdate(inputFields)}
                    loading={isLoading}
                >
                    <p>Update</p>
                </CustomButton>
            </div>
            <Dialog
                open={askDelete}
                // onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div className='askDelete'>
                    <p className='text'>Do you want to delete</p>
                    <div>
                        <CustomButton
                            style={{ marginRight: 10 }}
                            backgroundColor={'var(--red)'}
                            onClick={() => {
                                handleCloseDeleteModal();
                                handleDeleteItem();
                            }}
                        >
                            <p>Yes</p>
                        </CustomButton>
                        <CustomButton
                            style={{ marginRight: 10 }}
                            onClick={handleCloseDeleteModal}
                        // backgroundColor={'var(--red)'}
                        >
                            <p>No</p>
                        </CustomButton>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default InvoiceModalUpdate;
