import { CircularProgress } from '@mui/material';
import CustomButton from 'components/CustomButton';
import { TableLinkClient } from 'pages/invoice/linkClients/compo/tableLinkClient/TableLinkClient'
import { useLinkClient } from 'pages/invoice/linkClients/useLinkClient';
import React from 'react'
import { useCustomerList } from 'react-query/invoice/customer/useCustomerList';

export const ClientListCompo = ({
    project,
    handleCloseModal,
    customer
}) => {
    const { data, isLoading, isError } = useCustomerList();
    const { handleAddOldClientToProject, selectedData, addClientBtn, isBtnDisable } = useLinkClient({
        project,
        // handleIsLinkClient: handleCloseModal,
        isChangeClient: true,
        customerRemove: customer,
        handleCloseModal,
    })

    return (
        <div style={{ padding: '25px 5px' }}>
            <TableLinkClient heading={true} />
            <div>
                {
                    (isLoading) ?
                        <div className='loader'><CircularProgress /></div>
                        :
                        data?.map((item, i, arr) => {
                            return <TableLinkClient
                                item={item}
                                isLast={i === arr.length - 1}
                                onClick={() => {
                                    handleAddOldClientToProject(item)
                                }}
                                isChecked={item?._id === selectedData?._id}
                            />
                        })
                }

                {!isLoading && <div style={{ margin: '20px 5px 0px 0px', display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomButton
                        onClick={addClientBtn}
                        disabled={isBtnDisable}
                    >
                        Save Client
                    </CustomButton>
                </div>}
            </div>
        </div>
    )
}
