import CustomButton from 'components/CustomButton'
import CustomInput from 'components/customInput/CustomInput'
import React from 'react'
import { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';


export default function DynamicFieldAdd({
    inputFields, handleInputChange, addFields, removeFields
}) {

    return (
        <div >
            {inputFields?.map((el, i) => {
                return < div style={{ display: 'flex', marginBottom: 10 }} >
                    <CustomInput
                        name='name'
                        placeholder='Name'
                        style={{ width: '90%' }}
                        onChange={(e) => handleInputChange(i, e)}
                        value={el?.name}
                        type='text'
                    />
                    <CustomInput
                        name='value'
                        placeholder='Amount'
                        style={{ width: '95%' }}
                        onChange={(e) => handleInputChange(i, e)}
                        value={el?.value}
                        type='number'
                    />
                    <Tooltip title="Download Invoice" sx={{ width: 10 }}
                        onClick={() => removeFields(i)}
                    >
                        <IconButton sx={{ width: 10 }}>
                            <DeleteIcon
                                color='warning'
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            )}
            <CustomButton
                style={{ width: '100%', marginBottom: 10 }}
                onClick={addFields}
            >
                Add more Amount
            </CustomButton>

        </div >
    )
}
