import CustomButton from 'components/CustomButton';
import FieldRendered from './FieldRendered';
import './modal.scss';
import useModal from './useModal';
import ShowOtherDetails from './ShowOtherDetails';
import DynamicFieldAdd from '../../dynamicFieldAdd/DynamicFieldAdd';
import { useState } from 'react';
import SelectWithSelect from 'components/select/SelectWithSelect';

const type = 'INVOICE';

const InvoiceModal = ({ project, activeTab, modalHandler, filterData }) => {
    const {
        details,
        fields,
        onSubmit,
        isLoading,
        onValueChange,
        isAdmin,
        paymentScheduleData,
        paymentScheduleLoading,
        projectLoading,
        projectsData,
        subsidiaryList,
        subsidiaryLoading,
        invLoading,
        errorCode,
    } = useModal({
        type,
        project,
        activeTab,
        modalHandler,
        filterData,
    });

    const [inputFields, setInputFields] = useState([]);
    const [error, setError] = useState(null);
    const handleInputChange = (index, e) => {
        let temp = [...inputFields];
        temp[index][e.target.name] = e.target.value;
        setInputFields(temp);
    };

    const addFields = () => {
        let a = { name: '', value: '' };
        setInputFields([...inputFields, a]);
    };
    const removeFields = (i) => {
        let temp = [...inputFields];
        temp.splice(i, 1);
        setInputFields(temp);
    };

    return (
        <div className='modal'>
            <p className='heading'>Add a Invoice</p>
            {
                <FieldRendered
                    invLoading={invLoading}
                    details={details}
                    fields={fields}
                    isAdmin={isAdmin}
                    onValueChange={onValueChange}
                    menuItems={{
                        projectId: {
                            data: projectsData?.projects,
                            isLoading: projectLoading,
                        },
                        paymentSchedule: {
                            data: paymentScheduleData,
                            isLoading: paymentScheduleLoading,
                        },
                        subsiduary: {
                            data: subsidiaryList,
                            isLoading: subsidiaryLoading,
                        },
                    }}
                />
            }
            {/* <SelectWithSelect
                type='inInvoice'
                options={paymentScheduleData ?? []}
                onChange={(e, obj) => {
                    onValueChange(obj, 'paymentSchedule')
                }}
            />
 */}

            <ShowOtherDetails
                project={details?.projectId?.value}
                errorCode={errorCode}
                details={details}
                error={error}
                setError={setError}
            />
            {![412, 413].includes(error) && (
                <>
                    <DynamicFieldAdd
                        inputFields={inputFields}
                        handleInputChange={handleInputChange}
                        addFields={addFields}
                        removeFields={removeFields}
                    />
                    <div className='button'>
                        <CustomButton
                            onClick={() => onSubmit(inputFields)}
                            loading={isLoading}
                        >
                            <p>Add</p>
                        </CustomButton>
                    </div>
                </>
            )}


        </div>
    );
};

export default InvoiceModal;
