import { axiosInstance } from './axiosInstance';

export const getPaymentSchedule = (orgId, params) => {
    return axiosInstance
        .get(`/payment-schedule/${orgId}`, { params })
        .then((res) => res.data);
};

export const getInvoices = (orgId, params) => {
    return axiosInstance
        .get(`/invoice-new/${orgId}`, { params })
        .then((res) => res.data);
};

export const createPaymentSchedule = (orgId, data) => {
    return axiosInstance
        .post(`/payment-schedule/${orgId}`, data)
        .then((res) => res.data);
};

export const createInvoice = (orgId, data) => {
    return axiosInstance
        .post(`/invoice-new/${orgId}`, data)
        .then((res) => res.data);
};

export const updatePaymentSchedule = (orgId, data) => {
    return axiosInstance
        .patch(`/payment-schedule/${orgId}`, data)
        .then((res) => res.data);
};

export const updateInvoice = (orgId, data) => {
    return axiosInstance
        .patch(`/invoice-new/${orgId}`, data)
        .then((res) => res.data);
};


export const deletePaymentInvoiceItem = (type, orgId, id) => {
    return axiosInstance
        .delete(`/${type}/${orgId}/${id}`)
        .then((res) => res.data);
};

