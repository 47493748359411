import { filterLists } from 'pages/invoice/data';
import FilterItemSelector from '../../filterItemSelector/FilterItemSelector';
import './invoicePaymentFilter.scss';
import useModal from '../Modal/useModal';
import useInvoicePaymentFilter from './useInvoicePaymentFilter';
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

export default function InvoicePaymentFilter({
    openFilter,
    filterData,
    handleFilterData,
    activeTab,
    type,
    handleClearFilterData,
    handleClose,
    handleResetFilterData,
}) {
    const { projectLoading, listFilter } = useInvoicePaymentFilter(activeTab);

    return (
        <div>
            {openFilter && (
                <div className='invoicePaymentFilterSec'>
                    <div className='heading'>
                        <p className='text1'>Quick Filters</p>
                        <div className='df'>
                            <p onClick={() => handleResetFilterData(activeTab)} className='text2'>
                                Reset
                            </p>
                            <p onClick={() => handleClearFilterData(activeTab)} className='text2'>
                                Clear All
                            </p>

                            {/* <IconButton onClick={handleClose}
                                size={15}
                            > */}
                            <CloseIcon onClick={handleClose} color='warning' style={{ cursor: 'pointer' }} />
                            {/* </IconButton> */}
                        </div>
                    </div>
                    <div className='filterSection'>
                        {listFilter?.map((el, i) => {
                            return (
                                <FilterItemSelector
                                    key={i}
                                    el={el}
                                    handleFilterData={handleFilterData}
                                    selected={filterData[el.key]}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}
