import { FormHelperText } from '@material-ui/core';
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import classes from './select.module.css';
import './selectForSelect.scss';

const SelectWithSelect = ({
    options,
    labelKey = 'title',
    loading,
    className,
    variant,
    placeholder,
    paperClassName,
    labelClassName,
    onChange,
    name,
    value,
    errorText,
    type = 'common',
    initialSelected
}) => {
    return (<>
        {type === 'common' && <div>
            <Autocomplete
                disablePortal
                id='combo-box-demo'
                options={options}
                getOptionLabel={(option) => option[labelKey]}
                sx={{ paddingRight: 0, padding: 0 }}
                renderInput={(params) =>
                    value ? (
                        <TextField {...params} variant='standard' />
                    ) : (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            variant='standard'
                        />
                    )
                }
                loading={loading}
                className={className}
                placeholder={placeholder}
                classes={{
                    paper: `${classes.paperClassName} ${paperClassName}`,
                    option: `${classes.tag} ${labelClassName}`,
                    loading: classes.loading,
                    clearIndicator: classes.loading,
                    popupIndicator: classes.loading,
                    input: classes.input,
                    root: classes.root,
                }}
                onChange={onChange}
                value={value}
            />
            {errorText && (
                <FormHelperText
                    error={errorText ? true : false}
                // style={errorStyle}
                >
                    {errorText}
                </FormHelperText>
            )}
        </div>}

        {type === 'inInvoice' &&
            <Autocomplete
                multiple
                disablePortal
                id='combo-box-demo'
                options={options}
                getOptionLabel={(option) => option?.name}
                placeholder={placeholder}
                sx={{
                    paddingRight: 0,
                    padding: 0,
                    marginBottom: 2.5,
                    maxWidth: 500,
                    height: 'auto',
                    color: 'white',
                    '& .css-6od3lo-MuiChip-label': {
                        color: 'white',
                    }
                }}
                renderInput={(params) => {
                    // console.log('params', params)
                    return value ? (
                        <TextField {...params} variant='standard'
                        />
                    ) : (
                        <TextField
                            {...params}
                            // placeholder={placeholder}
                            variant='standard'
                        />
                    )
                }}

                loading={loading}
                className={className}
                classes={{
                    paper: `${classes.paperClassName} ${paperClassName}`,
                    option: `${classes.tag} ${labelClassName}`,
                    loading: classes.loading,
                    clearIndicator: classes.loading,
                    popupIndicator: classes.loading,
                    input: classes.input,
                    root: classes.root,
                }}
                onChange={onChange}
                value={value}
                defaultValue={initialSelected}

            />
        }
    </>
    );
};

export default SelectWithSelect;

