import { getAmountDetail } from 'api/invoice';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export const UseProjectAmountDetail = (projectId, totalProject, filterData) => {
    const orgId = useSelector((state) => state.userReducer?.selectedOrganisation?._id);

    const month = projectId ? 'all' : filterData?.MonthBy;
    const year = projectId ? 'all' : filterData?.FinancialYear;
    let res = useQuery(['projectAmount', orgId, projectId, month, year], () =>
        getAmountDetail({ orgId, projectId, month, year })
    );
    let cardData = [];
    if (res?.data) {
        let remainAmount = res?.data?.totalAmount - res?.data?.releaseAmount;
        // !!totalProject && cardData.unshift({ id: 0, title: 'Total Project', amount: totalProject, icon: 'project' })
        if (!!totalProject) {
            cardData = [
                { id: 0, title: 'Total Project', amount: totalProject, icon: 'project', hideSign: true },
                {
                    id: 1,
                    title: 'No of Payment Phase',
                    amount: res?.data?.numberOfPaymentPhase,
                    icon: 'noOfPayment',
                    hideSign: true,
                },
                { id: 2, title: 'Total Amount', amount: res?.data?.totalAmount, icon: 'dollar' },
                { id: 3, title: 'Paid Amount', amount: res?.data?.releaseAmount, icon: 'dollar' },
                { id: 4, title: 'Remaining Amount', amount: remainAmount, icon: 'dollar', borderRed: true },
            ];
        } else {
            cardData = [
                { id: 2, title: 'Total Amount', amount: res?.data?.totalAmount, icon: 'dollar' },
                { id: 3, title: 'Paid Amount', amount: res?.data?.releaseAmount, icon: 'dollar' },
                { id: 4, title: 'Remaining Amount', amount: remainAmount, icon: 'dollar', borderRed: true },
            ];
        }
    }
    return { ...res, cardData };
};
