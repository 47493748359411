import { createInvoice, createPaymentSchedule, updateInvoice, updatePaymentSchedule } from 'api/paymentSchedule';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { updateData, updateProjects } from './ops';
import { getAmountDetail } from 'api/invoice';

const apiData = [
    {
        api: createPaymentSchedule,
        key: 'payment-schedules',
        dataKey: 'paymentSchedule',
    },
    { api: createInvoice, key: 'invoice-new', dataKey: 'invoice' },
    {
        api: updatePaymentSchedule,
        key: 'payment-schedules',
        dataKey: 'paymentSchedule',
    },
    { api: updateInvoice, key: 'invoice-new', dataKey: 'invoice' },
];

export const useInvoiceMutateQuery = (
    activeTab,
    modalHandler,
    onError,
    projectId,
    status,
    month,
    year,
    createdAt,
    subsiduary
) => {
    const queryClient = useQueryClient();
    const orgId = useSelector((state) => state.userReducer?.selectedOrganisation?._id);

    const dep = [
        apiData[activeTab - 1].key,
        orgId,
        projectId ?? 'all',
        status ?? 'all',
        month ?? 'all',
        year ?? 'all',
        createdAt ?? 'all',
        subsiduary ?? 'all',
    ];
    const depAll = [apiData[activeTab - 1].key, orgId, 'all', 'all', 'all', 'all', 'all', 'all'];

    const projectDep = ['activeProjects', orgId, 'Active'];

    const { mutate, isLoading, error } = useMutation(
        (data) => {
            return apiData[activeTab - 1].api(orgId, data);
        },
        {
            onSuccess: async (data) => {
                try {
                    const prevPS = queryClient.getQueryData(dep);

                    const prevPSAll = queryClient.getQueryData(depAll);

                    // const prevProjectData =
                    //     queryClient.getQueryData(projectDep);

                    const key = apiData[activeTab - 1].dataKey;
                    const { prevPS: prevPSNew, prevPSAll: prevPSAllNew } = updateData(prevPSAll, prevPS, data, key);

                    // const { newProjectData } = updateProjects({
                    //     prevProjectData,
                    //     activeTab,
                    //     data,
                    // });

                    const dependency = [
                        'projectAmount',
                        orgId,
                        projectId,
                        !projectId ? month : 'all',
                        !projectId ? year : 'all',
                    ];

                    console.log('dependency', dependency);

                    queryClient.invalidateQueries(dependency);
                    queryClient.setQueryData(dep, prevPSNew);
                    queryClient.setQueryData(depAll, prevPSAllNew);
                    // queryClient.setQueryData(projectDep, { ...newProjectData });

                    modalHandler && modalHandler(false);
                } catch (error) {
                    console.error('error in useInvoiceMutateQuery', error);
                }
            },
            onError: (error) => {
                onError && onError(error);
            },
            onSettled: () => {},
        }
    );

    return { mutate, isLoading, error };
};
