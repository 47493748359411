export const updateData = (prevPSAll, prevPS, data, key) => {
    let index = -1;
    let indexAll = -1;
    const PSID = data?.data?.[key]?._id;
    prevPSAll &&
        prevPSAll?.data?.some((el, i) => {
            if (el._id === PSID) {
                indexAll = i;
                return true;
            }
        });
    prevPS &&
        prevPS?.data?.some((el, i) => {
            if (el._id === PSID) {
                index = i;
                return true;
            }
        });
    if (index === -1) {
        prevPS?.data?.unshift(data.data?.[key]);
    } else {
        prevPS.data[index] = data.data?.[key];
    }
    if (indexAll === -1) {
        prevPSAll?.data?.unshift(data.data?.[key]);
    } else {
        prevPSAll.data[indexAll] = data.data?.[key];
    }
    return { prevPSAll, prevPS };
};

export const updateProjects = ({ prevProjectData, activeTab, data }) => {
    const key = activeTab === 1 ? 'paymentSchedule' : 'invoice';
    const pId = data?.data?.[key]?.projectId?._id;
    console.log('pId', pId);
    prevProjectData?.projects.forEach((el) => {
        if (el?._id === pId) {
            el[key].push(data?.data?.[key]);
            console.log('el', el);
        }
    });
    const newProjectData = prevProjectData;
    return { newProjectData };
};

export const deleteInvoicePsHandler = (prevPS, key, id) => {
    const newData = [];

    if (prevPS?.data) {
        prevPS.data?.forEach((el) => {
            if (el?._id !== id) {
                newData.push(el);
            }
        });
        prevPS.data = [];
        prevPS.data = newData;
    }

    return { newData: prevPS };
};

export const updateProjectsInvoice = ({ prevProjectData, activeTab, data }) => {
    const key = activeTab === 1 ? 'paymentSchedule' : 'invoice';
    const pId = data?.projectId?._id;
    prevProjectData?.projects.forEach((el) => {
        if (el?._id === pId) {
            console.log('pId', pId);
            console.log('el', el);
            console.log('data', data);
            const d = [];
            el[key].forEach((el2) => {
                if (el2?._id !== data?._id) {
                    d.push(el2);
                }
            });
            el[key] = d;
        }
    });
    const newProjectData = prevProjectData;
    return { newProjectData };
};
