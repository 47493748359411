import { createCustomer, updateCustomer } from 'api/invoice';
import CustomButton from 'components/CustomButton';
import CustomSelect from 'components/CustomSelect';
import SelectRenderComponent from 'components/SelectRenderComponent';
import CustomInput from 'components/customInput/CustomInput';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useUpdateCustomer } from 'react-query/invoice/customer/useUpdateCustomer';
import { useProjects } from 'react-query/projects/useProjects';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from 'utils/textTruncate';

function CreateCustomer({ handleClose, buttonText, item, index, details }) {
    const queryClient = useQueryClient();
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );
    const { isLoading: projectLoading, data: projectsData } =
        useProjects('Active');
    const query = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    // let a = projectsData?.projects?.filter((e, i) => {
    //     for (let i = 0; i < item?.projects.length; i++) {
    //         if (item?.projects[i]) {
    //             console.log(item.projects[i])
    //             return e?._id == item.projects[i]
    //         }
    //     }
    // })
    let selectedProjects = []
    projectsData?.projects.forEach(el => {
        if (item?.projects.includes(el._id)) {
            selectedProjects.push(el)
        }
    })
    let a = item?.projects.map((e) => {
        let b;
        if (e) {
            b = projectsData?.projects?.reduce((prev, cur) => {
                if (prev !== null) return prev;
                if (cur._id === e) return cur;
            }, {})

        }
    })

    const [customerDetail, setCustomerDetail] = useState({
        fullName: item?.fullName ?? '',
        email: item?.email ?? '',
        project: selectedProjects,
        address: item?.address ?? '',

    });
    // useEffect(() => {
    //     projectsData?.projects.forEach(el => {
    //         if (item?.projects.includes(el._id)) {
    //             selectedProjects.push(el)
    //         }
    //     })
    //     let a = item?.projects.map((e) => {
    //         let b;
    //         if (e) {
    //             b = projectsData?.projects?.reduce((prev, cur) => {
    //                 if (prev !== null) return prev;
    //                 if (cur._id === e) return cur;
    //             }, {})

    //         }
    //         console.log('b', b)
    //     })
    //     console.log('item?.projects', item?.projects)
    //     console.log('a', a)

    //     setCustomerDetail((prev) => {
    //         return { ...prev, project: selectedProjects }
    //     })
    // }, [customerDetail])

    const [error, setError] = useState({
        fullName: '',
        email: '',
        project: null,
        address: '',
    });

    const onValueChange = (event) => {

        const { name, value } = event?.target;
        setCustomerDetail({
            ...customerDetail,
            [name]: value,
        });

        error[name] !== '' &&
            setError({
                ...error,
                [name]: '',
            });
    };


    const onSubmit = () => {
        let previousCustomerList = queryClient.getQueryData([
            "customer",
            orgId,
        ]);
        let defaultProjectId = details?.projectId?.value?._id;
        let tempError = { ...error };
        let isError = false;
        const { fullName, email } = customerDetail;
        let projects = [];
        if (customerDetail?.project?.length) {
            projects = customerDetail?.project.map((el) => el?._id);
        }
        if (!fullName?.trim()?.length) {
            isError = true;
            tempError = {
                ...tempError,
                fullName: 'Full Name is required',
            };
        }

        if (!email.trim().length) {
            isError = true;
            tempError = {
                ...tempError,
                email: 'Email is required',
            };
        }
        let emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailReg.test(email) && email.trim().length) {
            isError = true;
            tempError = {
                ...tempError,
                email: 'Please enter a valid email',
            };
        }
        if (isError) {
            setError(tempError);
            return null;
        }
        setIsLoading(true);

        if (!!item) {
            updateCustomer({
                orgId,
                customerId: item?._id,
                data: { ...customerDetail, projects: [defaultProjectId] },
            }).then((res) => {

                let temp = [...previousCustomerList]
                temp.splice(index, 1, res?.customer)

                queryClient.setQueryData(
                    ["customer", orgId], temp);

                handleClose && handleClose();
            })

        } else {
            createCustomer({
                orgId,
                data: { ...customerDetail, projects: [defaultProjectId] },
            })
                .then((res) => {
                    try {
                        let customerList = query.getQueryData(['customer', orgId]);
                        customerList.unshift({
                            ...res,
                            ...customerDetail,
                        });
                        query.setQueryData(['customer', orgId], customerList);
                    } catch (err) { }
                    setIsLoading(false);
                    handleClose && handleClose();
                })
                .catch((err) => {
                    console.error(err);
                    setIsLoading(false);
                });
        }
    };

    return (
        <div>
            <CustomInput
                placeholder='Full Name'
                value={customerDetail?.fullName}
                error={error?.fullName}
                name='fullName'
                onChange={onValueChange}
            />
            <CustomInput
                placeholder='Email'
                wrapperClassName={'my-2'}
                value={customerDetail?.email}
                error={error?.email}
                name='email'
                onChange={onValueChange}
            />

            {/* <CustomSelect
                // errorText={detail?.}
                menuItems={projectsData?.projects ?? []}
                menuRenderComponent={<SelectRenderComponent />}
                selectRenderComponent={<SelectRenderComponent />}
                multiple
                value={customerDetail?.project ?? []}
                handleChange={(e) =>
                    onValueChange({
                        target: { value: e.target?.value, name: 'project' },
                    })
                }
                isLoading={projectLoading}
                variant={'outlined'}
                placeholder='Select a project'
                labelClassName={'normalFont'}
                containerClassName={'selectOutlined mb-2'}
            /> */}
            <CustomInput
                placeholder='Address'
                type='textarea'
                value={customerDetail?.address}
                error={error?.address}
                name='address'
                onChange={onValueChange}
            />
            <div className='d_flex justifyContent_end flex mt-2'>
                <CustomButton onClick={onSubmit} loading={isLoading}>
                    {!!item ? <p>{buttonText ?? 'Update'}</p>
                        : <p>{buttonText ?? 'Save'}</p>}
                </CustomButton>
            </div>
        </div>
    );
}

function SelectRender({ item, selected }) {
    return selected ? (
        <div className={`normalFont d_flex alignCenter textEllipse`}>
            <p className='textEllipse'>
                {' '}
                {capitalizeFirstLetter(item?.name ?? item?.title)}
            </p>
        </div>
    ) : (
        <div className={`normalFont d_flex alignCenter textEllipse`}>
            <p className='pl-1 textEllipse'>
                {' '}
                {capitalizeFirstLetter(item?.name ?? item?.title)}
            </p>
        </div>
    );
}

export default CreateCustomer;
