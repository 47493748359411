import { addNewClient, updateClient } from 'api/invoice'
import { validate } from 'pages/invoice/linkClients/utilitis/validation'
import React from 'react'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'




export const useAddUpdateClient = (projects, customer, handleCloseModal, handleIsLinkClient, selectedProject) => {
    console.log('handleIsLinkClient', handleIsLinkClient)
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );
    console.log('projectsNOW', projects)
    const queryClient = useQueryClient();

    let initial = {
        fullName: customer?.fullName ?? '',
        email: customer?.email ?? '',
        address: customer?.address ?? '',
        projects: customer?.projects ?? [],
        country: customer?.country ?? '',
        phoneNumber: customer?.phoneNumber ?? '',
    }

    const [formValue, setFormValue] = useState(initial)
    const [error, setError] = useState({})

    const handleChange = (value, key) => {
        setFormValue((prev) => {
            return { ...prev, [key]: value }
        })

        setError({ ...error, [key]: '' })
    }
    const onSubmit = () => {
        console.log('handleIsLinkClient', handleIsLinkClient)
        let tempValidate = validate(formValue)
        if (Object.keys(tempValidate).length === 0) {
            let data = {
                fullName: formValue?.fullName,
                email: formValue?.email,
                address: formValue?.address,
                projects: !!customer ? customer?.projects : [projects],
                country: formValue?.country?.label ?? customer?.country,
                phoneNumber: formValue?.phoneNumber,
            }

            if (!!customer) {
                updateClient(orgId, customer?._id, data).then((res) => {
                    queryClient.invalidateQueries(['AdditionalInfo', orgId, selectedProject?._id])
                    handleCloseModal && handleCloseModal()
                    !!handleIsLinkClient && handleIsLinkClient(false)
                })
            } else {
                addNewClient(orgId, data).then((res) => { handleIsLinkClient(false) })
            }
        } else setError(tempValidate)


    }

    return {
        handleChange,
        formValue,
        onSubmit,
        error
    }
}
