import React from 'react';
import ProjectList from './components/ProjectList/ProjectList';
import classes from './invoice.module.css';
import useInvoice from './useInvoice';
import Details from './components/DetailsArea/Details';
import CustomDatePickerModal from 'components/CustomModal/CustomDatePickerModal';
import CustomInput from 'components/customInput/CustomInput';
import { LinkClients } from './linkClients/LinkClients';
import Icon from 'components/icons/IosIcon';
import './projectListSearch.scss'

const Invoice = () => {
    const {
        onProjectChange,
        projectListData,
        projectListLoading,
        active,
        activeTab,
        onTabChange,
        paymentScheduleData,
        paymentScheduleLoading,
        onStatusChange,
        modalHandler,
        showModal,
        onDateChange,
        handleClose,
        showDateModal,
        filterData,
        handleFilterData,
        handleClearFilterData,
        handleSearchProject,
        searchProject,
        handleResetFilterData,
        setIsAddInfo,
        isAddInfo,
        isLinkClient,
        handleIsLinkClient

    } = useInvoice();
    return (
        <>

            <div className={classes.invoiceArea}
                // style={{ height: isAddInfo === 3 ? 'auto' : '110vh' }}
                style={{ height: '115vh' }}
            >
                <div style={{ marginBottom: 100 }}>
                    <div style={{ borderWidth: 2 }} className='projectListSearchDiv' >
                        <Icon name='search' style={{ position: 'absolute', left: 10 }} />
                        <input
                            className='projectListSearchInput'
                            placeholder='Search'
                            value={searchProject}
                            onChange={(e) => handleSearchProject(e.target.value)}
                        />
                        {/* <CustomInput
                            style={{ borderRadius: 20 }}
                            // placeholder='Search'
                            value={searchProject}
                            onChange={(e) => handleSearchProject(e.target.value)}
                        /> */}
                    </div>
                    <ProjectList
                        active={active}
                        projectListData={projectListData}
                        projectListLoading={projectListLoading}
                        onProjectChange={onProjectChange}
                        handleIsLinkClient={handleIsLinkClient}
                    />
                </div>
                {isLinkClient ?
                    <LinkClients
                        handleIsLinkClient={handleIsLinkClient}
                        project={active}
                    />
                    :
                    <Details
                        onStatusChange={onStatusChange}
                        paymentScheduleData={paymentScheduleData}
                        paymentScheduleLoading={paymentScheduleLoading}
                        active={active}
                        activeTab={activeTab}
                        onTabChange={onTabChange}
                        project={active}
                        modalHandler={modalHandler}
                        showModal={showModal}
                        filterData={filterData}
                        handleFilterData={handleFilterData}
                        handleClearFilterData={handleClearFilterData}
                        handleResetFilterData={handleResetFilterData}
                        setIsAddInfo={setIsAddInfo}
                        isAddInfo={isAddInfo}
                        handleIsLinkClient={handleIsLinkClient}

                    />}
                <CustomDatePickerModal
                    handleChange={onDateChange}
                    showModal={showDateModal}
                    handleClose={handleClose}
                />
            </div>
        </>
    );
};

export default Invoice;
