import CustomButton from 'components/CustomButton';
import React, { useState } from 'react';
import './linkClients.scss';
import BtnWrapper from 'components/btnWrapper/BtnWrapper';
import { TableLinkClient } from './compo/tableLinkClient/TableLinkClient';
import { NewClient } from './newClient/NewClient';
import { useSelector } from 'react-redux';
import { useCustomerList } from 'react-query/invoice/customer/useCustomerList';
import Icon from 'components/icons/IosIcon';
import { useLinkClient } from './useLinkClient';
import { CircularProgress } from '@mui/material';

export const LinkClients = ({ handleIsLinkClient, project }) => {
    const [linkClientTab, setLinkClientTab] = useState(1);
    const { data, isLoading, isError } = useCustomerList();
    const { handleAddOldClientToProject, handleAddNewClient } = useLinkClient({ handleIsLinkClient, project });
    return (
        <div className='linkClientsBox'>
            <div className='headingLinkClient'>
                <p className='textHead'>Link Clients</p>
                <p style={{ cursor: 'pointer' }} onClick={() => handleIsLinkClient(false)}>
                    {' '}
                    <Icon name='back' style={{ width: 40, height: 40 }} />
                </p>
            </div>
            <div style={{ display: 'inline-block' }}>
                <BtnWrapper>
                    <p></p>
                    <CustomButton
                        onClick={() => setLinkClientTab(1)}
                        // key={i}
                        type={linkClientTab === 1 ? 'contained' : 'text'}
                    >
                        {'Previous Clients'}
                    </CustomButton>
                    <CustomButton
                        onClick={() => setLinkClientTab(2)}
                        // key={i}
                        type={linkClientTab === 2 ? 'contained' : 'text'}
                    >
                        {'New Clients'}
                    </CustomButton>
                </BtnWrapper>
            </div>

            {linkClientTab === 1 && (
                <>
                    <TableLinkClient heading={true} />
                    {isLoading ? (
                        <div>
                            <CircularProgress />
                        </div>
                    ) : (
                        data?.map((item, i, arr) => {
                            return (
                                <TableLinkClient
                                    item={item}
                                    isLast={i === arr.length - 1}
                                    onClick={() => handleAddNewClient(item)}
                                />
                            );
                        })
                    )}
                </>
            )}
            {linkClientTab === 2 && <NewClient projectId={project?._id} handleIsLinkClient={handleIsLinkClient} />}
        </div>
    );
};
