import prePad from './prePad';

const generateInvoiceNumber = (prevNo) => {
    let currYear = new Date().getFullYear() % 100;
    const currMonth = new Date().getMonth() + 1;
    if (!prevNo) {
        if (currMonth < 4) {
            return `PT/GST/${currYear - 1}-${currYear}/001`;
        } else {
            return `PT/GST/${currYear}-${currYear + 1}/001`;
        }
    }

    const [x, y, yearArr, numArr] = prevNo.split('/');
    let prevYearLast = yearArr.split('-')[0] * 1;
    let prevYearNext = yearArr.split('-')[1] * 1;

    let currNo;

    if (currYear === prevYearNext) {
        if (currMonth < 4) {
            currNo = `${x}/${y}/${prevYearLast}-${prevYearNext}/${prePad(numArr)}`;
        } else {
            currNo = `${x}/${y}/${prevYearLast + 1}-${prevYearNext + 1}/001`;
        }
    } else if (currYear > prevYearNext) {
        if (currMonth < 4) {
            currNo = `${x}/${y}/${prevYearLast}-${prevYearNext}/${prePad(numArr)}`;
        } else {
            currNo = `${x}/${y}/${prevYearLast}-${prevYearNext}/001`;
        }
    } else if (currYear < prevYearNext) {
        currNo = `${x}/${y}/${prevYearLast}-${prevYearNext}/${prePad(numArr)}`;
    }

    return currNo;
};

export default generateInvoiceNumber;
