import { getInvoices, getPaymentSchedule } from 'api/paymentSchedule';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const apiData = [
    { api: getPaymentSchedule, key: 'payment-schedules' },
    { api: getInvoices, key: 'invoice-new' },
];

export const useInvoiceQuery = (activeTab, projectId, status, month, year, createdAt, subsiduary, enable = false) => {
    const orgId = useSelector((state) => state.userReducer?.selectedOrganisation?._id);

    const dep = [
        apiData[activeTab - 1].key,
        orgId,
        projectId ?? 'all',
        status ?? 'all',
        month ?? 'all',
        year ?? 'all',
        createdAt ?? 'all',
        subsiduary ?? 'all',
    ];

    const { isLoading, data, isError, refetch } = useQuery(
        dep,
        () =>
            apiData[activeTab - 1].api(orgId, {
                projectId,
                status,
                month,
                year,
                createdAt,
                subsiduary,
            }),
        {
            enabled: !!orgId,
            retry: 0,
            refetchOnMount: enable,
            onSuccess: () => {
                console.log('dep', dep);
            },
        }
    );
    return { isLoading, data, isError, refetch };
};
