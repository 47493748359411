import CustomButton from 'components/CustomButton';
import JoditEditor from 'jodit-react';
import React, { useState } from 'react';
import classes from './CustomTextEditor.module.css';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { LightTooltip } from 'components/tooltip/LightTooltip';

const CustomTextEditor = React.forwardRef(
    ({ disable, value = '', theme, updateData, isEOD = 'no', onChange, className }, ref) => {
        const [showEditor, setShowEditor] = useState(false);
        const [showEditor2, setShowEditor2] = useState(false);
        const config = {
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            statusbar: false,
            autofocus: true,
            toolbarInline: false,
            // preset: !show ? "inline" : "",
            showTooltipDelay: 100,
            tabIndex: -1,

            // removeButtons: [
            //   "superscript",
            //   "subscript",
            //   "source",
            //   "image",
            //   "video",
            //   "file",
            //   "copyformat",
            //   "table",
            //   "symbol",
            //   "fullsize",
            //   "preview",
            //   "print",
            //   "about",
            // ],
            buttons:
                'bold,italic,underline,strikethrough,superscript,subscript,ul,ol,indent,outdent,left,fontsize,paragraph,brush,link',
            buttonsMD:
                'bold,italic,underline,strikethrough,superscript,subscript,ul,ol,indent,outdent,left,fontsize,paragraph,brush,link',
            buttonsSM:
                'bold,italic,underline,strikethrough,superscript,subscript,ul,ol,indent,outdent,left,fontsize,paragraph,brush,link',
            buttonsXS:
                'bold,italic,underline,strikethrough,superscript,subscript,ul,ol,indent,outdent,left,fontsize,paragraph,brush,link',
            // askBeforePasteFromWord: false,
            // askBeforePasteHTML: false,
            // events: {
            //   processPaste: function (event, html) {
            //     jodit_editor.selection.insertHTML(html);
            //     jodit_editor.tempContent = jodit_editor.getEditorValue();
            //   },
            //   afterPaste: function (event) {
            //     let el = $("<div></div>");
            //     el.html(
            //       jodit_editor.tempContent
            //         ? jodit_editor.tempContent
            //         : jodit_editor.getEditorValue()
            //     );
            //     jodit_editor.setEditorValue(el.html());
            //     jodit_editor.tempContent = null;
            //   },
            // },
            askBeforePasteFromWord: false,
            askBeforePasteHTML: false,
            defaultActionOnPaste: 'insert_only_text',
            minHeight: 150,
            maxHeight: 200,
            uploader: {
                url: 'none',
            },
        };

        const config2 = {
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
            statusbar: false,
            autofocus: false,
            toolbarInline: false,
            removeButtons: [
                'superscript',
                'subscript',
                'source',
                'image',
                'video',
                'file',
                'copyformat',
                'table',
                'symbol',
                'fullsize',
                'preview',
                'print',
                'about',
                'rotate',
            ],
        };
        return (
            <>
                {isEOD === 'no' && (
                    <div>
                        {!showEditor && (
                            <div className='alignCenter justifyContent_end mb-05'>
                                {!disable && (
                                    <div className='cursorPointer' onClick={() => !disable && setShowEditor(true)}>
                                        <LightTooltip title='Edit' arrow>
                                            <EditRoundedIcon />
                                        </LightTooltip>
                                    </div>
                                )}
                            </div>
                        )}
                        {showEditor ? (
                            <div>
                                <JoditEditor
                                    ref={ref}
                                    value={value}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    theme={theme ?? 'dark'}
                                    onBlur={(e, b, c, d) => {
                                        // setEditorData(e);
                                        // updateData(ref);
                                    }}
                                    onChange={(e) => {
                                        // setEditorData(e);
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CustomButton
                                        style={{ margin: 10 }}
                                        onClick={() => {
                                            console.log(ref);
                                            // ref.current;
                                            setShowEditor(false);
                                        }}
                                    >
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        onClick={() => {
                                            setShowEditor(false);
                                            updateData && updateData(ref?.current?.value);
                                            console.log('--->', ref?.current?.value);
                                            // if (ref?.current?.value.length < 1) ref.current.value = "Add";
                                        }}
                                    >
                                        Update
                                    </CustomButton>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={classes.j_editor}
                                onClick={() => {
                                    // !disable && setShowEditor(true);
                                }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        value === '' || value === undefined
                                            ? !disable
                                                ? '<p>Click edit icon to add description</p>'
                                                : '<p>No description available</p>'
                                            : value?.split('<a').join('<a target="_blank"'),
                                }}
                            />
                        )}
                    </div>
                )}
                {isEOD === 'yes' && (
                    <div>
                        {!showEditor2 && (
                            <div className='alignCenter justifyContent_end mb-05'>
                                {!disable && (
                                    <div
                                        className='cursorPointer'
                                        onClick={() => !disable && setShowEditor2(true)}
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            background: '#363D68',
                                            padding: 10,
                                        }}
                                    >
                                        <p style={{ color: 'gray' }}>Click edit icon to add description</p>
                                        <LightTooltip title='Description' arrow>
                                            <EditRoundedIcon />
                                        </LightTooltip>
                                    </div>
                                )}
                            </div>
                        )}
                        {showEditor2 ? (
                            <div style={{ position: 'relative', color: '#000' }} className={className}>
                                <JoditEditor
                                    ref={ref}
                                    autofocus={false}
                                    value={value}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    theme={theme ?? 'dark'}
                                    onBlur={(e, b, c, d) => {
                                        // setEditorData(e);
                                        // updateData(ref);
                                    }}
                                    onChange={(e) => {
                                        // setEditorData(e);
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        right: 10,
                                        bottom: 10,
                                    }}
                                >
                                    {/* <CustomButton
                                    style={{ margin: 10 }}
                                    onClick={() => {
                                        console.log(ref);
                                        // ref.current;
                                        setShowEditor(false);
                                    }}
                                >
                                    Cancel
                                </CustomButton> */}
                                    <CustomButton
                                        onClick={() => {
                                            setShowEditor2(false);
                                            updateData && updateData(ref?.current?.value);
                                            console.log('--->', ref?.current?.value);
                                            // if (ref?.current?.value.length < 1) ref.current.value = "Add";
                                        }}
                                    >
                                        Save Description
                                    </CustomButton>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={classes.j_editor}
                                onClick={() => {
                                    // !disable && setShowEditor(true);
                                }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        value === '' || value === undefined
                                            ? !disable
                                                ? ''
                                                : '<p>No description available</p>'
                                            : value?.split('<a').join('<a target="_blank"'),
                                }}
                            />
                        )}
                    </div>
                )}
            </>
        );
    }
);

export default CustomTextEditor;
