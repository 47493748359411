import { axiosInstance } from 'api/axiosInstance';

export class Wincy {
    data = {};
    errorHandler = [];
    successHandler = [];
    finallyHandler = [];

    static getInstance() {
        if (this.instance == null) {
            this.instance = new Wincy();
        }

        return this.instance;
    }

    onSuccess(func) {
        this.successHandler.push(func);
    }

    onError(func) {
        this.errorHandler.push(func);
    }

    onFinally(func) {
        this.finallyHandler.push(func);
    }

    requestType = async ({ endPoint, requestType, data, params }) => {
        switch (requestType) {
            case 'GET':
                return await axiosInstance?.get(endPoint, { params });

            case 'POST':
                return await axiosInstance?.post(endPoint, data, { params });

            default:
                break;
        }
    };

    getData = async ({
        cacheKey,
        endPoint,
        data,
        params,
        requestType = 'GET',
    }) => {
        try {
            const res = await this.requestType({
                endPoint,
                requestType,
                data,
                params,
            });
            if (cacheKey) {
                this.data[cacheKey] = res.data;
            }
            this.successHandler?.length &&
                this.successHandler.forEach((el) => el(res.data));
            return res.data;
        } catch (error) {
            console.error('error in wincy', error);
            this.errorHandler?.length &&
                this.errorHandler.forEach((el) => el(error));
        } finally {
            console.log('first');
            this.finallyHandler?.length &&
                this.finallyHandler.forEach((el) => el());
        }
    };
}
