import Popper from '@mui/material/Popper';
import * as React from 'react';

export default function MyPopOver({ children, box, anchorEl, handleClick }) {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            {React.cloneElement(children, {
                'aria-describedby': id,
                onClick: handleClick,
            })}
            <Popper
                placement='bottom-start'
                id={id}
                open={open}
                anchorEl={anchorEl}
                p
            >
                {box}
            </Popper>
        </div>
    );
}
