import React from "react";
import "./EODTable.scss";
import TableRow from "../eodTableRow/TableRow";
import { useSelector } from "react-redux";
import moment from "moment";

export default function EODTable({
  form,
  item,
  index,
  onClickOpenEod,
  onClickItem,
  date,
}) {
  // const { _id, eodDetail } = item;
  const user = useSelector((state) => state.userReducer?.userData?.user);
  // console.log({ item });
  // let currentDate = new Date();

  let isCurrentDate = date === moment().format("DD-MMM-YYYY");

  return (
    <div className="tableMainDiv">
      <div className="dateTableHeader">
        <p className="dateBG">
          {/* {`${Month[_id?.month]} ${_id?.day},${_id?.year}`} */}
          {date}
        </p>
      </div>

      <div className="sideTopBottomTableBoarder">
        <TableRow styleDescription={{ textAlign: "center" }} isHeader={true} />
        {/* {index === 0 && <TableRow isForm={true} onClickOpenEod={onClickOpenEod} />} */}
        {item?.map((eodItem, i) => {
          let isUser = eodItem?.user_detail?._id === user?._id;
          return (
            <>
              <TableRow
                key={i}
                isTable={true}
                eodItem={eodItem}
                onClickItem={() =>
                  isUser && isCurrentDate ? onClickItem(eodItem) : undefined
                }
                isUser={isUser && isCurrentDate}
              />
            </>
          );
        })}
      </div>
    </div>
  );
}

let Month = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
