import React from 'react'
import './newClient.scss'
import CustomInput from 'components/customInput/CustomInput'
import { CountryPicker } from '../compo/countryPiker/CountryPicker'
import CustomButton from 'components/CustomButton'
import { useAddUpdateClient } from 'pages/invoice/components/DetailsArea/ListItem/additionalInfo/component/editInfo/functionality/useUpdateClient'

export const NewClient = ({ projectId, handleIsLinkClient }) => {
    const { handleChange, onSubmit, error } = useAddUpdateClient(projectId, '', '', handleIsLinkClient)//projects
    return (
        <div className='newClientDiv'>
            <div className='row1'>
                {InputLabel('Client Name',
                    (e) => handleChange(e.target.value, 'fullName'), error.fullName)}
                <div className='inputWithLevel'>
                    <p className='text'>Country</p>
                    <CountryPicker
                        onChange={(e, value) => handleChange(value, 'country')}
                    />
                    {error.country && <p style={{ color: 'red' }}>{error.country}</p>}
                </div>
            </div>
            <div className='row1'>
                {InputLabel('Email Address', (e) => handleChange(e.target.value, 'email'), error.email)}
                {InputLabel('Contact Number', (e) => handleChange(e.target.value, 'phoneNumber'), error.phoneNumber)}
            </div>

            <p className='text'>Address</p>
            <textarea
                style={{
                    width: "99%",
                    outline: 0,
                    backgroundColor: 'rgba(45, 52, 99, 1)',
                    padding: 15,
                    color: "#fff",
                    fontSize: 14,
                    border: "1px solid #363d72",
                    borderRadius: 6,
                    marginRight: 20,
                }}
                rows={4}
                cols={50}
                placeholder="Address"
                // value={leadDetails?.description}
                onChange={(e) => handleChange(e.target.value, 'address')}

                name="address"
                className="addLeadTextArea"
            />
            <p style={{ color: 'red' }}>{error.address}</p>

            <div className='btnDiv'>
                <CustomButton
                    onClick={onSubmit}
                >Save</CustomButton>
            </div>

        </div>
    )
}


let InputLabel = (label, onChange, error) => {
    return <div className='inputWithLevel'>
        <p className='text'>{label}</p>
        <CustomInput
            onChange={onChange}
            // placeholder={label}
            style={{
                backgroundColor: 'rgba(45, 52, 99, 1)',
            }}

        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
}
