import MyButton from 'components/MyButton/MyButton';
import MenuBox from 'components/MyPopOver/MenuBox';
import MyPopOver from 'components/MyPopOver/MyPopOver';
import moment from 'moment';
import { paymentScheduleStatus } from 'pages/invoice/data';
import { useState } from 'react';
import './listItem.scss';
import Icon from 'components/icons/IosIcon';
import avatar from '../../../../../assets/images/avatar.png';
import { Dialog } from '@material-ui/core';
import { InvoiceIdPdfViewer } from './invoiceIdPdfViewer/InvoiceIdPdfViewer';

const status = {
    INVOICED: 'Invoiced',
    PENDING: 'Pending',
    PARTIALLY_INVOICED: 'Partially...',
    PAID: 'Paid',
};

const PSItem = ({ style, index, item, onStatusChange, onItemPress, isLast, heading, isShowPN }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    //pdf
    const [isShowPdf, setIsShowPdf] = useState({
        status: false,
        invoiceId: null,
    });

    const handleCloseOpenPdfModal = ({ status, invoiceId }) => {
        setIsShowPdf((prev) => {
            let temp = { ...prev, status, invoiceId };
            return temp;
        });
    };
    const handleClick = (e) => {
        if (e?.key) {
            onStatusChange({ ...e, index, type: 'payment-schedule', item });
            setAnchorEl(null);
        } else {
            setAnchorEl(anchorEl ? null : e?.currentTarget);
        }
    };

    // let invoiceAmount = 0;
    // item?.invoice?.forEach((el) => {
    //     invoiceAmount += Number(el.amount);
    // });
    console.log('isShowPN', isShowPN)
    console.log('proname', item?.projectId?.title)
    let invoiceAmount = Number(item?.amount) - item?.invoice.reduce((prev, cur) => prev + Number(cur.amount), 0);
    return (
        <>
            {heading && (
                <div className='header'>
                    <div className='dfs ml'>
                        <p>Title</p>
                    </div>
                    <div className='dfc'>
                        <p>Created By</p>
                    </div>
                    <div className='dfc'>
                        <p>Due Date</p>
                    </div>
                    <div className='dfc'>
                        <p>Amount</p>
                    </div>
                    <div className='dfc'>
                        <p>Invoiced Id</p>
                    </div>
                    <div className='dfc'>
                        <p>Status</p>
                    </div>
                </div>
            )}
            {!heading && (
                <div className='box' style={{ borderBottom: !isLast && 'none' }}>
                    <div className='box2'>
                        <div>
                            <p>{item?.name}
                                {isShowPN && <span style={{ marginLeft: 5 }}>{`(${item?.projectId?.title})`}</span>}
                            </p>
                            {item?.isRestricted && <p className='textRed'>{`(Restricted)`}</p>}
                        </div>
                        <span style={{ cursor: 'pointer' }} onClick={onItemPress.bind(this, item)}>
                            <Icon name='info' style={{ width: 15, height: 15 }} />
                        </span>
                    </div>
                    <div className='dfc' style={{ alignSelf: 'center' }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={item?.createdBy[0]?.profilePicture ?? avatar}
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                            />
                        </div>
                    </div>
                    <div className='dfc'>
                        <p> {item?.dueDate ? moment(item?.dueDate).format('DD-MM-yyyy') : '---'}</p>
                    </div>
                    <div className='dfc fdc'>
                        <p>${item?.amount}</p>
                        {invoiceAmount > 0 && invoiceAmount < item?.amount && (
                            <p className='textRed'>{`(Remaining ${invoiceAmount})`}</p>
                        )}
                    </div>
                    <div className='dfc fdc'>
                        {!item?.invoice.length ? (
                            <p>---</p>
                        ) : (
                            item?.invoice?.map((el) => {
                                return (
                                    <p
                                        className='textBlue'
                                        onClick={() =>
                                            handleCloseOpenPdfModal({
                                                status: true,
                                                invoiceId: el?._id,
                                            })
                                        }
                                    >
                                        {el?.invoiceNumber}
                                    </p>
                                );
                            })
                        )}
                    </div>
                    <div className='dfc' sss>
                        <MyPopOver
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                            box={<MenuBox handleClick={handleClick} list={paymentScheduleStatus} />}
                        >
                            <MyButton className='button'>{status[item?.status]}</MyButton>
                        </MyPopOver>
                    </div>
                </div>
            )}
            <InvoiceIdPdfViewer
                isShowPdf={isShowPdf.status}
                handleCloseOpenPdfModal={handleCloseOpenPdfModal}
                invoiceId={isShowPdf?.invoiceId}
            />
            {/* <Dialog
                open={isShowPdf}
            >
                <div onClick={() => handleCloseOpenPdfModal(false)} className='pdfViewer'>
                    <Icon name='redCancel' />
                </div>
                <PDFViewer height={500} width={500} >
                    <DownloadInvoiceItem item={item} />
                </PDFViewer>
            </Dialog> */}

            {/* <div style={style}>
                <div className='row'>
                    <div className='details' onClick={onItemPress.bind(this, item)}>
                        <p className='text'>{item?.name}</p>
                        <p className='text' >{item?.projectId?.title}</p>
                        <p className='text' >
                            {item?.dueDate
                                ? moment(item?.dueDate).format('DD-MM-yyyy')
                                : '---'}
                        </p>

                        <p className='text'>{item?.amount}</p>
                        <p className='text'>{invoiceAmount}</p>

                    </div>
                    <MyPopOver
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                        box={
                            <MenuBox
                                handleClick={handleClick}
                                list={paymentScheduleStatus}
                            />
                        }
                    >
                        <MyButton className='button'>{status[item?.status]}</MyButton>

                    </MyPopOver>
                </div>
            </div> */}
        </>
    );
};

export default PSItem;
