import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useInvoiceMutateQuery } from 'react-query/payment-schedule/useInvoiceMutateQuery';
import { useInvoiceQuery } from 'react-query/payment-schedule/useInvoiceQuery';
import { useInvoiceProjects } from 'react-query/projects/useInvoiceProjects';
import { useProjects } from 'react-query/projects/useProjects';

const useInvoice = () => {
    const [active, setActive] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [currRowData, setCurrRowData] = useState(null);
    const [showDateModal, setShowDateModal] = useState(false);
    const [searchProject, setSearchProject] = useState('');
    const [isLinkClient, setIsLinkClient] = useState(false);
    //
    const [openFilter, setOpenFilter] = useState(false);
    const [filterDataPP, setFilterDataPP] = useState({
        'Sub Company': 'wincy',
        status: 'unpaid',
        'Sort By': 'Ascending',
    });

    const [isAddInfo, setIsAddInfo] = useState(1); //esa is liye kiya
    //additional info tab pr click krne se bhut sare errors aa rhe the activeTab ki wjh se kyuki is active tab pr bhut sari conditions lgi hui h

    let initialData = [
        {
            // SubCompany: '629f2d0ec256f90016ad2952',
            // status: "PENDING",
            MonthBy: moment().month() + 1,
            FinancialYear: moment().year().toString(),
            createdAt: -1,
        },
        {
            // SubCompany: '629f2d0ec256f90016ad2952',
            // status: "UNPAID",
            MonthBy: moment().month() + 1,
            FinancialYear: moment().year().toString(),
            createdAt: -1,
        },
    ];

    const [filterData, setFilterData] = useState(initialData);
    const executeOtherAction = (action, value) => {
        switch (action) {
            case 'addYear':
                handleFilterData(filterData[activeTab - 1].FinancialYear ?? '2023', 'FinancialYear');
                break;
            case 'monthYear':
                let temp = {
                    MonthBy: value[0],
                    FinancialYear: value[1],
                };
                setFilterData((prev) => {
                    let tempPrev = prev;
                    tempPrev[0] = { ...tempPrev[0], ...temp };
                    tempPrev[1] = { ...tempPrev[1], ...temp };
                    return [...tempPrev];
                });
                // handleFilterData(
                //     setFilterData((prev) => {
                //         const temp = prev;
                //         temp[activeTab - 1] = { ...temp[activeTab - 1], FinancialYear: value[1], MonthBy: value[3] };
                //     })
                // );
                break;
            default:
                break;
        }
    };
    const handleFilterData = (value, key, otherAction) => {
        if (otherAction) {
            executeOtherAction(otherAction, value);
        } else {
            setFilterData((prev) => {
                const temp = prev;
                temp[activeTab - 1] = { ...temp[activeTab - 1], [key]: value };
                return [...temp];
            });
        }
        // setFilterDataPP({ ...filterDataPP, [title]: elm });
    };

    let handleSearchProject = (value) => {
        let temp = value.replace(/  +/g, ' ');
        setSearchProject(temp);
        // setActive(null)
    };
    //
    const handleClearFilterData = (activeTabs) => {
        setFilterData((prev) => {
            const temp = prev;

            temp[activeTabs - 1] = {
                // SubCompany: '',
                // status: "UNPAID",
                // MonthBy: '',
                // FinancialYear: '2023',
                // sort: 'Ascending'
            };

            return [...temp];
        });
    };

    const handleResetFilterData = (activeTabs) => {
        setFilterData(() => {
            const temp = initialData;
            temp[activeTabs - 1] = initialData[activeTabs - 1];

            return [...temp];
        });
    };
    //

    const handleClose = () => {
        setShowDateModal(null);
    };

    const onSuccess = () => {
        setShowDateModal(null);
        setShowDateModal(false);
    };

    const onStatusChange = (e) => {
        setCurrRowData({ ...e.item, statusToChange: e.key });
        if (e.key === 'INVOICED' || e.key === 'PAID') {
            setShowDateModal(true);
        } else {
            changeStatus(e.key, e.item);
        }
    };

    const { isLoading, data: projectsData } = useInvoiceProjects('specific');
    const projectId = active?._id; //
    const month = filterData[activeTab - 1]?.MonthBy;
    const year = filterData[activeTab - 1]?.FinancialYear;
    const status = filterData[activeTab - 1]?.status;
    const createdAt = filterData[activeTab - 1]?.createdAt;
    const subsiduary = filterData[activeTab - 1]?.subsiduary;
    // const month = 5;
    // const year = '2023';
    // const status = 'PAID';


    const { data, isLoading: paymentScheduleLoading } = useInvoiceQuery(
        activeTab,
        projectId,
        status,
        month,
        year,
        createdAt,
        subsiduary
    );

    const { isLoading: statusLoading, mutate } = useInvoiceMutateQuery(
        activeTab + 2,
        onSuccess,
        undefined,
        projectId,
        status,
        month,
        year,
        createdAt,
        subsiduary
    );
    const projectListData = projectsData?.projects?.filter((e) =>
        e.title.toLowerCase().includes(searchProject.toLowerCase())
    );

    const onProjectChange = (el) => {
        // setActive((prev) => {
        //     if (el?._id === prev?._id) {
        //         return null;
        //     } else return el;
        // });
        setActive(el);
        if (el.title === 'All projects') {
            setIsAddInfo(1);
        }
    };

    const onTabChange = (num, i) => {
        let temp = num;
        setActiveTab(temp);
        setIsAddInfo(temp);
    };

    const modalHandler = (state) => {
        setShowModal(state);
    };

    const onDateChange = (date) => {
        changeStatus(currRowData?.statusToChange, currRowData, date);
        handleClose();
    };

    const changeStatus = (status, item, paidAt) => {
        if (activeTab === 1) {
            const dataToSend = {
                paymentScheduleId: item?._id,
                status,
                projectId: item?.projectId?._id,
                ...(paidAt && { paidAt }),
            };
            mutate(dataToSend);
        } else {
            const dataToSend = {
                invoiceId: item?._id,
                paymentSchedule: item?.paymentSchedule,
                status,
                projectId: item?.projectId?._id,
                ...(paidAt && { paidAt }),
            };
            mutate(dataToSend);
        }
    };

    const paymentScheduleData = data?.data;

    const handleIsLinkClient = (value) => {
        setIsLinkClient(value);
    };

    return {
        onProjectChange,
        projectListLoading: isLoading,
        projectListData,
        active,
        onTabChange,
        activeTab,
        paymentScheduleData,
        paymentScheduleLoading,
        onStatusChange,
        modalHandler,
        showModal,
        onDateChange,
        showDateModal,
        handleClose,
        openFilter,
        setOpenFilter,
        filterDataPP,
        setFilterDataPP,
        handleFilterData,
        filterData,
        statusLoading,
        handleClearFilterData,
        handleSearchProject,
        searchProject,
        handleResetFilterData,
        //
        isAddInfo,
        setIsAddInfo,
        handleIsLinkClient,
        isLinkClient,
    };
};

export default useInvoice;
