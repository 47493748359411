import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import { acceptOrRejectSchedule, getAllSchedule, getAuthSchedule, getFilteredSchedule } from 'api/scheduledApi';
import { useOrgTeam } from 'react-query/organisations/useOrgTeam';


function useScheduled() {
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );
    const userType = useSelector(
        (state) => state.userReducer?.userType?.userType
    );
    const user = useSelector(
        (state) => state.userReducer?.userData?.user
    );

    const queryClient = useQueryClient()

    const [drawerOpen, setDrawerOpen] = useState({
        type: 'filter', isOpen: false
    });

    // const [scheduleStatus, setScheduleStatus] = useState('');
    const [filterData, setFilterData] = useState({});
    const type = userType === 'Admin' ? 'schedule-filter' : 'user-schedule-filter';
    //
    // getFilteredSchedule
    let listData = useQuery(['getFilteredSchedule', type, filterData], () => getFilteredSchedule({ type, filterData }), {
        refetchOnWindowFocus: false,
        enabled: true
    })
    // console.log('listData', listData)
    //

    const handleDrawer = (type) => {
        setDrawerOpen({ ...drawerOpen, type, isOpen: !drawerOpen.isOpen });
    };

    const handleAcceptReject = (v, id, i) => {
        console.log('v', v, 'id', id, 'i', i)
        acceptOrRejectSchedule(orgId, { status: v }, id).then(() => {
            let temp = queryClient.getQueryData(['getFilteredSchedule', type, filterData])
            let temp1 = [...temp]
            temp1.splice(i, 1, { ...temp[i], status: v, authorized_by: [{ ...user }] })
            console.log('temp', temp1)
            queryClient.setQueryData(['getFilteredSchedule', type, filterData], temp1)
        })
    }
    //
    const { isLoading, data } = useOrgTeam(orgId);

    let handleOnchange = (key, value) => {
        setFilterData((prev) => {
            let temp = { ...prev }
            temp[key] = value
            console.log('onChengePrev', temp)
            return temp
        })
    }
    const handleClearFilter = () => {
        setFilterData({})
    }


    let handleFilter = () => {

    }
    // console.log('temp', obj)

    return {
        drawer: { handleDrawer, drawerOpen },
        listData,
        handleAcceptReject,
        handleOnchange,
        handleFilter,
        handleClearFilter,
        filterData,
    }
}

export default useScheduled