import { getCustomerList } from "api/invoice";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

export const useCustomerList = () => {
  const orgId = useSelector(
    (state) => state.userReducer?.selectedOrganisation?._id
  );
  return useQuery(["customer", orgId], () => getCustomerList({ orgId }), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
};
