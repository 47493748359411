import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, CircularProgress, IconButton } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import MyList from 'components/MyList/MyList';
import NoData from 'components/NoData';
import { YearMonthPicker } from '../yearMonthPicker/YearMonthPicker';
import DetailsButton from './DetailsButton/DetailsButton';
import DetailsHeader from './Header/DetailsHeader';
import InvoiceItem from './ListItem/InvoiceItem';
import PSItem from './ListItem/PSItem';
import AdditionalInfo from './ListItem/additionalInfo/AdditionalInfo';
import InvoiceModal from './Modal/InvoiceModal';
import InvoiceModalUpdate from './Modal/InvoiceModalUpdate';
import PSModal from './Modal/PSModal';
import PSModalUpdate from './Modal/PSModalUpdate';
import './details.scss';
import useDetails from './useDetails';
import Icon from 'components/icons/IosIcon';
//

const Details = ({
    project,
    activeTab,
    onTabChange,
    active,
    paymentScheduleLoading,
    paymentScheduleData,
    onStatusChange,
    showModal,
    modalHandler,
    filterData,
    handleFilterData,
    handleClearFilterData,
    handleResetFilterData,
    isAddInfo,
    setIsAddInfo,
    handleIsLinkClient,
}) => {
    const {
        onItemPress,
        modalData,
        active: activeUpdate,
        modalHandler: modalHandlerUpdate,
    } = useDetails({ activeTab });

    const modals = [
        <PSModal project={project} activeTab={activeTab} modalHandler={modalHandler} filterData={filterData} />,
        <InvoiceModal project={project} activeTab={activeTab} modalHandler={modalHandler} filterData={filterData} />,
        <PSModalUpdate
            project={project}
            modalData={modalData.PS}
            active={activeUpdate}
            onSubmit={modalHandlerUpdate}
            filterData={filterData}
            activeTab={activeTab}
            selectedProject={active}
        />,
        <InvoiceModalUpdate
            project={project}
            modalData={modalData.INVOICE}
            active={activeUpdate}
            onSubmit={modalHandlerUpdate}
            filterData={filterData}
            activeTab={activeTab}
            selectedProject={active}
        />,
    ];
    const renderItem = (data) => {
        switch (activeTab) {
            case 1:
                return (
                    <div>
                        <PSItem {...data}
                            onItemPress={onItemPress}
                            onStatusChange={onStatusChange}
                            isShowPN={project?.title === 'All projects'}

                        />
                    </div>
                );

            case 2:
                return <InvoiceItem
                    onStatusChange={onStatusChange}
                    onItemPress={onItemPress} {...data}
                    isShowPN={project?.title === 'All projects'}
                />;

            default:
                break;
        }
    };
    let status = filterData[activeTab - 1]?.status;
    return (
        <div
            style={{
                flex: 1,
                overflow: 'auto',
            }}
        >
            <DetailsHeader
                project={project}
                active={active}
                handleIsLinkClient={handleIsLinkClient}
                handleFilterData={handleFilterData}
                activeTab={activeTab}
                filterData={filterData}
                //
                paymentScheduleData={paymentScheduleData}
            />
            <DetailsButton
                activeTab={activeTab}
                onTabChange={onTabChange}
                modalHandler={modalHandler}
                filterData={filterData}
                handleFilterData={handleFilterData}
                handleClearFilterData={handleClearFilterData}
                handleResetFilterData={handleResetFilterData}
                setIsAddInfo={setIsAddInfo}
                isAddInfo={isAddInfo}
                isShowBtn={!project?.totalProject}
            />

            <div className='filter'>
                {project?.title !== 'All projects' && isAddInfo < 3 && (
                    <>
                        {activeTab - 1 === 0 && (
                            <YearMonthPicker
                                filterData={filterData[activeTab - 1]}
                                handleFilterData={handleFilterData}
                            />
                        )}
                        {activeTab - 1 === 1 && (
                            <YearMonthPicker
                                filterData={filterData[activeTab - 1]}
                                handleFilterData={handleFilterData}
                            />
                        )}
                    </>
                )}

                <div className='status'>
                    {isAddInfo < 3 &&
                        arrayOfStatus[activeTab - 1]?.map((el) => {
                            return (
                                <div
                                    className='radioDiv'
                                    onClick={() => handleFilterData(el.value === status ? '' : el.value, 'status')}
                                >
                                    <Checkbox
                                        label='RadioButtonCheckedIcon'
                                        icon={<RadioButtonUncheckedIcon htmlColor='rgba(255, 255, 255, 0.8)' />}
                                        checkedIcon={<RadioButtonCheckedIcon htmlColor='var(--primary)' />}
                                        checked={status === el.value}
                                    />
                                    <p className={status === el.value ? 'selected' : null}>{el?.title}</p>
                                </div>
                            );
                        })}
                </div>
            </div>

            {isAddInfo === 1 && <PSItem heading={true} />}
            {isAddInfo === 2 && <InvoiceItem heading={true} />}

            {(isAddInfo === 1 || isAddInfo === 2) &&
                (paymentScheduleLoading ? (
                    <div className='loading'>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className='listArea' style={{ height: '50vh' }}>
                        {paymentScheduleData?.length > 0 ? (
                            <MyList
                                data={paymentScheduleData}
                                renderItem={renderItem}
                                length={paymentScheduleData.length}
                            />
                        ) : (
                            <NoData />
                        )}
                    </div>
                ))}
            {isAddInfo === 3 && (
                <>
                    <AdditionalInfo modalHandler={modalHandler}
                        showModal={showModal}
                        project={project}
                        selectedProject={active}
                    />
                </>
            )}

            {isAddInfo !== 3 && (
                <>
                    <CustomModal open={showModal} handleClose={modalHandler}>
                        <div
                            style={{
                                background: 'var(--newBlue)',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '5px 10px 0px 0px',
                            }}

                        >
                            <IconButton
                                onClick={() => modalHandler(false)}
                            >
                                <Icon name='closeM' />
                            </IconButton>
                        </div>
                        {modals[activeTab - 1]}
                    </CustomModal>
                    <CustomModal open={!!activeUpdate} handleClose={modalHandlerUpdate}>
                        <div
                            style={{
                                background: 'var(--newBlue)',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '5px 10px 0px 0px',
                            }}
                        >
                            <IconButton
                                onClick={() => modalHandlerUpdate(false)}
                            >
                                <Icon name='closeM' />
                            </IconButton>
                        </div>
                        {modals[activeUpdate - 1]}
                    </CustomModal>
                </>
            )
            }
        </div >
    );
};

export default Details;

export let arrayOfStatus = [
    [
        // { title: 'All', value: '' },
        { title: 'Paid', value: 'PAID' },
        { title: 'Pending', value: 'PENDING' },
        { title: 'Invoiced', value: 'INVOICED' },
        { title: 'Partially Invoiced', value: 'PARTIALLY_INVOICED' },
    ],

    [
        { title: 'Paid', value: 'PAID' },
        { title: 'Unpaid', value: 'UNPAID' },
    ],
];
