import { getAmountDetail, getInvoiceNumber } from 'api/invoice';
import { errors, fields, validations } from 'pages/invoice/data';
import { useEffect, useState } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { useSubsidiaryList } from 'react-query/invoice/subsidiary/useSubsidiaryList';
import { useInvoiceMutateQuery } from 'react-query/payment-schedule/useInvoiceMutateQuery';
import { useInvoiceQuery } from 'react-query/payment-schedule/useInvoiceQuery';
import { useInvoiceProjects } from 'react-query/projects/useInvoiceProjects';
import { useProjects } from 'react-query/projects/useProjects';
import { useSelector } from 'react-redux';
import generateInvoiceNumber from 'utils/generateInvoiceNumber';

const useModal = ({ type, project, activeTab, modalHandler, filterData }) => {
    const [invLoading, setInvLoading] = useState(false);
    let initialProject = project?.title === 'All projects' ? null : project;
    const [details, setDetails] = useState({
        PS: {
            projectId: { value: initialProject, error: '' },
        },
        INVOICE: {
            projectId: { value: initialProject, error: '' },
            invoicedAt: { value: new Date(), error: '' },
            dueDate: { value: new Date(), error: '' },
        },
    });

    const [errorCode, setErrorCode] = useState(null);

    const userType = useSelector((state) => state.userReducer?.userType?.userType);

    const orgId = useSelector((state) => state.userReducer?.selectedOrganisation?._id);
    const queryClient = useQueryClient();
    // const queryClient = new QueryClient();
    const onError = (error) => {
        setErrorCode(error?.request.status);
    };

    const projectId = project?._id; //
    const month = filterData[activeTab - 1]?.MonthBy;
    const year = filterData[activeTab - 1]?.FinancialYear;
    const status = filterData[activeTab - 1]?.status;
    const createdAt = filterData[activeTab - 1]?.createdAt;

    const { isLoading, mutate } = useInvoiceMutateQuery(
        activeTab,
        modalHandler,
        onError,
        projectId,
        status,
        month,
        year,
        createdAt
    );

    const { data: subsidiaryList, isLoading: subsidiaryLoading } = useSubsidiaryList({ orgId });

    const { isLoading: projectLoading, data: projectsData } = useInvoiceProjects('all');

    const {
        data,
        isLoading: paymentScheduleLoading,
        refetch,
    } = useInvoiceQuery(
        1,
        details?.[type]?.projectId?.value?._id,
        ['PENDING', 'PARTIALLY_INVOICED'],
        undefined,
        undefined,
        undefined,
        undefined,
        true
    );
    useEffect(() => {
        refetch();
    }, []);
    const getInvoiceList = async (subId) => {
        setInvLoading(true);
        console.log('subId', subId);
        const inV = await getInvoiceNumber(orgId, subId);
        console.log('inV', inV);
        const getInv = generateInvoiceNumber(inV?.invoiceNumber);
        onValueChange(getInv, 'invoiceNumber');
        setInvLoading(false);
    };

    const createError = (key) => {
        setDetails((prev) => {
            const temp = { ...prev };
            temp[type] = {
                ...temp[type],
                [key]: {
                    value: temp[type][key]?.value,
                    error: errors[type][key],
                },
            };
            return temp;
        });
    };

    const executeOtherActions = (otherAction, value) => {
        switch (otherAction) {
            case 'subsiduary':
                getInvoiceList(value?._id);
                break;

            case 'project':
                onValueChange(null, 'paymentSchedule');
                break;

            case 'amountFill':
                let PsAmount = 0;
                let invoiceAmount = 0;
                value.forEach((v) => {
                    PsAmount += Number(v.amount);
                    v.invoice.forEach((i) => {
                        invoiceAmount += Number(i.amount);
                    });
                });

                PsAmount -= invoiceAmount;
                onValueChange(String(PsAmount), 'amount');
                onValueChange(value[0]?.dueDate ?? new Date(), 'dueDate');
                break;

            default:
                break;
        }
    };

    const onValueChange = (value, key, otherAction) => {
        console.log('value', value);
        console.log('otherAction', otherAction);

        otherAction && executeOtherActions(otherAction, value);
        setDetails((prev) => {
            let temp = prev;
            if (temp[type][key]) {
                temp[type][key] = { value, error: '' };
            } else {
                if (Object.keys(temp[type]).length) {
                    temp[type] = { ...temp[type], [key]: { value, error: '' } };
                } else {
                    temp[type] = { [key]: { value, error: '' } };
                }
            }
            return { ...temp };
        });
    };
    // ['projectAmount', orgId, projectId]
    const onSubmit = (otherAmount) => {
        if (type === 'PS') {
            if (!details[type]?.projectId?.value) {
                createError('projectId');
            } else if (!details[type]?.name?.value?.trim()?.length) {
                createError('name');
            } else if (!details[type]?.amount?.value?.trim()?.length) {
                createError('amount');
            } else {
                const dataToSend = {
                    name: details[type]?.name?.value,
                    amount: Number(details[type]?.amount?.value),
                    projectId: details?.[type]?.projectId?.value?._id,
                    ...(details[type]?.isRestricted && {
                        isRestricted: details[type]?.isRestricted?.value,
                    }),
                    ...(details[type]?.dueDate && {
                        dueDate: new Date(details[type]?.dueDate.value).toISOString(),
                    }),
                    ...(details[type]?.paidAt && {
                        paidAt: new Date(details[type]?.paidAt.value),
                    }),
                    description: details[type]?.notes?.value,
                };
                mutate(dataToSend);
            }
        } else if (type === 'INVOICE') {
            // let paymentScheduleArr = details?.INVOICE?.paymentSchedule?.value.map((el) => el?._id)
            // console.log('paymentScheduleArr', paymentScheduleArr)
            const dataToValidate = details?.INVOICE;
            let isValid = true;
            validations.forEach((el) => {
                const isArray = Array.isArray(dataToValidate?.[el]?.value);

                if (isArray) {
                    if (!dataToValidate?.['paymentSchedule']?.value.length > 0 && isValid) {
                        createError('paymentSchedule');
                        isValid = false;
                    }
                } else {
                    if (!dataToValidate?.[el]?.value && isValid) {
                        createError(el);
                        isValid = false;
                    }
                }
            });

            if (isValid) {
                let paymentScheduleArr = dataToValidate?.paymentSchedule?.value.map((el) => el?._id);
                const dataToSend = {
                    projectId: dataToValidate?.projectId?.value?._id,
                    subsiduary: dataToValidate?.subsiduary?.value?._id,
                    paymentSchedule: paymentScheduleArr,
                    invoiceNumber: dataToValidate?.invoiceNumber?.value,
                    ...(dataToValidate?.invoicedAt?.value && {
                        invoicedAt: new Date(dataToValidate?.invoicedAt?.value),
                    }),
                    ...(dataToValidate?.dueDate?.value && {
                        dueDate: new Date(dataToValidate?.dueDate?.value),
                    }),
                    amount: dataToValidate?.amount?.value,
                    description: dataToValidate?.description?.value,
                    otherAmount: otherAmount,
                    description: dataToValidate?.notes?.value,
                };

                mutate(dataToSend);
            }
        }
    };
    return {
        fields: fields?.[type],
        details: details?.[type],
        onValueChange,
        onSubmit,
        isAdmin: userType === 'Admin',
        isLoading,
        paymentScheduleData: data?.data,
        paymentScheduleLoading,
        projectsData,
        projectLoading,
        subsidiaryList,
        subsidiaryLoading,
        invLoading,
        errorCode,
        // inputFields,
        // handleInputChange,
        // addFields,
    };
};

export default useModal;
