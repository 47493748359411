import { Dialog } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { getInvoices } from 'api/paymentSchedule';
import Icon from 'components/icons/IosIcon';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { DownloadInvoiceItem } from '../DownloadInvoiceItem';
// import DownloadIcon from '@mui/icons-material/Download';


export const InvoiceIdPdfViewer = ({
    isShowPdf,
    handleCloseOpenPdfModal,
    invoiceId,
}) => {
    //parents for
    // const [isShowPdf, setIsShowPdf] = useState(false);
    // const handleCloseOpenPdfModal = (value) => {
    //     setIsShowPdf(value);
    // }
    // let data = {}
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );

    const { data, isFetching, isFetched } = useQuery(['singleInvoice', invoiceId],
        () => getInvoices(orgId, { invoiceId }),
        {
            enabled: !!invoiceId
        })
    return (
        <Dialog
            open={isShowPdf}
        >
            <div onClick={() => handleCloseOpenPdfModal(
                { status: false, invoiceId: null }
            )} className='pdfViewer'>
                <Icon name='redCancel' style={{ marginRight: 15, marginBottom: 5, marginTop: 5 }} />


                <PDFViewer height={500} width={500}
                >
                    {isFetching ? <div

                    >
                        <CircularProgress />
                    </div> :
                        <DownloadInvoiceItem item={data?.data[0]} />
                    }
                </PDFViewer>



            </div>
            <div style={{ position: 'absolute', right: 40, bottom: 20 }}>
                {/* <Icon name='download' style={{ marginRight: 15, marginBottom: 5, marginTop: 5, width: 20, height: 20 }} /> */}

                <PDFDownloadLink
                    document={<DownloadInvoiceItem item={data?.data[0]} />}
                    fileName={`Invoice # ${data?.data[0]?.invoiceNumber}.pdf`}
                >
                    {({ blob, url, loading, error }) =>
                        loading ? (
                            <Loader size={15} color='grey' />
                        ) : (
                            // <CustomButton
                            //     backgroundColor={'var(--yellow)'}
                            // // className={
                            // //     'view_invoice_container_button'
                            // // }
                            // >
                            //     <p>Download</p>
                            // </CustomButton>
                            <Tooltip title='Download Invoice'>
                                <IconButton>
                                    <DownloadIcon color='secondary' />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </PDFDownloadLink>
            </div>
        </Dialog>
    )
}
