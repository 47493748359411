import React from "react";
import "./scheduled.scss";
import RowItem from "./component/rowItem/RowItem";
import ScheduledHeading from "./component/scheduledHeading/ScheduledHeading";
import Icon from "components/icons/IosIcon";
import useScheduled from "./useScheduled";
import { Drawer, makeStyles } from "@material-ui/core";
import DrawerScheduled from "./drawerCompo/DrawerScheduled";
import AddRequest from "./addRequest/AddRequest";
import { CircularProgress } from "@mui/material";
import NoData from "components/NoData";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "40%",
    minWidth: 500,
    background: "#201957",
    top: 60,
    right: 25,
    borderRadius: 25,
    paddingBottom: 100,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

function Scheduled({ add }) {
  const classes = useStyles();
  const {
    listData: { data, isFetching, isLoading },
    drawer: { drawerOpen, handleDrawer },
    handleAcceptReject,
  } = useScheduled();

  //api sahi hone k bad changes krnge
  // let tempData = filteredList ?? data;

  let drawerComponent = {
    add: <AddRequest closeDrawer={handleDrawer} />,
    filter: <DrawerScheduled closeDrawer={handleDrawer} />, //filter
  };
  return (
    <div className="container1">
      <div className="section1">
        <div className="sectionChild2" onClick={() => handleDrawer("add")}>
          <div className="iconBorder">
            <p className="plus">+</p>
          </div>
          <p className="text">Add Event</p>
        </div>
        <Icon
          name="filter"
          className="filterIcon"
          onClick={() => handleDrawer("filter")}
        />
      </div>

      <Drawer
        variant="persistent"
        anchor="right"
        open={drawerOpen.isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawerComponent[drawerOpen?.type]}
      </Drawer>

      <ScheduledHeading />
      <div className="section2">
        {isFetching || isLoading ? (
          <div className="loading">
            <CircularProgress />
          </div>
        ) : (
          <>
            {data?.length === 0 ? (
              <NoData />
            ) : (
              data?.map((el, i) => {
                return (
                  <RowItem
                    el={el}
                    handleAcceptReject={(v) => handleAcceptReject(v, el._id, i)}
                  />
                );
              })
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Scheduled;
