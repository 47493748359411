import React from 'react'
import './editInfo.scss'
import { inputWithLevel } from './BasicDetail'
import CustomInput from 'components/customInput/CustomInput'
import Icon from 'components/icons/IosIcon';
import CustomButton from 'components/CustomButton';

export const OtherInfo = () => {
    return (
        <div className='editInfo'>
            <div className='heading'>
                <p className='text1'>Edit Info</p>
                <Icon name={'addRound'} />
            </div>
            <div className='border'></div>
            <div className='fieldBox'>
                {inputWithLevel('Client NAme')}
                {inputWithLevel('Client NAme')}
                {inputWithLevel('Client NAme')}

            </div>
            <div className='DFpd'>
                <CustomButton>Save</CustomButton>
            </div>
        </div>
    )
}
