import React from 'react';
import { useModalUpdate } from './useModalUpdate';
import FieldRendered from './FieldRendered';
import { PSFieldsUpdate } from 'pages/invoice/data';
import CustomButton from 'components/CustomButton';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

const PSModalUpdate = ({
    modalData,
    active,
    onSubmit,
    filterData,
    activeTab,
    project,
    selectedProject
}) => {
    const {
        details,
        isAdmin,
        onValueChange,
        projectsData,
        projectLoading,
        paymentScheduleData,
        paymentScheduleLoading,
        onUpdate,
        isLoading,
        handleDeleteItem,
    } = useModalUpdate({
        type: 'PS',
        modalData,
        active,
        onSubmit,
        filterData,
        keyForApi: 'payment-schedule',
        activeTab,
        project,
        selectedProject
    });

    const [askDelete, setAskDelete] = useState(false);
    const handleCloseDeleteModal = () => {
        setAskDelete(!askDelete);
    };

    return (
        <div className='modal'>
            <p className='heading'>Add a Payment - Schedule</p>
            {
                <FieldRendered
                    details={details}
                    fields={PSFieldsUpdate}
                    isAdmin={isAdmin}
                    onValueChange={onValueChange}
                    menuItems={{
                        projectId: {
                            data: projectsData?.projects,
                            isLoading: projectLoading,
                        },
                        paymentSchedule: {
                            data: paymentScheduleData,
                            isLoading: paymentScheduleLoading,
                        },
                    }}
                />
            }
            <div className='button'>
                <CustomButton
                    style={{ marginRight: 10 }}
                    backgroundColor={'var(--red)'}
                    onClick={handleCloseDeleteModal}
                >
                    <p>Delete</p>
                </CustomButton>

                <CustomButton onClick={onUpdate} loading={isLoading}>
                    <p>Update</p>
                </CustomButton>
            </div>

            <Dialog
                open={askDelete}
                // onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div className='askDelete'>
                    <p className='text'>Do you want to delete</p>
                    <div>
                        <CustomButton
                            style={{ marginRight: 10 }}
                            backgroundColor={'var(--red)'}
                            onClick={() => {
                                handleCloseDeleteModal();
                                handleDeleteItem();
                            }}
                        >
                            <p>Yes</p>
                        </CustomButton>
                        <CustomButton
                            style={{ marginRight: 10 }}
                            onClick={handleCloseDeleteModal}
                        // backgroundColor={'var(--red)'}
                        >
                            <p>No</p>
                        </CustomButton>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default PSModalUpdate;
