/* eslint-disable array-callback-return */
import { CircularProgress } from '@material-ui/core';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/customInput/CustomInput';
import CheckBoxSquare from 'components/icons/CheckBoxSquare';
import SelectWithSelect from 'components/select/SelectWithSelect';
import React from 'react';
import { capitalizeFirstLetter } from 'utils/textTruncate';

const FieldRendered = ({
    fields,
    details,
    onValueChange,
    isAdmin,
    menuItems,
    invLoading,
}) => {

    return (
        <div>
            {fields?.map((el) => {
                const elData = details?.[el?.key];
                const value =
                    el.type === 'date'
                        ? elData?.value
                            ? new Date(elData?.value)
                            : null
                        : elData?.value;


                switch (el.type) {
                    case 'text':
                    case 'date':
                    case 'number':
                    case 'textarea':
                        return (
                            <div key={el?.id}>
                                {el?.label && (
                                    <p className='label'>{el?.label}</p>
                                )}
                                {!!value && <p style={{ fontSize: 12, color: 'var(--lightGray)' }}>{el?.name}</p>}
                                <div className='loadingRow'>

                                    <CustomInput

                                        className='info-text'
                                        key={el.id}
                                        placeholder={el?.name}
                                        wrapperClassName='mb-2'
                                        error={elData?.error}
                                        value={value}
                                        name='amount'
                                        onChange={(e) =>
                                            onValueChange(
                                                e.target?.value,
                                                el.key
                                            )
                                        }
                                        onDateChange={(e) =>
                                            onValueChange(e, el.key)
                                        }
                                        type={el.type}
                                    />
                                    {invLoading &&
                                        el.key === 'invoiceNumber' && (
                                            <CircularProgress
                                                size={20}
                                                className='mb-2 ml-2'
                                            />
                                        )}
                                </div>
                            </div>
                        );

                    case 'select':
                        return (
                            <>
                                {/* <SelectWithSelect
                                    options={menuItems[el?.key]?.data ?? []}
                                    loading={menuItems[el?.key]?.isLoading}
                                    className="selectOutlined mb-2"
                                    placeholder={el?.name}
                                    labelClassName="normalFont"
                                    onChange={(e) =>
                                        onValueChange(
                                            e.target?.value,
                                            el.key,
                                            el?.otherKey
                                        )}
                                    name="project"
                                    // value={
                                    //     Array.isArray(details?.project) ? details?.project : undefined
                                    // }
                                    value={elData?.value ? el?.name : null}
                                    errorText={elData?.error}
                                // labelKey
                                /> */}
                                {!!value && <p style={{ fontSize: 12, color: 'var(--lightGray)' }}>{el?.name}</p>}
                                <CustomSelect
                                    errorText={elData?.error}
                                    key={el.id}
                                    isLoading={menuItems[el?.key]?.isLoading}
                                    variant={'outlined'}
                                    placeholder={el?.name}
                                    menuItems={menuItems[el?.key]?.data ?? []}
                                    value={elData?.value ?? el?.name}
                                    handleChange={(e) =>
                                        onValueChange(
                                            e.target?.value,
                                            el.key,
                                            el?.otherKey
                                        )
                                    }
                                    menuRenderComponent={<SelectRender />}
                                    selectRenderComponent={<SelectRender />}
                                    labelClassName={'normalFont'}
                                    containerClassName={'selectOutlined mb-2'}
                                />
                            </>

                        );

                    case 'multiSelect':
                        return (
                            <>

                                {!!value && <p style={{ fontSize: 12, color: 'var(--lightGray)' }}>{el?.name}</p>}
                                <SelectWithSelect
                                    type='inInvoice'
                                    options={menuItems[el?.key]?.data ?? []}
                                    onChange={(e, arr) => {
                                        onValueChange(arr, 'paymentSchedule', el?.otherKey)
                                    }}
                                    placeholder={'Payment Schedule'}
                                // initialSelected={idMatchArr(elData?.value, menuItems[el?.key]?.data)}

                                />
                            </>
                        )

                    case 'checkbox':
                        return (
                            isAdmin && (
                                <div key={el.id} className='alignCenter my-2'>
                                    <CheckBoxSquare
                                        onClick={() =>
                                            onValueChange(
                                                !details?.isRestricted?.value,
                                                el.key
                                            )
                                        }
                                        isChecked={details?.isRestricted?.value}
                                        color='var(--chipRed)'
                                    />
                                    <p className='mx-1 info-text'>
                                        Restrict Payment phase for other users
                                    </p>
                                </div>
                            )
                        );

                    default:
                        break;
                }
            })}
        </div>
    );
};

function SelectRender({ item, selected }) {
    return selected ? (
        <div className={`normalFont d_flex alignCenter textEllipse`}>
            <p className='textEllipse'>
                {' '}
                {capitalizeFirstLetter(item?.name ?? item?.title)}
            </p>
        </div>
    ) : (
        <div className={`normalFont d_flex alignCenter textEllipse`}>
            <p className='pl-1 textEllipse'>
                {' '}
                {capitalizeFirstLetter(item?.name ?? item?.title)}
            </p>
        </div>
    );
}

export default FieldRendered;


const idMatchArr = (arrOfString, arrOfObject) => {
    let s = []
    arrOfString?.forEach((id) => {
        arrOfObject?.forEach((el) => {
            if (el?._id === id) {
                s.push(el)
            }
        })
    })
    return s
}