import { Box } from '@material-ui/core'
import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { countries } from 'utils/countries'

export const CountryPicker = ({
    onChange,
    defaultValue
}) => {
    return (
        <Autocomplete
            sx={{
                padding: '10px',
                background: 'rgba(45, 52, 99, 1)',
                borderRadius: '4px',
            }}

            id="country-select-demo"
            classes={{
                popper: "autoCompletePopper_addLead",
            }}
            onChange={onChange}
            options={countries}
            defaultValue={defaultValue}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    style={{
                        margin: 0,
                    }}
                    className="addLeadAutoComplete inputBox_addLead justifyContent_center"
                    variant="outlined"
                    placeholder="Choose a country"
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            margin: 0,
                            padding: 0,
                            fontSize: 14,
                            fontFamily: "Lato-Regular",
                            color: "#FFF",
                        },
                        autoComplete: "new-password", // disable autocomplete and autofill
                        classes: "{notchedOutline:'classes.noBorder'}",
                    }}
                />
            )}
        />
    )
}
