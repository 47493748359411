import React, { useState } from "react";
import "./EodFilter.scss";
import Icon from "components/icons/IosIcon";
import Calendar from "react-calendar";
import { FilterList } from "../component/filterList/FilterList";
import { EodDateRange } from "../component/dateRange/EodDateRange";

export default function EodFilter({
  onclickFilterType,
  openFilter,
  projectList,
  memberList,
  onClickFilterBtn,
  resetMPFilterList,
  MPSelectAll,
}) {
  // const handleSelect = (elm) => {
  //     setSelect(elm)
  // }
  // const handleSelectProject = (elm) => {
  //     setSelectProject(elm)
  // }
  return (
    <div className="EodFilterContainer">
      <p className="filterHeader">Quick Filters</p>
      <div className="filterBody">
        {["Date", "Project", "Member"].map((elm, i) => {
          return (
            <div
              key={i}
              className="filterBodyRow"
              style={{
                backgroundColor: openFilter.type === elm && "#625df5",
              }}
              onClick={() => onclickFilterType(elm)}
            >
              <div className="eodCheckBox">
                {openFilter?.filterSelect[elm] && <Icon name="tick" />}
              </div>
              <p>{elm}</p>
            </div>
          );
        })}

        {openFilter.type === "Project" && (
          <FilterList
            queryData={projectList}
            openFilter={openFilter}
            onclickFilterType={onclickFilterType}
            resetMPFilterList={resetMPFilterList}
            MPSelectAll={MPSelectAll}
          />
        )}
        {openFilter.type === "Member" && (
          <FilterList
            queryData={memberList}
            openFilter={openFilter}
            onclickFilterType={onclickFilterType}
            resetMPFilterList={resetMPFilterList}
            MPSelectAll={MPSelectAll}
          />
        )}

        {openFilter.type === "Date" && (
          <div className="calenderFilter">
            {/* <Calendar
                        showNeighboringMonth={false}
                        onChange={setCalenderDate} value={calenderDate}
                    /> */}
            <EodDateRange
              value={openFilter.Date}
              onChange={(item) => onclickFilterType(null, item)}
            />
          </div>
        )}

        {openFilter.type !== null && (
          <div className="doneBtnContainer">
            <p onClick={() => onClickFilterBtn("clear")} className="doneBtn">
              Clear All
            </p>
            <p onClick={onClickFilterBtn} className="doneBtn">
              Save
            </p>
            {/* for project and member list */}
            {/* <p onClick={() => resetMPFilterList(openFilter.type)} className='doneBtn'>Clear All</p>
                    <p onClick={() =>
                        MPSelectAll({
                            type: openFilter.type,
                            list: openFilter.type === 'Project' ? projectList?.data?.projects : memberList?.data?.users
                        })} className='doneBtn'>Select All</p> */}
          </div>
        )}
      </div>
    </div>
  );
}
