import React, { useEffect } from "react";
import "./filterList.scss";
import Icon from "components/icons/IosIcon";
import { useOrgTeam } from "react-query/organisations/useOrgTeam";
import { CircularProgress, IconButton } from "@material-ui/core";
import { useState } from "react";
import { InputEod } from "../customSelectInputEod/InputEod";
import NoData from "components/NoData";

export const FilterList = ({
  selectProject,
  queryData,
  openFilter,
  onclickFilterType,
  resetMPFilterList,
  MPSelectAll,
}) => {
  const { data, isLoading } = queryData;
  // data?.projects
  // data?.users
  const [listFiltering, setListFiltering] = useState([]);

  useEffect(() => {
    setListFiltering(
      openFilter.type === "Project" ? data?.projects : data?.users
    );
  }, [data]);

  const onTypingSearched = (searchVal) => {
    console.log("searchValType", searchVal);
    let tempList = openFilter.type === "Project" ? data?.projects : data?.users;
    let filter = tempList?.filter(
      (el) =>
        el?.name?.toLowerCase().includes(searchVal?.toLowerCase()) ||
        el?.title?.toLowerCase().includes(searchVal?.toLowerCase())
    );
    setListFiltering(filter);
    // temp.forEach((el) => {
    //     let
    //     console.log('el?.name?.toLowerCase().includes', el?.name?.toLowerCase().includes(searchVal.toLowerCase()))
    //     if (el?.name?.toLowerCase().includes('test')) {
    //         aaa.push(el)
    //         console.log('el22', el)
    //     }
    // })

    // let temp = [...listFiltering].filter((el) => {
    //     if (el?.title?.toLowerCase().includes(searchVal?.toLowerCase())) {
    //         return el
    //     }
    // })
    // console.log('temp!', temp)
  };
  return (
    <div className="filterList">
      <div className="afterSelect">
        <input
          className="filterInput"
          placeholder={`Search ${openFilter.type}.....`}
          onChange={(e) => onTypingSearched(e.target.value)}
        />
        <div className="filterBtn">
          {!!openFilter?.Project?.length && (
            <p onClick={() => resetMPFilterList(openFilter.type)}>Clear</p>
          )}

          {!!openFilter?.Member?.length && (
            <p onClick={() => resetMPFilterList(openFilter.type)}>Clear</p>
          )}
          <p
            onClick={() =>
              MPSelectAll({
                type: openFilter.type,
                list:
                  openFilter.type === "Project" ? data?.projects : data?.users,
              })
            }
          >
            Select All
          </p>
        </div>
        {/* <InputEod
                    onChange={(e) => onTypingSearched(e.target.value)}
                /> */}
        {/* <p onClick={onTypingSearched}>Select {openFilter.type}s</p> */}
        {/* <IconButton onClick={() => resetMPFilterList(openFilter.type)}>
                    <p>CA</p>
                </IconButton>
                <IconButton onClick={() =>
                    MPSelectAll({
                        type: openFilter.type,
                        list: openFilter.type === 'Project' ? data?.projects : data?.users
                    })}>
                    <p >SA</p>
                </IconButton> */}
      </div>
      <div style={{ maxHeight: 150, overflow: "auto" }}>
        {/* for projects */}
        {isLoading && (
          <div className="filterLoading">
            <CircularProgress />
          </div>
        )}

        {listFiltering?.length === 0 ? (
          <NoDataFound />
        ) : (
          <>
            {!!data?.projects &&
              listFiltering?.map((elm, i) => {
                let index = openFilter?.Project?.findIndex(
                  (el) => el?._id === elm?._id
                );
                return (
                  <div
                    key={i}
                    className="filterBodyRow"
                    onClick={() => onclickFilterType(null, elm)}
                  >
                    <div className="eodCheckBox">
                      {index > -1 && <Icon name="tick" />}
                    </div>
                    <p>{elm?.title}</p>
                  </div>
                );
              })}

            {/* for Members */}
            {!!data?.users &&
              listFiltering?.map((elm, i) => {
                let index = openFilter?.Member?.findIndex(
                  (el) => el?._id === elm?._id
                );
                return (
                  <div
                    key={i}
                    className="filterBodyRow"
                    onClick={() => onclickFilterType(null, elm)}
                  >
                    <div className="eodCheckBox">
                      {index > -1 && <Icon name="tick" />}
                    </div>
                    <p>{elm?.name}</p>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

const NoDataFound = () => {
  return (
    <div
      style={{
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p> No Data Match</p>
    </div>
  );
};
