import React, { useEffect, useState } from "react";
import "./drawerScheduled.scss";
import Icon from "components/icons/IosIcon";
import CheckBoxInScheduleDrawer from "../component/checkBoxInScheduleDrawer/CheckBoxInScheduleDrawer";
import { Value } from "sass";
import InputOrSelectBox from "../component/checkBoxInScheduleDrawer/inputOrSelectBox/InputOrSelectBox";
import UserLIstInDrawer from "../component/useLIstInDrawer/UserLIstInDrawer";
import CustomButton from "components/CustomButton";
import useScheduleFilter from "./useScheduleFilter";
import { typeData } from "../addRequest/AddRequest";
import useScheduled from "../useScheduled";
import DatePicker from "react-multi-date-picker";
import CustomRow from "components/CustomRow";
import CustomDatePicker from "components/customDatePicker/CustomDatePicker";

const initialValue = {
  Date: false,
  EmployeeName: false,
  Status: false,
  Type: false,
  ApprovedBy: false,
};
const mb = { marginBottom: 15 };

function DrawerScheduled({ closeDrawer }) {
  // const { isLoading,
  //     adminList,
  //     userList,
  //     handleOnchange,
  //     filterData,
  //     handleFilter } = useScheduleFilter()

  const {
    isLoading,
    adminList,
    userList,
    handleOnchange,
    filterData,
    handleFilter,
    handleClearFilter,
    isLoadingFilter,
  } = useScheduled();

  useEffect(() => {
    isLoadingFilter && closeDrawer();
  }, [isLoadingFilter]);
  console.log("filterData", filterData);
  return (
    <div className="drawerScheduleSection">
      <div className="header">
        <p className="headerText">Quick Filter</p>
        <Icon name="cross" className="icon" onClick={closeDrawer} />
      </div>
      <div className="line"></div>
      {/*  */}
      <div className="filterSection">
        <div className="mb">
          <CheckBoxInScheduleDrawer
            text="Date"
            style={mb}
            isSelect={!!filterData.fromDate && !!filterData.toDate}
          />
          {/* {isSelected?.Date && */}
          <div className="df">
            {/* <InputOrSelectBox
                            styleBox={{ width: '48%' }}
                            placeholder={'From Date'}
                            isInputBox={true}
                            inputType='date'
                            mode='input'
                            onChange={(e) =>
                                handleOnchange('fromDate', e.target.value)}
                        /> */}

            {/* <DatePicker
            //   multiple
            //   value={values}
            //   onChange={setValues}
            /> */}

            <CustomDatePicker
              onChange={() => {}}
              className={"inputSelectBox"}
              innerContainerStyle={"justifyContent_start"}
            >
              <p>{"Start Date"}</p>
            </CustomDatePicker>
            {/* <InputOrSelectBox
              styleBox={{ width: "48%" }}
              placeholder={"To Date"}
              isInputBox={true}
              inputType="date"
              mode="input"
              onChange={(e) => handleOnchange("toDate", e.target.value)}
            /> */}

            <CustomDatePicker
              onChange={() => {}}
              className={"inputSelectBox"}
              innerContainerStyle={"justifyContent_start"}
            >
              <p>{"End Date"}</p>
            </CustomDatePicker>
          </div>

          {/* } */}
        </div>
        <div className="mb">
          <CheckBoxInScheduleDrawer
            style={mb}
            text="Employee Name"
            isSelect={!!filterData.userId}
          />
          {/* {isSelected?.EmployeeName && */}
          <div className="df">
            <UserLIstInDrawer
              arrOfObject={userList}
              handleSelectOption={(e, i) => handleOnchange("userId", e?.userId)}
            />
          </div>
          {/* } */}
        </div>
        <div className="mb">
          <CheckBoxInScheduleDrawer
            style={mb}
            text="Status"
            isSelect={!!filterData.status}
            // isSelect={isSelected?.Status}
            // handleCheckBox={() => handleCheckBox(
            //     { ...isSelected, Status: !isSelected?.Status }
            // )}
          />
          {/* {isSelected?.Status && */}
          <div className="df">
            <InputOrSelectBox
              text="Approved"
              handleSelect={(v) => handleOnchange("status", v)}
              isSelect={filterData?.status}
            />
            <InputOrSelectBox
              text="Rejected"
              handleSelect={(v) => handleOnchange("status", v)}
              isSelect={filterData?.status}
            />
            <InputOrSelectBox
              text="Pending"
              handleSelect={(v) => handleOnchange("status", v)}
              isSelect={filterData?.status}
            />
          </div>
          {/* } */}
        </div>
        <div className="mb">
          <CheckBoxInScheduleDrawer
            style={mb}
            text="Type"
            isSelect={!!filterData.type}
          />

          <div className="df">
            <UserLIstInDrawer
              heading="Leave"
              handleSelectOption={(v) => handleOnchange("type", v?.value)}
              arrOfObject={typeData}
              radio={true}
              // selected={formValues.type}
            />
          </div>
          {/* } */}
        </div>
        <div className="mb">
          <CheckBoxInScheduleDrawer
            style={mb}
            text="Approved by"
            isSelect={!!filterData.authorizedId}
            // isSelect={isSelected?.ApprovedBy}
            // handleCheckBox={() => handleCheckBox(
            //     { ...isSelected, ApprovedBy: !isSelected?.ApprovedBy }
            // )}
          />
          {/* {isSelected?.ApprovedBy && */}
          <div className="df">
            <UserLIstInDrawer
              arrOfObject={adminList}
              handleSelectOption={(e, i) =>
                handleOnchange("authorizedId", e?.userId)
              }
            />
          </div>
          {/* } */}
        </div>

        <div className="btnDiv">
          <CustomButton
            backgroundColor={"var(--red"}
            onClick={handleClearFilter}
          >
            Clear
          </CustomButton>
          <CustomButton
            style={{ marginLeft: 15 }}
            backgroundColor={"var(--primary"}
            onClick={handleFilter}
          >
            Apply
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default DrawerScheduled;
