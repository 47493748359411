import React, { useCallback } from 'react'
import './customSelectInputEod.scss'

export const InputEod = ({
    placeholder,
    onChange,
    value,
}) => {
    return (
        <div className='selectDiv'>
            <input
                className='selectDivInput'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}
