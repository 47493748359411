import { deletePaymentInvoiceItem } from 'api/paymentSchedule';
import { errors, validationsInvoiceUpdate } from 'pages/invoice/data';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSubsidiaryList } from 'react-query/invoice/subsidiary/useSubsidiaryList';
import { deleteInvoicePsHandler, updateProjectsInvoice } from 'react-query/payment-schedule/ops';
import { useInvoiceMutateQuery } from 'react-query/payment-schedule/useInvoiceMutateQuery';
import { useInvoiceQuery } from 'react-query/payment-schedule/useInvoiceQuery';
import { useInvoiceProjects } from 'react-query/projects/useInvoiceProjects';
import { useSelector } from 'react-redux';

const keyData = [
    { key: 'payment-schedules', dataKey: 'paymentSchedule' },
    { key: 'invoice-new', dataKey: 'invoice' },
];

export const useModalUpdate = ({
    type,
    modalData,
    active,
    onSubmit,
    filterData,
    keyForApi,
    activeTab,
    project,
    selectedProject,
}) => {
    const queryClient = useQueryClient();

    const userType = useSelector((state) => state.userReducer?.userType?.userType);

    const { isLoading: projectLoading, data: projectsData } = useInvoiceProjects('all');

    const orgId = useSelector((state) => state.userReducer?.selectedOrganisation?._id);

    const { data: subsidiaryList, isLoading: subsidiaryLoading } = useSubsidiaryList({ orgId });

    const [details, setDetails] = useState({
        PS: {},
        INVOICE: {},
    });

    const projectId = project?._id; //
    const month = filterData[activeTab - 1]?.MonthBy;
    const year = filterData[activeTab - 1]?.FinancialYear;
    const status = filterData[activeTab - 1]?.status;
    const createdAt = filterData[activeTab - 1]?.createdAt;
    const subsidary = filterData[activeTab - 1]?.subsidary;

    const { isLoading, mutate } = useInvoiceMutateQuery(
        active,
        onSubmit,
        undefined,
        projectId,
        status,
        month,
        year,
        createdAt,
        subsidary
    );

    useEffect(() => {
        console.log('modalDat@!a', modalData)
        let formatData = {};
        modalData &&
            Object.keys(modalData).forEach((el) => {
                formatData = {
                    ...formatData,
                    [el]: { value: modalData[el], error: '' },
                };
            });
        setDetails({ PS: { ...formatData }, INVOICE: { ...formatData } });
    }, [modalData]);

    const { data, isLoading: paymentScheduleLoading } = useInvoiceQuery(
        1,
        type === 'INVOICE' ? details?.[type]?.projectId?.value?._id : null
    );

    const onValueChange = (value, key, otherAction) => {
        setDetails((prev) => {
            let temp = prev;
            if (temp[type][key]) {
                temp[type][key] = { value, error: '' };
            } else {
                if (Object.keys(temp[type]).length) {
                    temp[type] = { ...temp[type], [key]: { value, error: '' } };
                } else {
                    temp[type] = { [key]: { value, error: '' } };
                }
            }
            return { ...temp };
        });
    };

    const createError = (key) => {
        setDetails((prev) => {
            const temp = { ...prev };
            temp[type] = {
                ...temp[type],
                [key]: {
                    value: temp[type][key]?.value,
                    error: errors[type][key],
                },
            };

            return temp;
        });
    };

    const onUpdate = (otherAmount) => {
        if (type === 'PS') {
            if (!details[type]?.projectId?.value) {
                createError('projectId');
            } else if (!details[type]?.name?.value?.trim()?.length) {
                createError('name');
            } else if (!details[type]?.amount?.value?.trim()?.length) {
                createError('amount');
            } else {
                const dataToSend = {
                    paymentScheduleId: modalData?._id,
                    name: details[type]?.name?.value,
                    amount: Number(details[type]?.amount?.value),
                    projectId: details?.[type]?.projectId?.value?._id,
                    ...(details[type]?.isRestricted && {
                        isRestricted: details[type]?.isRestricted.value,
                    }),
                    ...(details[type]?.dueDate && {
                        dueDate: new Date(details[type]?.dueDate.value).toISOString(),
                    }),
                    ...(details[type]?.paidAt && {
                        paidAt: new Date(details[type]?.paidAt.value),
                    }),
                };

                mutate(dataToSend);
            }
        } else if (type === 'INVOICE') {
            const dataToValidate = details?.INVOICE;
            let isValid = true;
            validationsInvoiceUpdate.forEach((el) => {
                if (!dataToValidate?.[el]?.value && isValid) {
                    createError(el);
                    isValid = false;
                }
            });
            if (isValid) {
                const dataToSend = {
                    invoiceId: modalData?._id,
                    projectId: dataToValidate?.projectId?.value?._id,
                    subsiduary: dataToValidate?.subsiduary?.value?._id,
                    paymentSchedule: modalData?.paymentSchedule,
                    invoiceNumber: dataToValidate?.invoiceNumber?.value,
                    ...(dataToValidate?.dueDate && {
                        dueDate: new Date(dataToValidate?.dueDate?.value),
                    }),
                    ...(dataToValidate?.invoicedAt && {
                        invoicedAt: new Date(dataToValidate?.invoicedAt?.value),
                    }),
                    amount: dataToValidate?.amount?.value,
                    description: dataToValidate?.description?.value,
                    otherAmount: otherAmount,
                };
                mutate(dataToSend);
            }
        }
    };

    let handleDeleteItem = () => {
        try {
            deletePaymentInvoiceItem(keyForApi, orgId, modalData?._id).then((res) => {
                onSubmit && onSubmit(false);
                const dep = [
                    keyData[activeTab - 1].key,
                    orgId,
                    selectedProject?._id ?? 'all',
                    status ?? 'all',
                    month ?? 'all',
                    year ?? 'all',
                    createdAt ?? 'all',
                    subsidary ?? 'all',
                ];

                const prevData = queryClient.getQueryData(dep);

                const { newData } = deleteInvoicePsHandler(prevData, keyData[activeTab - 1].dataKey, modalData?._id);

                const dependency = [
                    'projectAmount',
                    orgId,
                    selectedProject?._id,
                    selectedProject?._id ? 'all' : month,
                    selectedProject?._id ? 'all' : year,
                ];

                queryClient.invalidateQueries(dependency);

                queryClient.setQueryData(dep, newData);
            });
        } catch (error) {
            console.error('handleDeleteItem', error);
        }
    };

    return {
        details: details[type],
        isAdmin: userType === 'Admin',
        onValueChange,
        projectsData,
        projectLoading,
        paymentScheduleData: data?.data,
        paymentScheduleLoading,
        subsidiaryList,
        subsidiaryLoading,
        onUpdate,
        isLoading,
        handleDeleteItem,
    };
};
