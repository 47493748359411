import { useState } from 'react';

const useDetails = ({ activeTab }) => {
    const [modalData, setModalData] = useState({ PS: null, INVOICE: null });
    const [active, setActive] = useState(null);

    const onItemPress = (item) => {
        setModalData({ PS: item, INVOICE: item });
        setActive(activeTab === 1 ? 3 : 4);
    };

    const modalHandler = () => {
        setModalData({ PS: null, INVOICE: null });
        setActive(null);
    };

    return { modalData, onItemPress, active, modalHandler };
};

export default useDetails;
