import React from 'react'
import './scheduledHeading.scss'
import { useSelector } from 'react-redux';

export default function ScheduledHeading() {
    const userType = useSelector(
        (state) => state.userReducer?.userType?.userType
    );
    return (
        <div className='headingSection'>
            {userType === 'Admin' && <div className='child1'>
                <p>Employee</p>
            </div>}

            <div className='child2'>
                <p>Date</p>
            </div>
            <div className='child2'>
                <p>
                    Type
                </p>
            </div>
            <div className='child2'>
                <p>
                    Time
                </p>
            </div>
            <div className='child3'>
                <p>Reason</p>
            </div>
            <div className='child4'>
                <p>Status</p>
            </div>
            <div className='child5'>
                <p>Authorized By</p>
            </div>
        </div>
    )
}
