import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import MyButton from 'components/MyButton/MyButton';
import MenuBox from 'components/MyPopOver/MenuBox';
import MyPopOver from 'components/MyPopOver/MyPopOver';
import moment from 'moment';
import { invoiceStatus } from 'pages/invoice/data';
import { useState } from 'react';
import Loader from 'react-spinners/ClipLoader';
import { DownloadInvoiceItem } from './DownloadInvoiceItem';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import DownloadIcon from '@mui/icons-material/Download';
import Icon from 'components/icons/IosIcon';
import DownloadInvoice from 'pages/invoice/viewInvoice/DownloadInvoice';
import CustomModal from 'components/CustomModal/CustomModal';
import { Dialog } from '@material-ui/core';
import { InvoiceIdPdfViewer } from './invoiceIdPdfViewer/InvoiceIdPdfViewer';
import { useQuery } from 'react-query';
import { getInvoices } from 'api/paymentSchedule';
import { useSelector } from 'react-redux';

const InvoiceItem = ({ style, index, item, onStatusChange, onItemPress, heading, isLast, isShowPN }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isShowPdf, setIsShowPdf] = useState({
        status: false,
        invoiceId: null,
    });

    const handleCloseOpenPdfModal = ({ status, invoiceId }) => {
        setIsShowPdf((prev) => {
            let temp = { ...prev, status, invoiceId };
            return temp;
        });
    };

    const handleClick = (e) => {
        if (e?.key) {
            onStatusChange({ ...e, index, type: 'invoice-new', item });
            setAnchorEl(null);
        } else {
            setAnchorEl(anchorEl ? null : e?.currentTarget);
        }
    };

    // const handleCloseOpenPdfModal = (value) => {
    //     setIsShowPdf(value);
    // }
    const orgId = useSelector(
        (state) => state.userReducer?.selectedOrganisation?._id
    );
    const [isPdfDownload, setIsPdfDownload] = useState(false);
    const { data, isFetching, isFetched } = useQuery(['singleInvoice', item?._id],
        () => getInvoices(orgId, { invoiceId: item?._id }),
        {
            enabled: !!isPdfDownload
        })

    // 
    return (
        <>
            {!heading && (
                <div className='box boxIn' style={{ borderBottom: !isLast && 'none' }}>
                    <div className='box2'>
                        <div style={{ display: 'flex' }}>
                            <p
                                className='textBlue'
                                onClick={() =>
                                    handleCloseOpenPdfModal({
                                        status: true,
                                        invoiceId: item?._id,
                                    })
                                }
                            >{item?.invoiceNumber}
                            </p>
                            {isShowPN && <p style={{ marginLeft: 5 }}>
                                {`(${item?.projectId?.title})`}
                            </p>}
                        </div>
                        <span style={{ cursor: 'pointer' }} onClick={onItemPress.bind(this, item)}>
                            <Icon name='info' style={{ width: 15, height: 15 }} />
                        </span>
                    </div>
                    <div className='dfc' style={{ alignSelf: 'center' }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={item?.createBy?.profilePicture}
                                style={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                            />
                        </div>
                    </div>
                    <div className='dfc'>
                        <p> {item?.invoicedAt ? moment(item?.invoicedAt).format('DD-MM-yyyy') : '---'}</p>
                    </div>
                    <div className='dfc fdc'>
                        <p>${item?.amount}</p>
                        {/* <p className='textRed'>{`(Remaining $40)`}</p> */}
                    </div>

                    <div className='dfc'>
                        <MyPopOver
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                            box={<MenuBox handleClick={handleClick} list={invoiceStatus} />}
                        >
                            <MyButton className='button'>{item?.status}</MyButton>
                        </MyPopOver>
                    </div>
                    <div className='dfc' sss>
                        <p className='textRed'>{item?.paidAt ? moment(item?.paidAt).format('DD-MM-yyyy') : '---'}</p>
                    </div>
                    <div className='dfc fdc'>
                        <div className='downloadDiv'>
                            {isPdfDownload ? (
                                <PDFDownloadLink
                                    document={<DownloadInvoiceItem item={data?.data[0]} />}
                                    fileName={`Invoice # ${item?.invoiceNumber}.pdf`}
                                >
                                    {({ blob, url, loading, error }) =>
                                        loading ? (
                                            <Loader size={15} color='grey' />
                                        ) : (
                                            // <CustomButton
                                            //     backgroundColor={'var(--yellow)'}
                                            // // className={
                                            // //     'view_invoice_container_button'
                                            // // }
                                            // >
                                            //     <p>Download</p>
                                            // </CustomButton>
                                            <Tooltip title='Download Invoice'>
                                                <IconButton>
                                                    <DownloadIcon color='secondary' />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                </PDFDownloadLink>
                            ) : (
                                <Tooltip title='Download Invoice' onClick={() => setIsPdfDownload(!isPdfDownload)}>
                                    <IconButton>
                                        <DownloadIcon color='warning' />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    {/* <div>{item?.projectId?.title}</div> */}
                </div>
            )}

            {heading && (
                <div className='header headerIn'>
                    <div className='dfs ml'>
                        <p>Invoice Number </p>
                    </div>
                    <div className='dfc'>
                        <p>Created By</p>
                    </div>
                    <div className='dfc'>
                        <p>Invoice At</p>
                    </div>
                    <div className='dfc'>
                        <p>Amount</p>
                    </div>
                    <div className='dfc'>
                        <p>Status</p>
                    </div>
                    <div className='dfc'>
                        <p>Paid at</p>
                    </div>
                    <div className='dfc'>
                        <p>{''}</p>
                    </div>
                    <div className='dfc'>
                        <p>{''}</p>
                    </div>
                </div>
            )}

            {/* <Dialog
                open={isShowPdf}
            >
                <div onClick={() => handleCloseOpenPdfModal(false)} className='pdfViewer'>
                    <Icon name='redCancel' />
                </div>
                <PDFViewer height={500} width={500} >
                    <DownloadInvoice item={item} />
                </PDFViewer>
            </Dialog> */}
            <InvoiceIdPdfViewer
                isShowPdf={isShowPdf.status}
                handleCloseOpenPdfModal={handleCloseOpenPdfModal}
                invoiceId={isShowPdf?.invoiceId}
            />

            {/*  {!heading && <div style={style}>
                <div className='row'>
                    <div className='details' onClick={onItemPress.bind(this, item)}>
                        <p >{item?.invoiceNumber}</p>
                        <p >{item?.projectId?.title}</p>
                        <p >
                            {item?.dueDate
                                ? moment(item?.dueDate).format('DD-MM-yyyy')
                                : '---'}
                        </p>
                        <p >{item?.amount}</p>
                    </div>
                   
                    <MyPopOver
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                        box={
                            <MenuBox
                                handleClick={handleClick}
                                list={invoiceStatus}
                            />
                        }
                    >
                        <MyButton className='button'>{item?.status}</MyButton>
                    </MyPopOver>

                        <div className='downloadDiv'>
                            {isPdfDownload ? (
                                <PDFDownloadLink
                                    document={
                                        <DownloadInvoiceItem item={item} />
                                    }
                                    fileName={`Invoice # ${item?.invoiceNumber}.pdf`}
                                >
                                    {({ blob, url, loading, error }) =>
                                        loading ? (
                                            <Loader size={15} color='grey' />
                                        ) : (
                                            // <CustomButton
                                            //     backgroundColor={'var(--yellow)'}
                                            // // className={
                                            // //     'view_invoice_container_button'
                                            // // }
                                            // >
                                            //     <p>Download</p>
                                            // </CustomButton>
                                            <Tooltip title='Download Invoice'>
                                                <IconButton>
                                                    <DownloadIcon color='secondary' />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                </PDFDownloadLink>
                            ) : (
                                <Tooltip
                                    title='Download Invoice'
                                    onClick={() =>
                                        setIsPdfDownload(!isPdfDownload)
                                    }
                                >
                                    <IconButton>
                                        <DownloadIcon color='warning' />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            } */}
            {/* {heading && <div className='row' style={{ background: 'var(--milestoneCard)' }}>
                <div className='details'  >
                    <p >invoiceNumber</p>
                    <p >projectId</p>
                    <p >
                        Due Date
                    </p>
                    <p >Amount</p>
                </div>

                <div className='btnDiv'>
                    <MyButton >
                        <p style={{ fontSize: 15 }}>status</p>
                    </MyButton>

                    <div className='downloadDiv'>
                        <p style={{ fontSize: 15 }}>Download</p>
                    </div>

                </div>

            </div>

            } */}
        </>
    );
};

export default InvoiceItem;
