import { Box } from '@mui/material';
import React from 'react';
import './popover.scss';

const MenuBox = ({ list, handleClick }) => {
    return (
        <Box
            className='my-popover-menu'
            boxShadow={5}
            sx={{
                bgcolor: 'var(--newBlue)',
            }}
        >
            {list?.map((el, i) => (
                <div
                    onClick={() => handleClick(el)}
                    className='my-popover-menu-item remove-text-select'
                    key={i}
                >
                    {el.text}
                </div>
            ))}
        </Box>
    );
};

export default MenuBox;
