import React from 'react'
import './checkBox.scss'
import Icon from 'components/icons/IosIcon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
export default function CheckBoxInScheduleDrawer({
    isSelect,
    text,
    handleCheckBox,
    style,
    mode = 'checkBox',
}) {
    return (
        <>
            {mode === 'checkBox' ?
                <div
                    style={{ ...style }}
                    className='checkBoxSection'
                    onClick={handleCheckBox}
                >
                    {/* <div className={isSelect ? 'checkBoxSize' : 'checkBoxBlank'}>
                        {isSelect ? <Icon name='tick'
                            className='icon'
                        />
                            : <div style={{ width: 15 }}></div>}
                    </div> */}
                    {isSelect ?
                        <CheckBoxIcon
                            color='primary'
                            sx={{ width: 17, height: 19, alignSelf: 'center', marginRight: 1 }}
                        /> :
                        <CheckBoxOutlineBlankIcon
                            sx={{ width: 17, height: 19, alignSelf: 'center', marginRight: 1 }}
                        />}
                    {text && <p className='text'>{text}</p>}
                </div>
                :
                <div className='checkBoxSection ml'>
                    <div className='outerCircle'>
                        {isSelect && <p
                            className='circle'
                        ></p>}
                    </div>
                </div>}
        </>
    )
}
