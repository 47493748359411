import React from 'react';
// import Modal from '@mui/material/Modal';
import { Dialog } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme, props) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
    },
    closeButton: {
        // position: "absolute",
        // right: theme.spacing(0),
        // top: theme.spacing(0),
        color: 'var(--defaultWhite)',
        padding: 0,
    },
    paper: {
        background: theme.palette.background.default,
        color: 'var(--defaultWhite)',
        width: (props) => props.width,
        minHeight: (props) => props.height,
        maxHeight: (props) => props?.maxHeight,
        minWidth: (props) => props.minWidth,
        maxWidth: (props) => props.maxWidth,
        // padding: '20px 10px',
    },
}));

const CustomModal = ({ open, handleClose, children, minWidth = '40vw' }) => {
    const classes = useStyles({ minWidth });
    return (
        <Dialog
            classes={{ paper: classes.paper }}
            open={open}
            onClose={handleClose.bind(this, false)}
        >
            {children}
        </Dialog>
    );
};

export default CustomModal;
