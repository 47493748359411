import { axiosInstance } from "./axiosInstance";

export const getEodOfSIngleStaff = () => {
  return axiosInstance.get(`/eod/list`).then((res) => res?.data?.data);
};

export const getEodListForAdmin = ({ orgId }) => {
  return axiosInstance.get(`/eod/all/${orgId}`).then((res) => res?.data);
};

export const addEodByStaff = (data, orgId) => {
  return axiosInstance.post(`/eod/${orgId}/add`, data).then((res) => res?.data);
};

export const updateEodByStaffAdmin = (data, orgId) => {
  return axiosInstance
    .put(`/eod/${orgId}/update`, data)
    .then((res) => res?.data);
};

export const getAllEodList = (page = 1, data = {}, orgId) => {
  return axiosInstance
    .post(`/eod/${orgId}/list`, data, {
      params: {
        page,
      },
    })
    .then((res) => res?.data);
};
