//
import { useState } from 'react';
import {
    DateRange
} from "react-date-range";
import './eodDateRange.scss';

export const EodDateRange = ({ onChange, value }) => {
    // const [value, setValue] = useState([
    //     {
    //         startDate: new Date(),
    //         endDate: new Date(),
    //         key: 'selection'
    //     }
    // ]);

    //  onChange={item => setValue([item.selection])}
    return (
        <div>
            <DateRange
                editableDateInputs={true}
                onChange={onChange}
                moveRangeOnFirstSelection={false}
                ranges={value}
                className='eodDateRange'
            />
        </div>
    )
}
